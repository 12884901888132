import AbstractModel from "./AbstractModel";

/** @typedef {import('./UserInfo').default} UserInfo */

export default class Client extends AbstractModel {
    /**
     * @type {number}
     * @private
     */
    _id = undefined;
    /**
     * @type {UserInfo}
     * @private
     */
    _userInfo = undefined;

    /**
     * @param {number} [id]
     * @param {UserInfo} [userInfo]
     */
    constructor(id, userInfo) {
        super();

        this._id = id;
        this._userInfo = userInfo;
    }

    /**
     * @returns {number}
     */
    get id() {
        return this._id;
    }

    /**
     * @param {number} id
     */
    set id(id) {
        this._id = id;
    }

    /**
     * @returns {UserInfo}
     */
    get userInfo() {
        return this._userInfo;
    }

    /**
     * @param {UserInfo} userInfo
     */
    set userInfo(userInfo) {
        this._userInfo = userInfo;
    }

    toJSON() {
        return {
            id: this._id,
            userInfo: this._userInfo.toJSON()
        };
    }

    /**
     * @returns {Client}
     */
    clone() {
        return this._clone(new Client());
    }
}