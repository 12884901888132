import * as React from "react";
import {
    Create,
    Datagrid,
    DateField,
    DateInput,
    Edit,
    EmailField,
    List,
    required,
    SelectField,
    SelectInput,
    Show,
    SimpleForm,
    SimpleShowLayout,
    TextField,
    TextInput
} from 'react-admin';
import {countries} from "../util/countries";
import Grid from "@material-ui/core/Grid";
import DataFormatter from "./DataFormatter";

export const AdminList = props => (
    <List {...props} title={"IAHA Mitarbeiter"}>
        <Datagrid rowClick="show">
            <TextField label="Nachname" source="userInfo.lastName" />
            <TextField label="Vorname" source="userInfo.firstName" />
            <EmailField label="Email" source="userInfo.email" />
        </Datagrid>
    </List>
);

export const AdminShow = props => (
    <Show title={<Title/>} {...props}>
        <SimpleShowLayout>
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <DataFormatter title={"Anrede"} Field={<SelectField source="userInfo.gender" choices={[
                        {id: 'male', name: 'Herr'},
                        {id: 'female', name: 'Frau'},
                        {id: 'other', name: 'Andere'},
                    ]}/>}/>
                    <DataFormatter title={"Nachname"} Field={<TextField source="userInfo.lastName"/>}/>
                    <DataFormatter title={"Vorname"} Field={<TextField source="userInfo.firstName"/>}/>
                    <DataFormatter title={"Email"} Field={<EmailField source="userInfo.email"/>}/>
                    <DataFormatter title={"Strasse"} Field={<TextField source="userInfo.streetAndNr"/>}/>
                    <DataFormatter title={"PLZ"} Field={<TextField source="userInfo.zipcode"/>}/>
                    <DataFormatter title={"Stadt"} Field={<TextField source="userInfo.city"/>}/>
                    <DataFormatter title={"Land"}
                                   Field={<SelectField source="userInfo.country" choices={countries()}/>}/>
                    <DataFormatter title={"Nationalität"}
                                   Field={<SelectField source="userInfo.nationality" choices={countries()}/>}/>
                    <DataFormatter title={"Geburtsdatum"}
                                   Field={<DateField source="userInfo.birthDate" choices={countries()}/>}/>
                    <DataFormatter title={"Telefon"} Field={<TextField type="number" source="userInfo.phoneNumber"/>}/>
                    <DataFormatter title={"Telefon Mobil"}
                                   Field={<TextField type="number" source="userInfo.mobileNumber"/>}/>
                </Grid>
            </Grid>
        </SimpleShowLayout>
    </Show>
);

export const AdminCreate = props => (
    <Create title="Erstelle IAHA Mitarbeiter" {...props}>
        <SimpleForm>
            <SelectInput label="Gender" source="userInfo.gender" validate={[required()]} choices={[
                {id: 'male', name: 'Herr'},
                {id: 'female', name: 'Frau'},
                {id: 'other', name: 'Andere'},
            ]}/>
            <TextInput label="Nachname" source="userInfo.lastName" validate={[required()]}/>
            <TextInput label="Vorname" source="userInfo.firstName" validate={[required()]}/>
            <TextInput label="Email" source="userInfo.email"/>
            <TextInput label="Strasse" source="userInfo.streetAndNr" validate={[required()]}/>
            <TextInput label="PLZ" source="userInfo.zipcode" validate={[required()]}/>
            <TextInput label="Ort" source="userInfo.city" validate={[required()]}/>
            <SelectInput label="Land" source="userInfo.country" choices={countries()} validate={[required()]}/>
            <SelectInput label="Nationality" source="userInfo.nationality" choices={countries()}
                         validate={[required()]}/>
            <DateInput label="Birthdate" source="userInfo.birthDate"/>
            <TextInput type="number" label="Phone Number" source="userInfo.phoneNumber" validate={[required()]}/>
            <TextInput type="number" label="Mobile Number" source="userInfo.mobileNumber" validate={[required()]}/>
        </SimpleForm>
    </Create>
);

export const AdminEdit = props => (
    <Edit title={<Title/>} {...props}>
        <SimpleForm>
            <TextInput disabled source="id" />
            <SelectInput label="Gender" source="userInfo.gender" validate={[required()]} choices={[
                {id: 'male', name: 'Herr'},
                {id: 'female', name: 'Frau'},
                {id: 'other', name: 'Andere'},
            ]}/>
            <TextInput label="Nachname" source="userInfo.lastName" validate={[required()]}/>
            <TextInput label="Vorname" source="userInfo.firstName" validate={[required()]}/>
            <TextInput label="Strasse" source="userInfo.streetAndNr" validate={[required()]}/>
            <TextInput label="PLZ" source="userInfo.zipcode" validate={[required()]}/>
            <TextInput label="Ort" source="userInfo.city" validate={[required()]}/>
            <SelectInput label="Land" source="userInfo.country" choices={countries()} validate={[required()]}/>
            <SelectInput label="Nationality" source="userInfo.nationality" choices={countries()}
                         validate={[required()]}/>
            <DateInput label="Birthdate" source="userInfo.birthDate"/>
            <TextInput type="number" label="Phone Number" source="userInfo.phoneNumber" validate={[required()]}/>
            <TextInput type="number" label="Mobile Number" source="userInfo.mobileNumber" validate={[required()]}/>
        </SimpleForm>
    </Edit>
);

const Title = ({ record }) => {
    return (
        <span>{record ? `${record.lastName + ' ' + record.firstName + ', ' + record.email}` : ''}</span>
    )
};
