import React from 'react';
import {Button, Edit, email, FunctionField, required, SaveButton, SimpleForm, TextInput, Toolbar} from 'react-admin';
import keycloak from '../keycloak';
import ApiService from '../services/ApiService';
import {Alert} from "@mui/material";

const ToolbarWithoutDelete = ({onSubmit, onBack, ...props}) => (
    <Toolbar {...props}>
        <SaveButton hidden={!!onBack} onSuccess={onSubmit}/>
        <Button hidden={!onBack} onClick={onBack} label="Zurück" size="medium"/>
    </Toolbar>
);

export default class ProfileEdit extends React.Component {
    constructor(props) {
        super(props);

        const token = this._getTokenByUrl();

        this.state = {
            token: token,
            tokenSubmissionInProgress: !!token,
            tokenSubmissionSucceeded: false,
            emailChangeInitialized: false
        };
    }

    /**
     * @returns {string|undefined}
     * @private
     */
    get _token() {
        return this.state.token;
    }

    /**
     * @returns {boolean}
     * @private
     */
    get _tokenSubmissionInProgress() {
        return this.state.tokenSubmissionInProgress;
    }

    /**
     * @returns {boolean}
     * @private
     */
    get _tokenSubmissionSucceeded() {
        return this.state.tokenSubmissionSucceeded;
    }

    /**
     * @param {boolean} tokenSubmissionSucceeded
     * @private
     */
    set _tokenSubmissionSucceeded(tokenSubmissionSucceeded) {
        this.setState({
            tokenSubmissionSucceeded: tokenSubmissionSucceeded
        });
    }

    /**
     * @param {boolean} tokenSubmissionInProgress
     * @private
     */
    set _tokenSubmissionInProgress(tokenSubmissionInProgress) {
        this.setState({
            tokenSubmissionInProgress: tokenSubmissionInProgress
        });
    }

    /**
     * @returns {boolean}
     * @private
     */
    get _emailChangeInitialized() {
        return this.state.emailChangeInitialized;
    }

    /**
     * @param {boolean} emailChangeInitialized
     * @private
     */
    set _emailChangeInitialized(emailChangeInitialized) {
        console.info('test');
        this.setState({
            emailChangeInitialized: emailChangeInitialized
        });
    }

    /**
     * @returns {boolean}
     * @private
     */
    get _isEmailFieldHidden() {
        return this._emailChangeInitialized || this._tokenSubmissionInProgress || this._tokenSubmissionSucceeded;
    }

    componentDidMount() {
        if (!!this._token) {
            ApiService.sendEmailChangeToken(this._token, keycloak)
                .then(() => this._tokenSubmissionSucceeded = true)
                .catch(error => this._tokenSubmissionSucceeded = error)
                .finally(() => this._tokenSubmissionInProgress = false);
        }
    }

    render = () => (
        <Edit resource="profile" basePath="/profile" title="E-Mail Adresse ändern" {...this.props} undoable={false}>
            <SimpleForm redirect={false} toolbar={this._renderToolbar()}>
                <FunctionField addLabel={false} render={this._renderActionStatus}/>
                {this._renderEmailField()}
            </SimpleForm>
        </Edit>
    );

    /**
     * @returns {boolean}
     * @private
     */
    _onSubmit = () => this._emailChangeInitialized = true;

    /**
     * @private
     */
    _onReset = () => {
        this._emailChangeInitialized = false;
        this._tokenSubmissionSucceeded = false;
        this._tokenSubmissionInProgress = false;

        if (!!this._token) {
            window.location.search = '';
        }
    };

    /**
     * @returns {JSX.Element}
     * @private
     */
    _renderToolbar = () => <ToolbarWithoutDelete
        onSubmit={this._isEmailFieldHidden ? null : this._onSubmit}
        onBack={this._isEmailFieldHidden ? this._onReset : null}
    />;

    /**
     * @returns {JSX.Element|null}
     * @private
     */
    _renderActionStatus = () => {
        switch (true) {
            case this._emailChangeInitialized:
                return <Alert severity="info">
                    Eine Nachricht mit einem Link wurde an Ihre neue E-Mail Adresse versandt. Bitte
                    prüfen Sie Ihre Mailbox und klicken Sie auf den Bestätigungslink um den Vorgang
                    abzuschliessen.
                </Alert>;
            case this._tokenSubmissionSucceeded === true:
                return <Alert severity="success">Ihre E-Mail Adresse wurde erfolgreich geändert.</Alert>;
            case this._tokenSubmissionSucceeded instanceof Error:
                return <Alert severity="error">
                    Ihre E-Mail Adresse konnte nicht geändert werden. Entweder das Link ist ungültig oder es existiert
                    bereits ein Konto mit dieser E-Mail Adresse.
                </Alert>;
            default:
                return null;
        }
    }

    /**
     * @returns {null|JSX.Element}
     * @private
     */
    _renderEmailField = () => this._isEmailFieldHidden ? null : (
        <TextInput source="email" validate={[required(), email()]} label="Neue E-Mail Adresse"/>
    );

    /**
     * @returns {string}
     * @private
     */
    _getTokenByUrl = () => {
        const params = new URLSearchParams(window.location.search);

        return params.get('token');
    };
}