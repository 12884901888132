import React from "react";
import './ImageEditorInput.css';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import {Col, Row} from "react-bootstrap";
import FileUploader from "../FileUploader/FileUploader";
import CropPortraitIcon from '@material-ui/icons/CropPortrait';
import Crop169Icon from '@material-ui/icons/Crop169';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import SwapVertIcon from '@material-ui/icons/SwapVert';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import RotateRightIcon from '@material-ui/icons/RotateRight';
import Button from '@material-ui/core/Button';
import ApiService from "../../services/ApiService";
import keycloak from "../../keycloak";

export default class ImageEditorInput extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            image: null,
            imageId: null,
            file: null,
            saving: false
        };
    }

    /**
     * @returns {string|ArrayBuffer|null}
     * @private
     */
    get _image() {
        return this.state.image;
    }

    /**
     * @param {string|ArrayBuffer} image
     * @private
     */
    set _image(image) {
        this.setState({
            image: image
        });
    }

    /**
     * @returns {number|null}
     * @private
     */
    get _imageId() {
        return this.state.imageId;
    }

    /**
     * @param {number|null} imageId
     * @private
     */
    set _imageId(imageId) {
        this.setState({
            imageId: imageId
        });
    }

    componentDidMount() {
        ApiService.getCaregiverPhotoInfo(this.props.record.id, keycloak)
            .then(this._handlePhotoInfo)
            .catch(console.error);
    }

    _handlePhotoInfo = photo => {
        if (!photo) {
            return;
        }

        this._imageId = photo.id;

        return ApiService.getCaregiverPhoto(this.props.record.id, photo.id, keycloak).then(this._handlePhoto);
    }

    /**
     * @param {Blob} blob
     * @private
     */
    _handlePhoto = blob => {
        const reader = new FileReader();

        reader.onloadend = () => this._image = reader.result;
        reader.readAsDataURL(blob);
    }

    _setImage = (file) => {
        const reader = new FileReader();

        reader.onload = () => {
            this.setState({image: reader.result, file: file});
        };
        reader.readAsDataURL(file);
    }

    setAspectRatio(w, h) {
        const imageData = this.cropper.getImageData();
        const containerData = this.cropper.getContainerData();
        const cropHeight = imageData.height;
        const cropWidth = cropHeight / h * w;

        this.cropper.crop();

        this.cropper.setCropBoxData({
            left: containerData.width / 2 - cropWidth / 2,
            top: -containerData.height / 2 - cropHeight / 2, width: cropWidth, height: cropHeight
        });
    }

    flipHorizontal() {
        this.cropper.scaleX(this.cropper.getImageData().scaleX * -1);
    }

    flipVertical() {
        this.cropper.scaleY(this.cropper.getImageData().scaleY * -1);
    }

    rotateRight() {
        this.cropper.rotate(90);
    }

    rotateLeft() {
        this.cropper.rotate(-90);
    }

    uploadImage = () => {
        this.setState({saving: true}, () => {
            this.cropper.getCroppedCanvas()
                .toBlob(blob => this._savePhoto(blob).then(() => this.setState({saving: false})));
        });
    }

    _savePhoto = photo => {
        if (this._imageId) {
            return ApiService.updateCaregiverPhoto(this.props.record.id, this._imageId, photo, keycloak);
        } else {
            return ApiService.addCaregiverPhoto(this.props.record.id, photo, keycloak);
        }
    }

    render() {
        return (
            <div>
                <Row>
                    <Col>
                        <Cropper
                            onInitialized={c => this.cropper = c}
                            src={this.state.image}
                            style={{height: 400, backgroundColor: "#f5f5f5", width: "100%"}}
                            initialAspectRatio={16 / 9}
                            guides={false}
                            autoCrop={false}
                            zoomable={true}
                            viewMode={1}
                            minCropBoxHeight={10}
                            minCropBoxWidth={10}
                            responsive={true}
                            autoCropArea={1}
                        />
                    </Col>
                    <Col sm={3}>
                        <Row>
                            <Col>
                                <FileUploader file={this.state.file} handleFile={this._setImage}/>
                            </Col>
                        </Row>
                        <hr/>
                        <Row>
                            <Col>
                                <p style={{margin: 0, marginTop: 5}}>Seitenverhältnisse</p>
                                <Button variant="contained" color="primary" aria-label="upload picture"
                                        component="span" disabled={this.state.image === null} style={{marginRight: 5}}
                                        onClick={() => this.setAspectRatio(9, 13)}>
                                    <CropPortraitIcon/>
                                </Button>
                                <Button variant="contained" color="primary" aria-label="upload picture"
                                        component="span" disabled={this.state.image === null}
                                        onClick={() => this.setAspectRatio(13, 9)}>
                                    <Crop169Icon/>
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p style={{margin: 0, marginTop: 5}}>Spiegeln</p>
                                <Button variant="contained" color="primary" aria-label="upload picture"
                                        component="span" disabled={this.state.image === null} style={{marginRight: 5}}
                                        onClick={() => this.flipHorizontal()}>
                                    <SwapHorizIcon/>
                                </Button>
                                <Button variant="contained" color="primary" aria-label="upload picture"
                                        component="span" disabled={this.state.image === null}
                                        onClick={() => this.flipVertical()}>
                                    <SwapVertIcon/>
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p style={{margin: 0, marginTop: 5}}>Drehen</p>
                                <Button variant="contained" color="primary" aria-label="upload picture"
                                        component="span" disabled={this.state.image === null} style={{marginRight: 5}}
                                        onClick={() => this.rotateLeft()}>
                                    <RotateLeftIcon/>
                                </Button>
                                <Button variant="contained" color="primary" aria-label="upload picture"
                                        component="span" disabled={this.state.image === null}
                                        onClick={() => this.rotateRight()}>
                                    <RotateRightIcon/>
                                </Button>
                            </Col>
                        </Row>
                        <Row style={{marginTop: 40}}>
                            <Col>
                                <Button variant="contained" color="primary" aria-label="upload picture"
                                        component="span" disabled={this.state.image === null || this.state.saving}
                                        style={{marginRight: 5}}
                                        onClick={this.uploadImage}>
                                    Speichern
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        )
    }
}
