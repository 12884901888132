import React from "react";
import './AssessmentPoints.css';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

export default class AssessmentPoints extends React.Component {

    render() {
        let pointsPerSuperCategoryMap = {};
        let totalPoints = 0;

        for(let answer of this.props.answers) {
            let superScoreCategory = answer.question.scoreCategory.superCategory.description[0].text;
            let point = answer.answerScore;
            if(superScoreCategory in pointsPerSuperCategoryMap) pointsPerSuperCategoryMap[superScoreCategory].points += point;
            else pointsPerSuperCategoryMap[superScoreCategory] = {name: superScoreCategory, points: point};
            totalPoints += point;
        }

        let pointsPerSuperCategory = [];
        Object.entries(pointsPerSuperCategoryMap).map(entry => {
            pointsPerSuperCategory.push(entry[1])
        });

        pointsPerSuperCategory.sort(
            function(a, b){
                if(a.name < b.name) { return -1; }
                if(a.name > b.name) { return 1; }
                return 0;
            });

        return (
            <TableContainer component={Paper}>
                <Table className='table' aria-label="spanning table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Oberkategorie</TableCell>
                            <TableCell align="right">Punkte</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {pointsPerSuperCategory.map((row) => (
                            <TableRow key={row.name}>
                                <TableCell>{row.name}</TableCell>
                                <TableCell align="right">{row.points}</TableCell>
                            </TableRow>
                        ))}

                        <TableRow>
                            <TableCell style={{fontWeight: 'bold'}} align="right" colSpan={1}>Totalpunktzahl</TableCell>
                            <TableCell style={{fontWeight: 'bold'}} align="right">{totalPoints}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }
}
