export default class AnswerChoice {

    constructor(id, choice, priorityQuestionOrder = null) {
        this._id = id;
        this._choice = choice;
        this._priorityQuestionOrder = priorityQuestionOrder;
    }

    get id() {
        return this._id;
    }

    set id(value) {
        this._id = value;
    }

    get choice() {
        return this._choice;
    }

    set choice(value) {
        this._choice = value;
    }

    get priorityQuestionOrder() {
        return this._priorityQuestionOrder;
    }

    set priorityQuestionOrder(value) {
        this._priorityQuestionOrder = value;
    }

    toJSON() {
        return ({
            id: this._id,
            choice: this._choice ? this._choice.toJSON() : "",
            priorityQuestionOrder: this._priorityQuestionOrder
        })
    }
}
