import * as React from "react";
import {
    Show, SimpleShowLayout, List, Create, Edit, SimpleForm, Datagrid, TextField, ReferenceField, TextInput,
    ReferenceInput, SelectInput, required, EditButton, DeleteButton
}
    from 'react-admin';
import LocalizedTextField from "./LocalizedTextField/LocalizedTextField";
import LocalizedTextInput from "./LocalizedTextInput/LocalizedTextInput";
import DataFormatter from "./DataFormatter";
import Grid from "@material-ui/core/Grid";
import OptionsButton from "./OptionsButton";
import {BulkDeleteButtonWithConfirmation} from "./DeleteButtonWithConfirmation";

export const ScoreCategoryList = props => (
    <List {...props} title={"Punktekategorien"} bulkActionButtons={<BulkDeleteButtonWithConfirmation {...props}/>}>
        <Datagrid>
            <TextField label="Beschreibung" source="description[0].text" />
            <ReferenceField label="Oberpunktekategorie" source="superCategory.id" reference="scoreSuperCategory">
                <TextField source="description[0].text" />
            </ReferenceField>
            <OptionsButton basePath="/scoreCategory" label=" " record={props}/>
        </Datagrid>
    </List>
);


export const ScoreCategoryShow = props => (
    <Show title={<Title/>} {...props}>
        <SimpleShowLayout>
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <DataFormatter title={"Id"} Field={<TextField source="id" />}/>
                    <DataFormatter title={"Beschreibung"} Field={<TextField source="description[0].text"/>} />
                    <DataFormatter title={"Oberpunktekategorie"} Field={<ReferenceField source="superCategory.id" reference="scoreSuperCategory">
                        <TextField source="description[0].text" />
                    </ReferenceField>} />
                </Grid>
            </Grid>
        </SimpleShowLayout>
    </Show>
);

export const ScoreCategoryCreate = props => (
    <Create title="Erstelle Punktekategorie" transform={transform} {...props}>
        <SimpleForm>
            <LocalizedTextInput label="Beschreibung" source="description" validate={[required()]} />
            <ReferenceInput label="Oberpunktekategorie" source="superCategory.id" reference="scoreSuperCategory" validate={[required()]}>
                <SelectInput optionText="description[0].text" />
            </ReferenceInput>
        </SimpleForm>
    </Create>
);

export const ScoreCategoryEdit = props => (
    <Edit title={<Title/>} transform={transform} {...props}>
        <SimpleForm>
            <TextInput disabled source="id" />
            <LocalizedTextInput label="Beschreibung" source="description" validate={[required()]}/>
            <ReferenceInput label="Oberpunktekategorie" source="superCategory.id" reference="scoreSuperCategory" validate={[required()]}>
                <SelectInput optionText="description[0].text" />
            </ReferenceInput>
        </SimpleForm>
    </Edit>
);

const Title = ({ record }) => {
    return (
        <span>{record ? `Punktekategorien - ${record.description[0].text}` : ''}</span>
    )
};

let transform = data => ({
    ...data,
    superCategory: data.superCategory.id
});
