import keycloak from "../keycloak";

const authProvider = {
    logout: () => {keycloak.logout()},
    login: params => Promise.resolve(),
    checkError: error => Promise.resolve(),
    checkAuth: params => Promise.resolve(),
    getIdentity: () => Promise.resolve(),
    // authorization
    getPermissions: () => { return keycloak.realmAccess.roles ?
        Promise.resolve(keycloak.realmAccess.roles) : Promise.reject()},
};
export default authProvider;
