import * as React from "react";
import {
    Show, Filter, SimpleShowLayout, ArrayField, List, Create, Edit, SimpleForm, Datagrid, TextField, BooleanField,
    ReferenceField, SelectField, TextInput, BooleanInput, ReferenceInput, required,
    SelectInput, ArrayInput, SimpleFormIterator, EditButton, DeleteButton
} from 'react-admin';

import LocalizedTextInput from "./LocalizedTextInput/LocalizedTextInput";
import LocalizedTextField from "./LocalizedTextField/LocalizedTextField";
import {addLocalizationToChoices, addLocalizationToQuestion} from "../util/localizationTransformer";
import Grid from "@material-ui/core/Grid";
import DataFormatter from "./DataFormatter";
import OptionsButton from "./OptionsButton";
import {BulkDeleteButtonWithConfirmation} from "./DeleteButtonWithConfirmation";

const CaregiverAssessmentQuestionFilter = (props) => (
    <Filter {...props}>
        <SelectInput label="Status" source="status" choices={[
            {id: 'disabled', name: "Deaktiviert"},
            {id: 'enabled', name: "Aktiviert"},
            {id: 'enabled-printable', name: "Aktiviert und druckbar"},
        ]}/>
    </Filter>
);

export const CaregiverAssessmentQuestionList = props => (
    <List {...props} title={"Betreuungspersonen Prüfungsfragen"} filters={<CaregiverAssessmentQuestionFilter/>}
          filterDefaultValues={{enabled: true}} bulkActionButtons={<BulkDeleteButtonWithConfirmation {...props}/>}>
        <Datagrid>
            <ReferenceField label="Punktekategorie" source="scoreCategory.id" reference="scoreCategory" sortable={false}>
                <TextField source="description[0].text" />
            </ReferenceField>
            <SelectField label="Fragentyp" sortable={false} source="answerType" choices={[
                { id: 'text', name: 'Text' },
                { id: 'single-choice', name: 'Single Choice' },
                { id: 'multiple-choice', name: 'Multiple Choice' },
            ]}/>
            <TextField label="Frage" source="question[0].text" sortable={false} />
            <SelectField label="Status" source="status" choices={[
                {id: 'disabled', name: "Deaktiviert"},
                {id: 'enabled', name: "Aktiviert"},
                {id: 'enabled-printable', name: "Aktiviert und druckbar"},
            ]}/>
            <OptionsButton basePath="/caregiverAssessmentQuestion" label=" " record={props}/>
        </Datagrid>
    </List>
);

export const CaregiverAssessmentQuestionShow = props => (
    <Show title={<Title/>} {...props}>
        <SimpleShowLayout>
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <DataFormatter title={"Id"} Field={<TextField disabled source={"id"}/>}/>
                    <DataFormatter title={"Aktiv"} Field={<SelectField label="Status" source="status" choices={[
                        {id: 'disabled', name: "Deaktiviert"},
                        {id: 'enabled', name: "Aktiviert"},
                        {id: 'enabled-printable', name: "Aktiviert und druckbar"},
                    ]}/>}/>
                    <DataFormatter title={"Punktekategorie"} Field={<ReferenceField source="scoreCategory.id" reference="scoreCategory">
                        <TextField source="description[0].text" />
                    </ReferenceField>}/>
                    <DataFormatter title={"Frage"} Field={<TextField source="question[0].text" />}/>
                    <DataFormatter title={"Fragentyp"} Field={<SelectField source="answerType" choices={[
                        { id: 'text', name: 'Text' },
                        { id: 'single-choice', name: 'Single Choice' },
                        { id: 'multiple-choice', name: 'Multiple Choice' },
                        { id: 'checkbox', name: 'Checkbox' },
                        { id: 'number', name: 'Nummer' },
                    ]} />}/>
                    <DataFormatter title={"Antwortmöglichkeiten"} Field={<ArrayField source="choices">
                        <Datagrid>
                            <LocalizedTextField source="text" />
                            <SelectField label="" source="scoreValue" choices={[
                                { id: 1.0, name: 'Wahr' },
                                { id: 0.0, name: 'Falsch' },
                            ]}/>
                        </Datagrid>
                    </ArrayField>}/>
                </Grid>
            </Grid>
        </SimpleShowLayout>
    </Show>
);

export const CaregiverAssessmentQuestionCreate = props => (
    <Create title="Erstelle Prüfungsfrage" {...props} transform={transform}>
        <SimpleForm>
            <SelectInput label="Status" source="status" choices={[
                {id: 'disabled', name: "Deaktiviert"},
                {id: 'enabled', name: "Aktiviert"},
                {id: 'enabled-printable', name: "Aktiviert und druckbar"},
            ]}/>
            <ReferenceInput label="Punktekategorie" source="scoreCategory.id" reference="scoreCategory" validate={[required()]}>
                <SelectInput optionText="description[0].text" />
            </ReferenceInput>
            <LocalizedTextInput label="Frage" multiline fullWidth source="question" validate={[required()]}/>
            <SelectInput label="Fragentyp" source="answerType" validate={[required()]} choices={[
                { id: 'text', name: 'Text' },
                { id: 'single-choice', name: 'Single Choice' },
                { id: 'multiple-choice', name: 'Multiple Choice' },
            ]} />
            <ArrayInput label="Antwortmöglichkeiten" source="choices">
                <SimpleFormIterator>
                    <LocalizedTextInput label="" multiline fullWidth source="text" validate={[required()]}/>
                    <SelectInput label="Richtigkeit" defaultValue={0.0} source="scoreValue" validate={[required()]} choices={[
                        { id: 1.0, name: 'Wahr' },
                        { id: 0.0, name: 'Falsch' },
                    ]}/>
                </SimpleFormIterator>
            </ArrayInput>
        </SimpleForm>
    </Create>
);

export const CaregiverAssessmentQuestionEdit = props => (
    <Edit title={<Title/>} {...props} transform={transform}>
        <SimpleForm>
            <TextInput disabled source="id" />
            <SelectInput label="Status" source="status" choices={[
                {id: 'disabled', name: "Deaktiviert"},
                {id: 'enabled', name: "Aktiviert"},
                {id: 'enabled-printable', name: "Aktiviert und druckbar"},
            ]}/>
            <ReferenceInput label="Punktekategorie" source="scoreCategory.id" reference="scoreCategory" validate={[required()]}>
                <SelectInput optionText="description[0].text" />
            </ReferenceInput>
            <LocalizedTextInput label="Frage" multiline fullWidth source="question" validate={[required()]}/>
            <SelectInput label="Fragentyp" source="answerType" validate={[required()]} choices={[
                { id: 'text', name: 'Text' },
                { id: 'single-choice', name: 'Single Choice' },
                { id: 'multiple-choice', name: 'Multiple Choice' },
            ]} />
            <ArrayInput label="Antwortmöglichkeiten" source="choices">
                <SimpleFormIterator>
                    <LocalizedTextInput label="" multiline fullWidth source="text" validate={[required()]}/>
                    <SelectInput label="Richtigkeit" defaultValue={0.0} source="scoreValue" validate={[required()]} choices={[
                        { id: 1.0, name: 'Wahr' },
                        { id: 0.0, name: 'Falsch' },
                    ]}/>
                </SimpleFormIterator>
            </ArrayInput>
        </SimpleForm>
    </Edit>
);

const Title = ({ record }) => {
    return (
        <span>{record ? `Betreuungspersonen Prüfungsfragen - ${record.question[0].text}` : ''}</span>
    )
};

let transform = data => ({
    ...data,
    scoreCategory: data.scoreCategory.id,
    question: addLocalizationToQuestion(data.question),
    choices: addLocalizationToChoices(data.choices)
});
