import * as React from "react";
import {
    Create,
    Datagrid,
    DateField,
    DateInput,
    Edit,
    EmailField,
    Filter,
    FormTab,
    List,
    required,
    SelectField,
    SelectInput,
    Show,
    SimpleForm,
    Tab,
    TabbedForm,
    TabbedShowLayout,
    TextField,
    TextInput
} from 'react-admin';
import {countries} from "../util/countries";
import Grid from "@material-ui/core/Grid";
import DataFormatter from "./DataFormatter";
import OptionsButton from "./OptionsButton";
import {BulkDeleteButtonWithConfirmation} from "./DeleteButtonWithConfirmation";

const PartnerCompanyFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
    </Filter>
);

export const PartnerCompanyList = props => (
    <List {...props} filters={<PartnerCompanyFilter/>} title={"Partnerfirmen"}
          bulkActionButtons={<BulkDeleteButtonWithConfirmation {...props}/>}>
        <Datagrid>
            <TextField label="Name" source="name"/>
            <TextField source="id"/>
            <TextField label="Adresse" source="userInfo.streetAndNr"/>
            <TextField label="Stadt" source="userInfo.city"/>
            <TextField label="Land" source="userInfo.country"/>
            <TextField type="number" label="Telefon" source="userInfo.phoneNumber"/>
            <OptionsButton basePath="/partnerCompany" label=" " record={props.userInfo}/>
        </Datagrid>
    </List>
);

export const PartnerCompanyShow = props => (
    <Show title={<Title/>} {...props}>
        <TabbedShowLayout>
            <Tab label="Allgemein">
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <DataFormatter title={"Id"} Field={<TextField source="id"/>}/>
                        <DataFormatter title={"Name"} Field={<TextField source="name"/>}/>
                        <DataFormatter title={"Anrede"} Field={<SelectField source="userInfo.gender" choices={[
                            {id: 'male', name: 'Herr'},
                            {id: 'female', name: 'Frau'},
                            {id: 'other', name: 'Andere'},
                        ]}/>}/>
                        <DataFormatter title={"Verantwortlicher Vorname"}
                                       Field={<TextField source="userInfo.lastName"/>}/>
                        <DataFormatter title={"Verantwortlicher Nachname"}
                                       Field={<TextField source="userInfo.firstName"/>}/>
                        <DataFormatter title={"Email"} Field={<EmailField source="userInfo.email"/>}/>
                        <DataFormatter title={"Adresse"} Field={<TextField source="userInfo.streetAndNr"/>}/>
                        <DataFormatter title={"PLZ"} Field={<TextField source="userInfo.zipcode"/>}/>
                        <DataFormatter title={"Stadt"} Field={<TextField source="userInfo.city"/>}/>
                        <DataFormatter title={"Land"}
                                       Field={<SelectField source="userInfo.country" choices={countries()}/>}/>
                        <DataFormatter title={"Nationalität"}
                                       Field={<SelectField source="userInfo.nationality" choices={countries()}/>}/>
                        <DataFormatter title={"Geburtsdatum"} Field={<DateField source="userInfo.birthDate"/>}/>
                        <DataFormatter title={"Telefon"}
                                       Field={<TextField type="number" source="userInfo.phoneNumber"/>}/>
                        <DataFormatter title={"Telefon Mobil"}
                                       Field={<TextField type="number" source="userInfo.mobileNumber"/>}/>
                    </Grid>
                </Grid>
            </Tab>
        </TabbedShowLayout>
    </Show>
);

export const PartnerCompanyCreate = props => (
    <Create title="Erstelle Partnerfirma" {...props}>
        <SimpleForm>
            <TextInput label="Name" source="name" validate={[required()]}/>
            <SelectInput label="Gender" source="userInfo.gender" validate={[required()]} choices={[
                {id: 'male', name: 'Herr'},
                {id: 'female', name: 'Frau'},
                {id: 'other', name: 'Andere'},
            ]}/>
            <TextInput label="Verantwortlicher Vorname" source="userInfo.lastName" validate={[required()]}/>
            <TextInput label="Verantwortlicher Nachname" source="userInfo.firstName" validate={[required()]}/>
            <TextInput label="Email" source="userInfo.email" validate={[required()]}/>
            <TextInput label="Adresse" source="userInfo.streetAndNr" validate={[required()]}/>
            <TextInput label="PLZ" source="userInfo.zipcode" validate={[required()]}/>
            <TextInput label="Stadt" source="userInfo.city" validate={[required()]}/>
            <SelectInput label="Land" source="userInfo.country" choices={countries()} validate={[required()]}/>
            <SelectInput label="Nationalität" source="userInfo.nationality" choices={countries()}
                         validate={[required()]}/>
            <DateInput label="Geburtsdatum" source="userInfo.birthDate"/>
            <TextInput type="number" label="Telefon" source="userInfo.phoneNumber" validate={[required()]}/>
            <TextInput type="number" label="Telefon Mobil" source="userInfo.mobileNumber" validate={[required()]}/>
        </SimpleForm>
    </Create>
);

export const PartnerCompanyEdit = props => (
    <Edit title={<Title/>} {...props}>
        <TabbedForm>
            <FormTab label="Allgemein">
                <TextInput label="Name" source="name" validate={[required()]}/>
                <SelectInput label="Gender" source="userInfo.gender" validate={[required()]} choices={[
                    {id: 'male', name: 'Herr'},
                    {id: 'female', name: 'Frau'},
                    {id: 'other', name: 'Andere'},
                ]}/>
                <TextInput label="Verantwortlicher Vorname" source="userInfo.lastName" validate={[required()]}/>
                <TextInput label="Verantwortlicher Nachname" source="userInfo.firstName" validate={[required()]}/>
                <TextInput label="Adresse" source="userInfo.streetAndNr" validate={[required()]}/>
                <TextInput label="PLZ" source="userInfo.zipcode" validate={[required()]}/>
                <TextInput label="Stadt" source="userInfo.city" validate={[required()]}/>
                <SelectInput label="Land" source="userInfo.country" choices={countries()} validate={[required()]}/>
                <SelectInput label="Nationalität" source="userInfo.nationality" choices={countries()}
                             validate={[required()]}/>
                <DateInput label="Geburtsdatum" source="userInfo.birthDate"/>
                <TextInput type="number" label="Telefon" source="userInfo.phoneNumber" validate={[required()]}/>
                <TextInput type="number" label="Telefon Mobil" source="userInfo.mobileNumber" validate={[required()]}/>
            </FormTab>
        </TabbedForm>
    </Edit>
);

const Title = ({ record }) => {
    return (
        <span>{record ? `Partnerfirma - ${record.name}` : ''}</span>
    )
};
