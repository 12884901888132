import React from "react";
import ApiService from "../../../services/ApiService";
import keycloak from "../../../keycloak";

export class PhotoField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            image: null
        }
    };

    componentDidMount() {
        this.getPhoto();
    }

    onLoad(result) {
        this.setState({image: result})
    }

    getPhoto() {
        ApiService.getCaregiverPhotoInfo(this.props.record.id, keycloak).then(photo => {
            ApiService.getCaregiverPhoto(this.props.record.id, photo.id, keycloak)
                .then(blob => {
                    let reader = new FileReader();
                    reader.onloadend = () => this.setState({image: reader.result});
                    reader.readAsDataURL(blob);
                });
        }).catch(console.error);
    }

    render() {
        if (this.state.image !== null) {
            return (
                <img style={{marginBottom: 20, height: 200}} src={this.state.image}/>
            )
        } else return <div style={{marginBottom: 20, height: 200, width: 140, backgroundColor: '#fafafa'}}/>
    }
}

export default PhotoField;
