import React from "react";
import './LocalizedTextField.css';
import {Tabs, Tab, Nav} from 'react-bootstrap';
import {TextField} from 'react-admin';
import get from "lodash/get";


class LocalizedTextField extends React.Component {

    constructor(props) {
        super(props);
        this.textInput = React.createRef();
        this.focus = this.focus.bind(this);

        this._locales = get(this.props.record, this.props.source);
    }

    focus() {
        console.log(this.textInput.current);
    }

    textInputStyle = {
        marginTop: 0
    };

    determineLocalization(targetLocalization) {
        let sourcePath = this.props.source.split(".");
        let locals = get(this.props.record, sourcePath[sourcePath.length - 1]);
        for(let localIndex in locals) {
            let local = locals[localIndex];
            if(local.localization === targetLocalization)
                return localIndex;
        }
    }

    renderNavItems () {
        let renderedNavItems = [];
        for(let localIndex in LOCALS) {
            let local = LOCALS[localIndex];
            let className = "";
            if(localIndex === "0") className = 'nav-link-left';
            else if (localIndex === (LOCALS.length -1).toString()) className = 'nav-link-right';
            renderedNavItems.push(
                <Nav.Item>
                    <Nav.Link className={className}
                              eventKey={local.key}>{local.name}</Nav.Link>
                </Nav.Item>
            );
        }
        return renderedNavItems;
    }

    renderTabs () {
        let renderedTabs = [];
        for(let local of LOCALS) {
            renderedTabs.push(
                <Tab.Pane eventKey={local.key}>
                    <TextField ref={this.textInput} style={this.textInputStyle}
                               multiline={this.props.multiline} fullWidth={this.props.fullWidth}
                               record={this.props.record}
                               source={this.props.source + "[" + this.determineLocalization("local-de") + "].text"}/>
                </Tab.Pane>
            )
        }
        return renderedTabs;
    }

    render () {
        return (
            <div>
                <p className={"MuiFormLabel-root MuiInputLabel-root RaLabeled-label-45 MuiInputLabel-formControl " +
                "MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-marginDense"}>
                    {this.props.label ? this.props.label : ''}
                </p>
                <Tab.Container id="localizationTab" defaultActiveKey={LOCALS[0].key}>
                    <Nav variant="pills" style={{fontSize: 10}}>
                        {this.renderNavItems()}
                    </Nav>
                    <Tab.Content>
                        {this.renderTabs()}
                    </Tab.Content>
                </Tab.Container>
            </div>
        )
    }
}
export default LocalizedTextField;

const LOCALS = [
    {name: "DE", key: "local-de"},
    //{name: "EN", key: "local-en"},
    //{name: "FR", key: "local-fr"},
    //{name: "IT", key: "local-it"},
];
