import './CaregiverHistory.css';

import React from 'react';
import {DataGrid} from '@material-ui/data-grid';
import {formatPhoneNumberIntl} from 'react-phone-number-input'
import ApiService from '../services/ApiService';
import keycloak from '../keycloak';
import Country from '../enums/Country';
import ResidencePermit from '../enums/ResidencePermit';
import CivilStatus from '../enums/CivilStatus';

/** @typedef {import('@material-ui/data-grid/dist/data-grid').GridColDef} GridColDef */
/** @typedef {import('@material-ui/data-grid/dist/data-grid').GridValueGetterParams} GridValueGetterParams */
/** @typedef {import('@material-ui/data-grid/dist/data-grid').GridValueFormatterParams} GridValueFormatterParams */
/** @typedef {import('@material-ui/data-grid/dist/data-grid').GridCellParams} GridCellParams */

export default class CaregiverHistory extends React.Component {
    constructor(props) {
        super(props);

        this._initDifference();

        this.state = {
            loading: true,
            userInfoRevisions: undefined,
            userInfoDetailRevisions: undefined,
            userInfoChildRevisions: undefined,
            rows: [],
            issuers: new Map()
        };
    }

    /**
     * @returns {boolean}
     * @private
     */
    get _hasDifferenceParams() {
        return !!this._diffStart && !!this._diffEnd;
    }

    /**
     * @returns {boolean}
     * @private
     */
    get _loading() {
        return this.state.loading;
    }

    /**
     * @param {boolean} loading
     * @private
     */
    set _loading(loading) {
        this.setState({
            loading: !!loading
        })
    }

    /**
     * @returns {UserInfo[]}
     * @private
     */
    get _userInfoRevisions() {
        return this.state.userInfoRevisions;
    }

    /**
     * @returns {UserInfoDetail[]}
     * @private
     */
    get _userInfoDetailRevisions() {
        return this.state.userInfoDetailRevisions;
    }

    /**
     * @returns {UserInfoChild[]}
     * @private
     */
    get _userInfoChildRevisions() {
        return this.state.userInfoChildRevisions;
    }

    /**
     * @returns {number}
     */
    get _userInfoId() {
        return this.props.userInfoId;
    }

    /**
     * @returns {string}
     */
    get _userInfoDetailId() {
        return this.props.userInfoDetailId;
    }

    /**
     * @returns {Map<string,UserInfo>}
     * @private
     */
    get _issuers() {
        return this.state.issuers;
    }

    /**
     * @returns {GridColDef[]}
     * @private
     */
    get _columns() {
        /**
         * @param {GridValueGetterParams} params
         */
        const valueGetter = params => params.value.data;
        /**
         * @param enumClass
         * @returns {function(GridValueGetterParams): *}
         */
        const enumValueGetter = enumClass => params => enumClass.valueOf(params.value.data)?.label;
        /**
         * @param {GridCellParams} params
         */
        const cellClassName = params => params.row[params.field].hasChanged ? 'changed' : 'similar';
        /**
         * @param {GridValueFormatterParams} params
         */
        const phoneNumberFormatter = params => formatPhoneNumberIntl(params.value);
        /**
         * @param {GridValueFormatterParams} params
         */
        const booleanValueFormatter = params => params.value ? 'Ja' : 'Nein';

        return [
            {
                field: 'revision',
                headerName: 'Revision',
                hide: this._hasDifferenceParams,
                width: 150,
                type: 'number',
                align: 'center',
                headerAlign: 'center',
                sortComparator: (l, r) => Math.sign(l - r)
            },
            {
                field: 'timestamp',
                headerName: 'Zeitstempel',
                width: 175,
                type: 'dateTime',
                sortable: false
            },
            {
                field: 'issuer',
                headerName: 'Geändert durch ...',
                hide: this._hasDifferenceParams,
                width: 200,
                type: 'string',
                sortable: false,
                valueFormatter: params => this._issuers.get(params.value)
            },
            {
                field: 'employmentStartDate',
                headerName: 'Eintrittsdatum',
                minWidth: 175,
                valueGetter: valueGetter,
                cellClassName: cellClassName,
                type: 'date',
                sortable: false
            },
            {
                field: 'firstName',
                headerName: 'Vorname',
                minWidth: 200,
                valueGetter: valueGetter,
                cellClassName: cellClassName,
                type: 'string',
                sortable: false
            },
            {
                field: 'lastName',
                headerName: 'Nachname',
                minWidth: 200,
                valueGetter: valueGetter,
                cellClassName: cellClassName,
                type: 'string',
                sortable: false
            },
            {
                field: 'streetAndNr',
                headerName: 'Strasse',
                minWidth: 300,
                valueGetter: valueGetter,
                cellClassName: cellClassName,
                type: 'string',
                sortable: false
            },
            {
                field: 'zipcode',
                headerName: 'PLZ',
                minWidth: 150,
                valueGetter: valueGetter,
                cellClassName: cellClassName,
                type: 'number',
                sortable: false
            },
            {
                field: 'city',
                headerName: 'Ort',
                minWidth: 250,
                valueGetter: valueGetter,
                cellClassName: cellClassName,
                type: 'string',
                sortable: false
            },
            {
                field: 'country',
                headerName: 'Land',
                minWidth: 250,
                valueGetter: enumValueGetter(Country),
                cellClassName: cellClassName,
                type: 'string',
                sortable: false
            },
            {
                field: 'birthDate',
                headerName: 'Geburtsdatum',
                minWidth: 175,
                valueGetter: valueGetter,
                cellClassName: cellClassName,
                type: 'date',
                sortable: false
            },
            {
                field: 'nationality',
                headerName: 'Nationalität',
                minWidth: 250,
                valueGetter: enumValueGetter(Country),
                cellClassName: cellClassName,
                type: 'string',
                sortable: false
            },
            {
                field: 'residencePermit',
                headerName: 'CH Aufenthaltsbewilligung',
                minWidth: 250,
                valueGetter: enumValueGetter(ResidencePermit),
                cellClassName: cellClassName,
                type: 'string',
                sortable: false
            },
            {
                field: 'civilStatus',
                headerName: 'Zivilstand',
                minWidth: 250,
                valueGetter: enumValueGetter(CivilStatus),
                cellClassName: cellClassName,
                type: 'string',
                sortable: false
            },
            {
                field: 'phoneNumber',
                headerName: 'Telefon',
                minWidth: 250,
                valueGetter: valueGetter,
                cellClassName: cellClassName,
                valueFormatter: phoneNumberFormatter,
                type: 'string',
                sortable: false
            },
            {
                field: 'mobileNumber',
                headerName: 'Mobile',
                minWidth: 250,
                valueGetter: valueGetter,
                cellClassName: cellClassName,
                valueFormatter: phoneNumberFormatter,
                type: 'string',
                sortable: false
            },
            {
                field: 'socialSecurityNumber',
                headerName: 'AHV-Nummer',
                minWidth: 250,
                valueGetter: valueGetter,
                cellClassName: cellClassName,
                type: 'string',
                sortable: false
            },
            {
                field: 'bankAccountNumber',
                headerName: 'IBAN-Nummer',
                minWidth: 250,
                valueGetter: valueGetter,
                cellClassName: cellClassName,
                type: 'string',
                sortable: false
            },
            {
                field: 'withholdingTax',
                headerName: 'Quellensteuer',
                minWidth: 175,
                align: 'center',
                headerAlign: 'center',
                valueGetter: valueGetter,
                cellClassName: cellClassName,
                valueFormatter: booleanValueFormatter,
                type: 'string',
                sortable: false
            },
            {
                field: 'otherEmployers',
                headerName: 'Andere Arbeitsgeber',
                minWidth: 250,
                align: 'left',
                headerAlign: 'left',
                valueGetter: valueGetter,
                cellClassName: cellClassName,
                valueFormatter: params => params.value === null || params.value === undefined ? 'Nein' : params.value === '' ? 'Ja' : params.value,
                type: 'string',
                sortable: false
            },
            {
                field: 'additionalIncomeUnemploymentInsurance',
                headerName: 'Zusatzverdienst RAV',
                minWidth: 225,
                align: 'center',
                headerAlign: 'center',
                valueGetter: valueGetter,
                cellClassName: cellClassName,
                valueFormatter: booleanValueFormatter,
                type: 'string',
                sortable: false
            },
            {
                field: 'disabilityAndSocialInsuranceDeductions',
                headerName: 'IV / Sozialhilfebezug',
                minWidth: 225,
                align: 'center',
                headerAlign: 'center',
                valueGetter: valueGetter,
                cellClassName: cellClassName,
                valueFormatter: booleanValueFormatter,
                type: 'string',
                sortable: false
            },
            {
                field: 'notice',
                headerName: 'Bemerkung',
                minWidth: 250,
                align: 'left',
                headerAlign: 'left',
                valueGetter: valueGetter,
                cellClassName: cellClassName,
                type: 'string',
                sortable: false
            },
            {
                field: 'employmentEndDate',
                headerName: 'Austrittsdatum',
                minWidth: 175,
                valueGetter: valueGetter,
                cellClassName: cellClassName,
                type: 'date',
                sortable: false
            },
        ];
    }

    get _rows() {
        return this.state.rows;
    }

    componentDidMount() {
        if (!this._userInfoId || !this._userInfoDetailId) {
            this._loading = false;

            return;
        }

        Promise.all([
            ApiService.getUserInfoRevisions(this._userInfoId, keycloak).then(this._importUserInfoRevisions),
            ApiService.getUserInfoDetailRevisions(this._userInfoDetailId, keycloak).then(this._importUserInfoDetailRevisions),
            ApiService.getUserInfoChildRevisions(this._userInfoDetailId, keycloak).then(this._importUserInfoChildRevisions)
        ])
            .then(this._handleRevisions)
            .finally(() => this._loading = false);
    }

    render() {
        return (
            <DataGrid columns={this._columns}
                      rows={this._rows}
                      autoHeight={true}
                      autoPageSize={true}
                      loading={this._loading}/>
        );
    }

    _importUserInfoRevisions = (revisions) => {
        this.setState({
            userInfoRevisions: revisions
        });
    }

    _importUserInfoDetailRevisions = (revisions) => {
        this.setState({
            userInfoDetailRevisions: revisions
        });
    }

    _importUserInfoChildRevisions = (revisions) => {
        this.setState({
            userInfoChildRevisions: revisions
        })
    }

    _handleRevisions = async () => {
        /**
         * @type {Map<number, {userInfo:UserInfo|undefined,userInfoDetails:UserInfoDetail|undefined,revision:{id:string,timestamp:Date,issuer:string}}>}
         */
        const revisionEntityMap = new Map();

        this._userInfoRevisions.forEach(userInfo => {
            const id = userInfo.revision.id;
            const revision = revisionEntityMap.get(id) ?? {
                revision: userInfo.revision
            };
            revision.userInfo = userInfo;
            revisionEntityMap.set(id, revision);
        });

        this._userInfoDetailRevisions.forEach(userInfoDetails => {
            const id = userInfoDetails.revision.id;
            const revision = revisionEntityMap.get(id) ?? {
                revision: userInfoDetails.revision
            };
            revision.userInfoDetails = userInfoDetails;
            revisionEntityMap.set(id, revision);
        });

        /**
         * @type {Set<string>}
         */
        const issuerIds = new Set();
        const state = {};

        const rows = [...revisionEntityMap.values()]
            .sort((a, b) => Math.sign(a.revision.id - b.revision.id))
            .map(revision => {
                issuerIds.add(revision.revision.issuer);

                const currentState = {
                    id: revision.revision.id,
                    revision: revision.revision.id,
                    issuer: revision.revision.issuer,
                    timestamp: revision.revision.timestamp,
                    employmentStartDate: new HistoryTableCell(revision?.userInfoDetails?.employmentStartDate, state?.employmentStartDate),
                    firstName: new HistoryTableCell(revision?.userInfo?.firstName, state?.firstName),
                    lastName: new HistoryTableCell(revision?.userInfo?.lastName, state?.lastName),
                    streetAndNr: new HistoryTableCell(revision?.userInfo?.streetAndNr, state?.streetAndNr),
                    zipcode: new HistoryTableCell(revision?.userInfo?.zipcode, state?.zipcode),
                    city: new HistoryTableCell(revision?.userInfo?.city, state?.city),
                    country: new HistoryTableCell(revision?.userInfo?.country, state?.country),
                    birthDate: new HistoryTableCell(revision?.userInfo?.birthDate, state?.birthDate),
                    nationality: new HistoryTableCell(revision?.userInfo?.nationality, state?.nationality),
                    residencePermit: new HistoryTableCell(revision?.userInfoDetails?.residencePermit, state?.residencePermit),
                    civilStatus: new HistoryTableCell(revision?.userInfoDetails?.civilStatus, state?.civilStatus),
                    phoneNumber: new HistoryTableCell(revision?.userInfo?.phoneNumber, state?.phoneNumber),
                    mobileNumber: new HistoryTableCell(revision?.userInfo?.mobileNumber, state?.mobileNumber),
                    bankAccountNumber: new HistoryTableCell(revision?.userInfoDetails?.bankAccountNumber, state?.bankAccountNumber),
                    socialSecurityNumber: new HistoryTableCell(revision?.userInfoDetails?.socialSecurityNumber, state?.socialSecurityNumber),
                    withholdingTax: new HistoryTableCell(revision?.userInfoDetails?.withholdingTax, state?.withholdingTax),
                    otherEmployers: new HistoryTableCell(revision?.userInfoDetails?.otherEmployers, state?.otherEmployers),
                    additionalIncomeUnemploymentInsurance: new HistoryTableCell(revision?.userInfoDetails?.additionalIncomeUnemploymentInsurance, state?.additionalIncomeUnemploymentInsurance),
                    disabilityAndSocialInsuranceDeductions: new HistoryTableCell(revision?.userInfoDetails?.disabilityAndSocialInsuranceDeductions, state?.disabilityAndSocialInsuranceDeductions),
                    notice: new HistoryTableCell(revision?.userInfoDetails?.notice, state?.notice),
                    employmentEndDate: new HistoryTableCell(revision?.userInfoDetails?.employmentEndDate, state?.employmentEndDate),
                };

                Object.assign(state, currentState);

                return currentState;
            })
            .reduce(this._reduceToDifferenceIfRequired, [])
            .sort((a, b) => Math.sign(b.id - a.id));

        const issuers = new Map([['00000000-0000-0000-0000-000000000000', 'System']]);
        for (const id of issuerIds.values()) {
            const issuer = await ApiService.getUserEmailByKeycloakId(id, keycloak);

            issuers.set(id, issuer);
        }

        this.setState({
            rows: rows,
            issuers: issuers
        });
    }

    /**
     * @param {{}[]} previous
     * @param {{}} current
     * @returns {{}[]}
     * @private
     */
    _reduceToDifferenceIfRequired = (previous, current) => {
        if (!this._diffStart && !this._diffEnd) {
            previous.push(current);

            return previous;
        }

        if (current.id !== this._diffStart && current.id !== this._diffEnd) {
            return previous;
        }

        if (current.id === this._diffStart && current.id === this._diffEnd) {
            const row = this._copyRevisionRow(current);

            previous.push(row);

            return previous;
        }

        if (current.id === this._diffStart) {
            const row = this._copyRevisionRow(current, current);

            previous.push(row);
        }

        if (current.id === this._diffEnd) {
            const currentRow = this._copyRevisionRow(current, previous[0]);

            previous.push(currentRow);
        }

        return previous;
    }

    /**
     * @param {{}} rowToCopy
     * @param {{}} [previousRow]
     * @returns {{}}
     * @private
     */
    _copyRevisionRow(rowToCopy, previousRow) {
        const rowCopy = {};

        for (const [name, cell] of Object.entries(rowToCopy)) {
            const previousCell = !!previousRow ? previousRow[name] : previousRow;

            rowCopy[name] = cell instanceof HistoryTableCell ? new HistoryTableCell(cell.data, previousCell) : cell;
        }

        return rowCopy;
    }

    _initDifference() {
        const url = new URL(window.location);
        const rawDiff = url.searchParams.get('diff');
        const diff = !!rawDiff ? rawDiff.split('..') : [undefined, undefined];

        this._diffStart = parseInt(diff[0]);
        this._diffEnd = parseInt(diff[1]);
    }
}

class HistoryTableCell {
    /**
     * @type {*}
     * @private
     */
    _data = undefined;
    /**
     * @type {boolean}
     * @private
     */
    _hasChanged = true;

    /**
     * @param {*} data
     * @param {HistoryTableCell|null|undefined} previous
     */
    constructor(data, previous) {
        this._data = data === undefined ? previous?.data : data;
        this._hasChanged = `${previous?.data}` !== `${this._data}`;
    }

    /**
     * @returns {*}
     */
    get data() {
        return this._data;
    }

    /**
     * @returns {boolean}
     */
    get hasChanged() {
        return this._hasChanged;
    }
}