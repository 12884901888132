import Grid from "@material-ui/core/Grid";
import {Button as ButtonMUI, Checkbox, FormControlLabel, RadioGroup, Slider as SliderMUI} from "@material-ui/core";
import * as React from "react";
import {Formik} from 'formik';
import ApiService from "../services/ApiService";
import keycloak from "../keycloak";
import { parse } from "query-string";
import Availability from "../model/Availability";
import authProvider from "../providers/AuthProvider";
import Caregiver from "../model/Caregiver";

/** @typedef {import('formik').FormikProps} FormikProps */
/** @typedef {import('formik').FormikValues} FormikValues */
/** @typedef {import('formik').FormikHelpers} FormikHelpers */

const DayRow = ({id, label, checkboxGet}) => {
    /**
     * @type {string[][]}
     */
    const availableWorkSlots = [
        ['morning', 'Vormittag'],
        ['afternoon', 'Nachmittag'],
        ['night', 'Nacht'],
    ];

    return (
        <Grid container>
            <Grid xs={2}>
                <div className="grayBoxStyle">{label}</div>
            </Grid>
            <Grid className="whiteBoxStyle" style={{height: "34px"}} xs={6}>
                {availableWorkSlots.map(slot => {
                    const [slotId, name] = slot;
                    const checkbox = (checkboxGet(id, slotId, name));

                    return (
                        <FormControlLabel key={slotId} style={{marginTop: "-10px"}} control={checkbox} label={name}/>
                    );
                })}
            </Grid>
        </Grid>
    )
};


export class AvailabilityResource extends React.Component {
    constructor(props) {
        super(props);
    }
    state = {
        workSlots: new Set(),
    };

    get _initialFormValues() {
        let workloads = null
        if (this.props.record.availability != null)
        {
            workloads = this.props.record.availability.workloads
        }
        return {
            fullDay: this.getTypeWorkLoad(workloads, "fullDay"),
            halfDay: this.getTypeWorkLoad(workloads, "halfDay"),
            nights: this.getTypeWorkLoad(workloads, "nights")
        }
    }

    getTypeWorkLoad(workloads, type){
        if (workloads != null) {
            for (let i = 0; i < workloads.length; i++) {
                if (workloads[i].includes(type)) {
                    return workloads[i]
                }
            }
        }
        return type
    }

    componentDidMount() {
        ApiService.getCaregiver(this.props.id, keycloak).then(result => {
            if (result.availability != null)
            {
                this.setState({workSlots: new Set(result.availability.workslots)})
            }
        })
    }

    get availableWorkSlots() {
        return [
            ['monday', 'Montag'],
            ['tuesday', 'Dienstag'],
            ['wednesday', 'Mittwoch'],
            ['thursday', 'Donnerstag'],
            ['friday', 'Freitag'],
            ['saturday', 'Samstag'],
            ['sunday', 'Sonntag'],
        ];
    }
    get halfDays() {
        return [
            {id: 'halfDay', text: 'Kein Halbtag'},
            {id: 'halfDay-1', text: '1 Halbtag'},
            {id: 'halfDay-2', text: '2 Halbtäge'},
            {id: 'halfDay-3', text: '3 Halbtäge'},
            {id: 'halfDay-4', text: '4 Halbtäge'},
            {id: 'halfDay-5', text: '5 Halbtäge'},
            {id: 'halfDay-6', text: '6 Halbtäge'},
            {id: 'halfDay-7', text: '7 Halbtäge'},
        ];
    }
    get fullDay() {
        return [
            {id: 'fullDay', text: 'Kein Tag'},
            {id: 'fullDay-1', text: '1 Tag'},
            {id: 'fullDay-2', text: '2 Täge'},
            {id: 'fullDay-3', text: '3 Täge'},
            {id: 'fullDay-4', text: '4 Täge'},
            {id: 'fullDay-5', text: '5 Täge'},
            {id: 'fullDay-6', text: '6 Täge'},
            {id: 'fullDay-7', text: '7 Täge'},
        ];
    }
    get nights() {
        return [
            {id: 'nights', text: 'Keine Nacht'},
            {id: 'nights-1', text: '1 Nacht'},
            {id: 'nights-2', text: '2 Nächte'},
            {id: 'nights-3', text: '3 Nächte'},
            {id: 'nights-4', text: '4 Nächte'},
            {id: 'nights-5', text: '5 Nächte'},
            {id: 'nights-6', text: '6 Nächte'},
            {id: 'nights-7', text: '7 Nächte'},
        ];
    }

    _toggleWorkSlot = (day, slot) => {
        const workSlots = new Set([...this.state.workSlots.values()]);
        const workSlot = day + '-' + slot;

        if (workSlots.has(workSlot)) {
            workSlots.delete(workSlot);
        } else {
            workSlots.add(workSlot);
        }

        this.setState({
            workSlots: workSlots
        });
        return workSlots.has(workSlot);
    }

    getChecked = (id, slotId, name) => {
        if (this.state.workSlots.has(id + '-' + slotId))
        {
            return <div><Checkbox name={name} defaultChecked onChange={() => this._toggleWorkSlot(id, slotId)}/></div>
        }else{
           return <Checkbox name={name} onChange={() => this._toggleWorkSlot(id, slotId)}/>
        }
    }

    getSelected(option, value) {
        if (value === option){
            return "selected"
        }
        return ""
    }

    _renderAvailableWorkSlot = (workSlot, index) => {
        const [id, label] = workSlot;

        return (<DayRow key={index}
                        label={label}
                        checkboxGet = {this.getChecked}
                        id={id}/>);
    };

    _renderForm(formik){
        return (
            <div>
                <RadioGroup>
                    {this.availableWorkSlots.map(this._renderAvailableWorkSlot)}
                    <br/>
                    <select style={{border: "solid transparent", width: "350px"}} value={formik.values.fullDay} onChange={formik.handleChange("fullDay")}>
                        {this.fullDay.map((object, id) =>
                            <option key={id} value={object.id} selected={this.getSelected(formik.values.fullDay, object.id)}>{object.text}</option>
                        )}
                    </select>
                    <br/>
                    <select style={{border: "solid transparent", width: "350px"}} value={formik.values.halfDay} onChange={formik.handleChange("halfDay")}>
                        {this.halfDays.map((object, id) =>
                            <option key={id} value={object.id} selected={this.getSelected(formik.values.halfDay, object.id)}>{object.text}</option>
                        )}
                    </select>
                    <br/>
                    <select style={{border: "solid transparent", width: "350px"}} value={formik.values.nights} onChange={formik.handleChange("nights")}>
                        {this.nights.map((object, id) =>
                            <option key={id} value={object.id} selected={this.getSelected(formik.values.nights, object.id)}>{object.text} </option>
                        )}
                    </select>
                </RadioGroup>
            </div>
        )
    }


    _onSubmit(values, actions)  {
        actions.setSubmitting(true);
        this.setState({
            loading: true
        });

        const workloadSet = this.state.workSlots;
        const workslotSet = new Set();
        let tempCaregiver = null
        ApiService.getCaregiver(this.props.id, keycloak).then(result => {
            tempCaregiver = result;
            let AvailabilityObject = new Availability();
            workslotSet.add(values.fullDay);
            workslotSet.add(values.halfDay);
            workslotSet.add(values.nights);

            AvailabilityObject.workSlots = workloadSet;
            AvailabilityObject.workLoads = workslotSet;
            tempCaregiver.availability = AvailabilityObject;
            ApiService.updateCaregiver(tempCaregiver, keycloak)
        })
    }

    render() {
        return (
            <Formik initialValues={this._initialFormValues}
                    onSubmit={this._onSubmit.bind(this)}
                    enableReinitialize={true}
            >
                {formik => (
                    <div>
                        {this._renderForm(formik)}
                        <br/>
                        <ButtonMUI variant="contained" color="secondary" className="grayBoxStyle"
                                type="submit"
                                onClick={formik.handleSubmit}>
                            Bestätigen
                        </ButtonMUI>
                    </div>
                )}
            </Formik>
        )
    }
}


