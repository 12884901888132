import React from "react";
import {DataGrid} from "@material-ui/data-grid";

const columns = [
    {
        field: 'scoreCategory', headerName: 'Punktekategorie', flex: 0.4,
        valueFormatter: (params) => params.getValue(params.id, 'question').scoreCategory.description[0].text
    },
    {
        field: 'question', headerName: 'Frage', flex: 1,
        valueFormatter: (params) => params.getValue(params.id, 'question').question[0].text
    },
    {
        field: 'answers', headerName: 'Antwort', flex: 1,
        valueFormatter: (params) => getAnswers(params)
    }
];

const getAnswers = params => {
    let answers = params.getValue(params.id, 'answers');
    let text = [];
    for(let answer of answers) {
        let prio = answer.priorityQuestionOrder ? answer.priorityQuestionOrder + ". " : "";
        text.push(prio + answer.choice.text[0].text);
    }

    return text.join(", ")
};

export default class AnswerDataGrid extends React.Component {
    render() {
        let data = [];
        if(this.props.data !== undefined)
            data = [...this.props.data];
        else if(this.props.source !== undefined)
            data = [...this.props.record[this.props.source]];

        data.sort(
            function(a, b){
                if(a.question.scoreCategory.superCategory.description[0].text <
                    b.question.scoreCategory.superCategory.description[0].text) { return -1; }
                if(a.question.scoreCategory.superCategory.description[0].text >
                    b.question.scoreCategory.superCategory.description[0].text) { return 1; }
                return 0;
            }
        );

        return (
                <DataGrid autoHeight rowHeight={100} rows={data} columns={columns} checkboxSelection loading={this.props.loading}/>
        )
    }
}
