export const languages = [
    { name: "Deutsch", id: "language-de" },
    { name: "Englisch", id: "language-en" },
    { name: "Französisch", id: "language-fr"},
    { name: "Italienisch", id: "language-it"},
    { name: "Albanisch", id: "language-sq"},
    { name: "Bosnisch", id: "language-bs"},
    { name: "Bulgarisch", id: "language-bg"},
    { name: "Dänisch", id: "language-da"},
    { name: "Estnisch", id: "language-et"},
    { name: "Finnisch", id: "language-fi"},
    { name: "Griechisch", id: "language-el"},
    { name: "Isländisch", id: "language-is"},
    { name: "Kroatisch", id: "language-hr"},
    { name: "Lettisch", id: "language-lv"},
    { name: "Litauisch", id: "language-lt"},
    { name: "Luxemburgisch", id: "language-lb"},
    { name: "Maltesisch", id: "language-mt"},
    { name: "Montenegrinisch", id: "language-cnr"},
    { name: "Mazedonisch", id: "language-mk"},
    { name: "Niederländisch", id: "language-nl"},
    { name: "Norwegisch", id: "language-no"},
    { name: "Polnisch", id: "language-pl"},
    { name: "Portugiesisch", id: "language-pt"},
    { name: "Rumänisch", id: "language-ro"},
    { name: "Russisch", id: "language-ru"},
    { name: "Schwedisch", id: "language-sv"},
    { name: "Slowakisch", id: "language-sk"},
    { name: "Slowenisch", id: "language-sl"},
    { name: "Spanisch", id: "language-es"},
    { name: "Tschechisch", id: "language-cs"},
    { name: "Türkisch", id: "language-tr"},
    { name: "Ukrainisch", id: "language-uk"},
    { name: "Ungarisch", id: "language-hu"},
    { name: "Weissrussisch", id: "language-be" }
];
