import AbstractModel from "./AbstractModel";

export default class Revision extends AbstractModel {
    /**
     * @type {number}
     * @private
     */
    _id = undefined;
    /**
     * @type {Date}
     * @private
     */
    _timestamp = undefined;
    /**
     * @type {string}
     * @private
     */
    _issuer = undefined;
    /**
     * @type {string}
     * @private
     */
    _operation = undefined;

    /**
     * @param {number} [id]
     * @param {Date} [timestamp]
     * @param {string} [issuer]
     * @param {string} [operation]
     */
    constructor(id, timestamp, issuer, operation) {
        super();

        this._id = id;
        this._timestamp = timestamp;
        this._issuer = issuer;
        this._operation = operation;
    }

    /**
     * @returns {number}
     */
    get id() {
        return this._id;
    }

    /**
     * @param {number} id
     */
    set id(id) {
        this._id = id;
    }

    /**
     * @returns {Date}
     */
    get timestamp() {
        return this._timestamp;
    }

    /**
     * @param {Date} revisionDate
     */
    set timestamp(revisionDate) {
        this._timestamp = revisionDate;
    }

    /**
     * @returns {string}
     */
    get issuer() {
        return this._issuer;
    }

    /**
     * @param {string} userId
     */
    set issuer(userId) {
        this._issuer = userId;
    }

    /**
     * @returns {string}
     */
    get operation() {
        return this._operation;
    }

    /**
     * @param {string} operation
     */
    set operation(operation) {
        this._operation = operation;
    }

    /**
     * @inheritDoc
     */
    toJSON() {
        return {
            id: this._id,
            timestamp: this._timestamp,
            issuer: this._issuer,
            operation: this._operation
        };
    }

    /**
     * @returns {Revision}
     */
    clone() {
        return this._clone(new Revision());
    }
}