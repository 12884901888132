import React from "react";
import keycloak from "../../../keycloak";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import FolderIcon from '@material-ui/icons/Folder';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import ConfirmationDialog from "../../ConfirmationDialog";
import MultiDocumentUpload from "../../MultiDocumentUpload";
import ApiService from "../../../services/ApiService";

export class DocumentsField extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            documents: [],
            loading: false,
            loadingId: null,
            deleteModalOpen: false,
            deleteModalDocument: {fileName: ''},
            deleteModalTitle: '',
        };

        this.deleteModalOnConfirm = this.deleteModalOnConfirm.bind(this);
        this.deleteModalOnClose = this.deleteModalOnClose.bind(this);
    }

    componentDidMount() {
        this.getDocuments();
    }

    getDocuments() {
        fetch(window._env_.API_URL + "/" + this.props.resource + "/" + this.props.record.id + "/document",
            {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + keycloak.token
                }
            })
            .then(response => response.json())
            .then(documents => {
                documents.sort(
                    function(a, b){
                        if(a.fileName < b.fileName) { return -1; }
                        if(a.fileName > b.fileName) { return 1; }
                        return 0;
                });
                this.setState({documents: documents})
            });
    }

    openDocument(document) {
        this.setState({loading: true, loadingId: document.id});
        return fetch(window._env_.API_URL + "/" + this.props.resource + "/" + this.props.record.id + "/document/" + document.id,
            {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + keycloak.token
                }
            })
            .then(response => response.blob())
            .then(blob => {
                window.open(window.URL.createObjectURL(blob));
                this.setState({loading: false});
            });
    }

    deleteDocument(document) {
        this.setState({loading: true, loadingId: document.id});
        return fetch(window._env_.API_URL + "/" + this.props.resource + "/" + this.props.record.id + "/document/" + document.id,
            {
                method: 'DELETE',
                headers: {
                    'Authorization': 'Bearer ' + keycloak.token
                }
            })
            .then(response => response.blob())
            .then(blob => {
                this.setState({loading: false});
                this.getDocuments();
            });
    }

    getUploadFunction() {
        if(this.props.resource === "caregiver")
            return (files) => ApiService.addCaregiverFiles(this.props.record.id, files, keycloak).then(() => this.getDocuments());
        else
            return (files) => ApiService.addClientFiles(this.props.record.id, files, keycloak).then(() => this.getDocuments());
    }

    deleteModalOnConfirm(document) {
        this.deleteDocument(document).then(
            () => this.setState({deleteModalOpen: false})
        );
    }

    deleteModalOnClose() {
        this.setState({deleteModalOpen: false});
    }

    renderAvatar(document) {
        if(this.state.loading && this.state.loadingId === document.id) {
            return <CircularProgress />;
        } else {
            return (
                <Avatar>
                    <FolderIcon/>
                </Avatar>
                );
        }
    }

    renderDocuments() {
        let renderedDocuments = [];
        if(this.state.documents.length > 0) {
            for(let document of this.state.documents) {
                renderedDocuments.push(
                    <ListItem button onClick={() => this.openDocument(document)}>
                        <ListItemAvatar>
                            <Avatar>
                                {this.renderAvatar(document)}
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={document.fileName}
                            secondary={""}
                        />
                        {keycloak.realmAccess.roles.includes("admin") ?
                                 (
                                    <ListItemSecondaryAction>
                                        <IconButton edge="end" aria-label="delete" onClick={() =>
                                            this.setState(
                                                {
                                                    deleteModalOpen: true,
                                                    deleteModalDocument: document
                                                })}>
                                            <DeleteIcon/>
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                ) : ''
                        }
                    </ListItem>
                )
            }
        } else {
            renderedDocuments.push(
                <ListItem>
                    <ListItemText
                        primary={"Keine Dokumente vorhanden."}
                        secondary={""}
                    />
                </ListItem>
            )
        }
        return renderedDocuments;
    }

    render() {
        return (
            <Grid item xs={12} md={6}>
                <div>
                    <h5 style={{marginBottom: 10, fontWeight: 'bold'}}>Dokumente</h5>
                    <List style={{marginBottom: 50}} dense={true}>
                        {this.renderDocuments()}
                    </List>
                    <h5 style={{marginBottom: 20, fontWeight: 'bold'}}>Hochladen</h5>
                    <MultiDocumentUpload uploadFunction={this.getUploadFunction()}/>
                </div>
                <ConfirmationDialog title={'Wollen Sie das Dokument ' + this.state.deleteModalDocument.fileName +
                                        ' wirklich löschen?'}
                                    description={'Gelöschte Dokumente werden permanent entfernt ' +
                                        'und können nicht wiederhergestellt werden.'}
                                    confirmText={"Löschen"}
                                    closeText={"Abbrechen"}
                                    onConfirm={() => this.deleteModalOnConfirm(this.state.deleteModalDocument)}
                                    onClose={this.deleteModalOnClose}
                                    open={this.state.deleteModalOpen}/>
            </Grid>
        );
        return this.renderDocuments();
    }
}
export default DocumentsField;
