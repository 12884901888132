import React from "react";
import {Create, DateInput, FormTab, required, SelectInput, TabbedForm, TextInput,} from 'react-admin';
import {countries} from "../util/countries";
import ApiService from "../services/ApiService";
import {Form} from "react-bootstrap";
import QuestionTextField from "./questionFields/QuestionTextField";
import RadioButtonField from "./questionFields/RadioButtonField";
import MultipleCheckboxField from "./questionFields/MultipleCheckboxField";
import PriorityField from "./questionFields/PriorityField";
import keycloak from "../keycloak";
import AnswerChoice from "../model/AnswerChoice";
import QuestionChoice from "../model/QuestionChoice";
import Local from "../model/Local";
import ClientDeclarationAnswer from "../model/ClientDeclarationAnswer"

let ClientDefaultValue = () => ({"id": null, "languages": [], "clientObservers": [], "clientProfiles" : []})

export const ClientCreate = props => {
    return (
        <Create {...props}>
            <TabbedForm initialValues={ClientDefaultValue}>
                <FormTab label={"Info"}>
                    <SelectInput label="Gender" source="userInfo.gender" validate={[required()]} choices={[
                        {id: 'male', name: 'Herr'},
                        {id: 'female', name: 'Frau'},
                        {id: 'other', name: 'Andere'},
                    ]}/>
                    <TextInput label="Email" source="userInfo.email" validate={[required()]}/>
                    <TextInput label="Vorname" source="userInfo.firstName" validate={[required()]}/>
                    <TextInput label="Nachname" source="userInfo.lastName" validate={[required()]}/>
                    <TextInput label="Strasse" source="userInfo.streetAndNr" validate={[required()]}/>
                    <TextInput label="PLZ" source="userInfo.zipcode" validate={[required()]}/>
                    <TextInput label="Ort" source="userInfo.city" validate={[required()]}/>
                    <SelectInput label="Land" source="userInfo.country" choices={countries()} validate={[required()]}/>
                    <SelectInput label="Nationalität" source="userInfo.nationality" choices={countries()}
                                 validate={[required()]}/>
                    <DateInput label="Geburtsdatum" source="userInfo.birthDate"/>
                    <TextInput type="number" label="Telefon" source="userInfo.phoneNumber" validate={[required()]}/>
                    <TextInput type="number" label="Telefon Mobil" source="userInfo.mobileNumber"
                               validate={[required()]}/>
                </FormTab>
                <FormTab label={"Deklarationsfragen"}>
                    <ClientRegistrationDeclarationForm/>
                </FormTab>
            </TabbedForm>
        </Create>
    )
}

class ClientRegistrationDeclarationForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            validated: false,
            questionAnswerMap: {},
            scoreSuperCategoryQuestionIdMap: {},
            declarationQuestionAnswerMap: {}
        };

        this.setAnswer = this.setAnswer.bind(this);
    }

    componentDidMount() {
        if(this.state.declarationQuestionAnswerMap.length > 0){
            let scoreSuperCategoryQuestionIdMap = {};
            console.log(this.props.declarationQuestionAnswerMap)
            for (let clientDeclarationQuestionAnswer of Object.values(this.props.declarationQuestionAnswerMap)) {
                this.orderByCategory(clientDeclarationQuestionAnswer.question, scoreSuperCategoryQuestionIdMap)
            }
            this.numberScoreSuperCategoriesAndQuestions(scoreSuperCategoryQuestionIdMap, this.props.declarationQuestionAnswerMap);
            this.setState({
                questionAnswerMap: this.props.declarationQuestionAnswerMap,
                scoreSuperCategoryQuestionIdMap: scoreSuperCategoryQuestionIdMap
            });
        } else {
            ApiService.getClientDeclarationQuestions(keycloak).then(clientDeclarationQuestions => {
                let questionAnswerMap = {};
                let scoreSuperCategoryQuestionIdMap = {};
                for (let clientDeclarationQuestion of clientDeclarationQuestions) {
                    questionAnswerMap[clientDeclarationQuestion.id] = {
                        question: clientDeclarationQuestion,
                        answers: clientDeclarationQuestion.answerType === "multiple-choice" ? [] : [new AnswerChoice(null, new QuestionChoice(null, [new Local(null, "", null)]))],
                        validator: this.valNotEmpty,
                        invalid: false,
                        ref: React.createRef()
                    };
                    this.orderByCategory(clientDeclarationQuestion, scoreSuperCategoryQuestionIdMap)
                }
                this.numberScoreSuperCategoriesAndQuestions(scoreSuperCategoryQuestionIdMap, questionAnswerMap);
                this.orderAnswersChoices(questionAnswerMap);
                this.setState({
                    questionAnswerMap: questionAnswerMap,
                    scoreSuperCategoryQuestionIdMap: scoreSuperCategoryQuestionIdMap
                });
            }).catch(error => console.error(error.message));
        }
    };

    orderByCategory(clientDeclarationQuestion, scoreSuperCategoryQuestionIdMap) {
        let scoreSuperCategory = clientDeclarationQuestion.scoreCategory.superCategory.description[0].text;
        if (!(scoreSuperCategory in scoreSuperCategoryQuestionIdMap))
            scoreSuperCategoryQuestionIdMap[scoreSuperCategory] = [];
        scoreSuperCategoryQuestionIdMap[scoreSuperCategory].push(
            clientDeclarationQuestion.id);
    }

    numberScoreSuperCategoriesAndQuestions(scoreSuperCategoryQuestionIdMap, questionAnswerMap) {
        let scoreSuperCategoryQuestionIdMapTemp = {};
        let superScoreCategoryNumber = 1;
        for(let superScoreCategory of Object.keys(scoreSuperCategoryQuestionIdMap)) {
            let questionIds = scoreSuperCategoryQuestionIdMap[superScoreCategory];
            let questionNumber = 1;
            for(let questionId of questionIds) {
                let question = questionAnswerMap[questionId].question;
                questionAnswerMap[questionId].question.question[0].text =
                    superScoreCategoryNumber + '.' + questionNumber + '. ' + question.question[0].text;
                questionNumber++;
            }
            scoreSuperCategoryQuestionIdMapTemp[superScoreCategoryNumber + '. ' + superScoreCategory] =
                scoreSuperCategoryQuestionIdMap[superScoreCategoryNumber];

            superScoreCategoryNumber++;
        }
        scoreSuperCategoryQuestionIdMap = scoreSuperCategoryQuestionIdMapTemp;
    }

    orderAnswersChoices(questionAnswerMap) {
        for (let questionId of Object.keys(questionAnswerMap)) {
            questionAnswerMap[questionId].question.choices.sort((first, second) => {
                if (!first || !first.viewOrder) {
                    // first is null or undefined
                    return -1;
                }
                if (!second || !second.viewOrder) {
                    // second is null or undefined
                    return 1;
                }
                return first.viewOrder - second.viewOrder;
            });
        }
    }

    setAnswer(questionId, answer, questionType) {
        let questionAnswerMap = this.state.questionAnswerMap;
        let choice = null;

        if(questionType === "single-choice") {
            for (let choiceTemp of questionAnswerMap[questionId].question.choices) {
                if (choiceTemp.text[0].text === answer) {
                    choice = choiceTemp;
                    break;
                }
            }
            questionAnswerMap[questionId].answers[0].choice = choice;
        } else if(questionType === "multiple-choice") {
            questionAnswerMap[questionId].answers = answer;
        } else if(questionType === "priority-choice") {
            questionAnswerMap[questionId].answers = answer
        } else {
            questionAnswerMap[questionId].answers[0].choice.text[0].text = answer;
        }
        let clientProfiles = [];
        for(let questionAnswer of Object.values(questionAnswerMap)) {
            let answer =
                new ClientDeclarationAnswer(null, null, questionAnswer.question.id, questionAnswer.answers);
            clientProfiles.push(answer)
        }
        ClientDefaultValue = {"id": null, "languages": [], "clientObservers": [], "clientProfiles" : clientProfiles};
        this.setState({questionAnswerMap: questionAnswerMap});
    }

    renderCaregiverRegistrationDeclarationForm() {
        if(Object.keys(this.state.questionAnswerMap).length > 0) {
            return (
                <Form>
                    {this.renderQuestions()}

                </Form>
            )
        }
        return <p>No Assessment Questions available</p>;
    }

    validate() {
        let focusTo = null;
        let allValid = true;
        let questionAnswerMap = this.state.questionAnswerMap;
        for(let questionAnswerKey in questionAnswerMap) {
            let questionAnswer = questionAnswerMap[questionAnswerKey];
            let valid = false;
            if(questionAnswer.question.answerType === "priority-choice")
                //Every choice must be chosen
                valid = questionAnswer.answers.length === questionAnswer.question.choices.length;
            else
                valid = questionAnswer.answers.length > 0 && questionAnswer.validator(questionAnswer.answers[0].choice.text[0].text);
            questionAnswer.invalid = !valid;
            if(allValid && !valid) {
                focusTo = questionAnswer.ref;
                allValid = false;
            }
        }

        if(allValid) {
            return true;
        } else {
            console.log(focusTo);
            focusTo.current.scrollIntoView();
            this.setState({
                questionAnswerMap: questionAnswerMap,
            });
            return false;
        }
    }

    renderQuestions() {
        let renderedCategories = [];

        for(let superScoreCategory of Object.keys(this.state.scoreSuperCategoryQuestionIdMap)) {
            let renderedQuestions = [];
            let questionIds = this.state.scoreSuperCategoryQuestionIdMap[superScoreCategory];
            for(let questionId of questionIds) {
                let question = this.state.questionAnswerMap[questionId].question;
                renderedQuestions.push(
                    <div style={{marginBottom: 40}}>
                        {this.renderQuestionByType(question)}
                    </div>
                );
            }
            renderedCategories.push(
                <div style={{marginTop: 20, marginBottom: 40}}>
                    <h5 style={{marginBottom: 10, fontWeight: 'bold'}}>{superScoreCategory}</h5>
                    {renderedQuestions}
                </div>
            );
        }

        return renderedCategories;
    }

    renderQuestionByType(question) {
        switch (question.answerType) {
            default:
                return <QuestionTextField setAnswer={this.setAnswer}
                                          question={question}
                                          value={this.state.questionAnswerMap[question.id].answers[0].choice.text[0].text}
                                          invalid={this.state.questionAnswerMap[question.id].invalid}
                                          ref={this.state.questionAnswerMap[question.id].ref}
                                          required/>;
            case 'single-choice':
                return <RadioButtonField setAnswer={this.setAnswer}
                                         question={question}
                                         value={this.state.questionAnswerMap[question.id].answers[0].choice.text[0].text}
                                         invalid={this.state.questionAnswerMap[question.id].invalid}
                                         ref={this.state.questionAnswerMap[question.id].ref}
                                         required/>;
            case 'multiple-choice':
                return <MultipleCheckboxField setAnswer={this.setAnswer}
                                              question={question}
                                              value={this.state.questionAnswerMap[question.id].answers}
                                              invalid={this.state.questionAnswerMap[question.id].invalid}
                                              ref={this.state.questionAnswerMap[question.id].ref}
                                              required/>;
            case 'priority-choice':
                return <PriorityField setAnswer={this.setAnswer}
                                      question={question}
                                      value={this.state.questionAnswerMap[question.id].answers}
                                      invalid={this.state.questionAnswerMap[question.id].invalid}
                                      ref={this.state.questionAnswerMap[question.id].ref}
                                      required/>;
        }
    }


    render() {
        return (
            <div>
                {this.renderCaregiverRegistrationDeclarationForm()}
            </div>
        );
    }

    valNotEmpty = (input) => {
        return input !== "";
    };
}