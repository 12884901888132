import React from "react";
import {Admin, Resource} from 'react-admin';
import '../App.css';
import {createBrowserHistory} from 'history';
import {CaregiverEdit, CaregiverList, CaregiverShow} from "./ResourceCaregiver";
import {
    ClientEdit,
    ClientList,
    ClientObserverCreate,
    ClientObserverEdit,
    ClientObserverList,
    ClientShow
} from "./ResourceClient";
import {
    CaregiverDeclarationQuestionCreate,
    CaregiverDeclarationQuestionEdit,
    CaregiverDeclarationQuestionList,
    CaregiverDeclarationQuestionShow
} from "./ResourceCaregiverDeclarationQuestion";
import {
    CaregiverAssessmentQuestionCreate,
    CaregiverAssessmentQuestionEdit,
    CaregiverAssessmentQuestionList,
    CaregiverAssessmentQuestionShow
} from "./ResourceCaregiverAssessmentQuestion";
import {ScoreCategoryCreate, ScoreCategoryEdit, ScoreCategoryList, ScoreCategoryShow} from "./ResourceScoreCategory";
import {
    ScoreSuperCategoryCreate,
    ScoreSuperCategoryEdit,
    ScoreSuperCategoryList,
    ScoreSuperCategoryShow
} from "./ResourceScoreSuperCategory";
import {withKeycloak} from "@react-keycloak/web"
import dataProvider from "../providers/DataProvider";
import authProvider from "../providers/AuthProvider";
import {
    PartnerCompanyCreate,
    PartnerCompanyEdit,
    PartnerCompanyList,
    PartnerCompanyShow
} from "./ResourcePartnerCompany";
import {MeetingEdit, MeetingList, MeetingShow} from "./ResourceMeeting";
import {
    ClientDeclarationQuestionCreate,
    ClientDeclarationQuestionEdit,
    ClientDeclarationQuestionList,
    ClientDeclarationQuestionShow
} from "./ResourceClientDeclarationQuestion";
import {AdminCreate, AdminEdit, AdminList, AdminShow} from "./ResourceAdmin";
import {QualificationEdit, QualificationList, QualificationShow} from "./ResourceQualification";
import MyLayout from "./MainViewLayout";
import MUITheme from "./MUITheme";
import {ClientCreate} from "./ResourceClientCreate";
import {CaregiverCreate} from "./ResourceCaregiverCreate";
import ProfileEdit from './ProfileEdit';

const history = createBrowserHistory();

class MainView extends React.Component {
    render() {
        if (this.props.keycloak && this.props.keycloakInitialized) {
            if (this.props.keycloak.authenticated) {
                return (
                    <Admin history={history} layout={MyLayout} dataProvider={dataProvider} authProvider={authProvider}
                           theme={MUITheme} disableTelemetry={true}>
                        {permissions => [
                            <Resource name="caregiver"
                                      list={CaregiverList} show={CaregiverShow} create={CaregiverCreate}
                                      edit={permissions.includes("admin") ? CaregiverEdit : null}
                            />,
                            // Only include the categories resource for admin users
                            permissions.includes('admin') ?
                                <Resource name="client"
                                          list={ClientList} show={ClientShow}
                                          edit={ClientEdit} create={ClientCreate}/>
                                : null,
                            permissions.includes('admin') ?
                                <Resource name="meeting"
                                          list={MeetingList} show={MeetingShow}
                                          edit={MeetingEdit}/>
                                : null,
                            <Resource name={"partnerCompany"}
                                      list={PartnerCompanyList} show={PartnerCompanyShow}
                                      create={permissions.includes("admin") ? PartnerCompanyCreate : null}
                                      edit={permissions.includes("admin") ? PartnerCompanyEdit : null}/>,
                            permissions.includes('admin') ?
                                <Resource name="clientDeclarationQuestion" show={ClientDeclarationQuestionShow}
                                          list={ClientDeclarationQuestionList} create={ClientDeclarationQuestionCreate}
                                          edit={ClientDeclarationQuestionEdit}/>
                                : null,
                            permissions.includes('admin') ?
                                <Resource name="caregiverDeclarationQuestion" show={CaregiverDeclarationQuestionShow}
                                          list={CaregiverDeclarationQuestionList}
                                          create={CaregiverDeclarationQuestionCreate}
                                          edit={CaregiverDeclarationQuestionEdit}/>
                                : null,
                            permissions.includes('admin') ?
                                <Resource name="caregiverAssessmentQuestion" show={CaregiverAssessmentQuestionShow}
                                          list={CaregiverAssessmentQuestionList}
                                          create={CaregiverAssessmentQuestionCreate}
                                          edit={CaregiverAssessmentQuestionEdit}/>
                                : null,
                            permissions.includes('admin') ?
                                <Resource name="scoreCategory" list={ScoreCategoryList} show={ScoreCategoryShow}
                                          create={ScoreCategoryCreate} edit={ScoreCategoryEdit}/>
                                : null,
                            permissions.includes('admin') ?
                                <Resource name="scoreSuperCategory" show={ScoreSuperCategoryShow}
                                          list={ScoreSuperCategoryList} create={ScoreSuperCategoryCreate}
                                          edit={ScoreSuperCategoryEdit}/>
                                : null,
                            permissions.includes('admin') ?
                                <Resource name="qualification" show={QualificationShow}
                                          list={QualificationList} edit={QualificationEdit}/>
                                : null,
                            permissions.includes('admin') ?
                                <Resource name="admin" show={AdminShow}
                                          list={AdminList} create={AdminCreate}
                                          edit={AdminEdit}/>
                                : null,
                            permissions.includes('admin') ?
                                <Resource name="clientObserver" create={ClientObserverCreate}
                                          edit={ClientObserverEdit}/>
                                : null,
                            permissions.includes('admin') ?
                                <Resource name="profile" edit={ProfileEdit}/>
                                : null,
                        ]}
                    </Admin>
                )
            } else {
                this.props.keycloak.login();
                return (<div>Unable to authenticate!</div>)
            }
        }
        return (
            <div/>
        )
    }
}

export default withKeycloak(MainView);
