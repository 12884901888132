import AbstractModel from "./AbstractModel";
import {v4 as uuid} from "uuid";

export default class UserInfoChild extends AbstractModel {
    /**
     * @type {string}
     * @private
     */
    _id = undefined;
    /**
     * @type {string}
     * @private
     */
    _firstName = undefined;
    /**
     * @type {string}
     * @private
     */
    _lastName = undefined;
    /**
     * @type {Date}
     * @private
     */
    _birthDate = undefined;
    /**
     * @type {Revision}
     * @private
     */
    _revision = undefined;

    /**
     * @param {string} [id]
     * @param {string} [firstName]
     * @param {string} [lastName]
     * @param {Date} [birthDate]
     * @param {Revision} [revision]
     */
    constructor(id, firstName, lastName, birthDate, revision) {
        super();

        this._id = id ?? uuid();
        this._firstName = firstName ?? '';
        this._lastName = lastName ?? '';
        this._birthDate = birthDate ?? new Date();
        this._revision = revision;
    }

    /**
     * @returns {string}
     */
    get id() {
        return this._id;
    }

    /**
     * @returns {string}
     */
    get firstName() {
        return this._firstName;
    }

    /**
     * @param {string} firstName
     */
    set firstName(firstName) {
        this._firstName = firstName;
    }

    /**
     * @returns {string}
     */
    get lastName() {
        return this._lastName;
    }

    /**
     * @param {string} lastName
     */
    set lastName(lastName) {
        this._lastName = lastName;
    }

    /**
     * @returns {Date}
     */
    get birthDate() {
        return this._birthDate;
    }

    /**
     * @param {Date} birthDate
     */
    set birthDate(birthDate) {
        this._birthDate = birthDate;
    }

    /**
     * @returns {Revision}
     */
    get revision() {
        return this._revision;
    }

    /**
     * @param {Revision} revision
     */
    set revision(revision) {
        this._revision = revision;
    }

    toJSON() {
        return {
            id: this._id,
            firstName: this._firstName,
            lastName: this._lastName,
            birthDate: this._birthDate
        };
    }

    /**
     * @returns {UserInfoChild}
     */
    clone() {
        return this._clone(new UserInfoChild());
    }
}