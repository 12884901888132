import * as React from "react";
import {
    ArrayField,
    ArrayInput,
    Create,
    Datagrid,
    Edit,
    Filter,
    List,
    ReferenceField,
    ReferenceInput,
    required,
    SelectField,
    SelectInput,
    Show,
    SimpleForm,
    SimpleFormIterator,
    SimpleShowLayout,
    TextField,
    TextInput
} from 'react-admin';
import LocalizedTextInput from "./LocalizedTextInput/LocalizedTextInput";
import LocalizedTextField from "./LocalizedTextField/LocalizedTextField";
import {addLocalizationToChoices, addLocalizationToQuestion} from "../util/localizationTransformer";
import Grid from "@material-ui/core/Grid";
import DataFormatter from "./DataFormatter";
import {BulkDeleteButtonWithConfirmation} from "./DeleteButtonWithConfirmation";
import OptionsButton from "./OptionsButton";

const CaregiverDeclarationQuestionFilter = (props) => (
    <Filter {...props}>
        <SelectInput label="Status" source="status" choices={[
            {id: 'disabled', name: "Deaktiviert"},
            {id: 'enabled', name: "Aktiviert"},
            {id: 'enabled-printable', name: "Aktiviert und druckbar"},
        ]}/>
    </Filter>
);

export const CaregiverDeclarationQuestionList = props => (
    <List {...props} title={"Betreuungspersonen Deklarationsfragen"} filters={<CaregiverDeclarationQuestionFilter/>}
          filterDefaultValues={{enabled: true}} bulkActionButtons={<BulkDeleteButtonWithConfirmation {...props}/>}>
        <Datagrid>
            <ReferenceField label="Punktekategorie" source="scoreCategory.id" reference="scoreCategory" sortable={false}>
                <TextField source="description[0].text" />
            </ReferenceField>
            <SelectField label="Fragentyp" sortable={false} source="answerType" choices={[
                { id: 'text', name: 'Text' },
                { id: 'single-choice', name: 'Single Choice' },
                { id: 'multiple-choice', name: 'Multiple Choice' },
                { id: 'checkbox', name: 'Checkbox' },
                { id: 'number', name: 'Nummer' },
                { id: 'priority-choice', name: 'Priorität' },
            ]}/>
            <TextField label="Frage" source="question[0].text" sortable={false} />
            <SelectField label="Status" source="status" choices={[
                {id: 'disabled', name: "Deaktiviert"},
                {id: 'enabled', name: "Aktiviert"},
                {id: 'enabled-printable', name: "Aktiviert und druckbar"},
            ]}/>
            <OptionsButton basePath="/caregiverDeclarationQuestion" label=" " record={props}/>
        </Datagrid>
    </List>
);

export const CaregiverDeclarationQuestionShow = props => (
    <Show title={<Title/>} {...props}>
        <SimpleShowLayout>
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <DataFormatter title={"Id"} Field={<TextField disabled source={"id"}/>}/>
                    <DataFormatter title={"Status"} Field={<SelectField label="Status" source="status" choices={[
                        {id: 'disabled', name: "Deaktiviert"},
                        {id: 'enabled', name: "Aktiviert"},
                        {id: 'enabled-printable', name: "Aktiviert und druckbar"},
                    ]}/>}/>
                    <DataFormatter title={"Punktekategorie"} Field={<ReferenceField source="scoreCategory.id" reference="scoreCategory">
                        <TextField source="description[0].text" />
                    </ReferenceField>}/>
                    <DataFormatter title={"Frage"} Field={<TextField source="question[0].text"/>}/>
                    <DataFormatter title={"Fragentyp"}
                                   Field={<SelectField label="Fragentyp" source="answerType" choices={[
                                       {id: 'text', name: 'Text'},
                                       {id: 'single-choice', name: 'Single Choice'},
                                       {id: 'multiple-choice', name: 'Multiple Choice'},
                                       {id: 'checkbox', name: 'Checkbox'},
                                       {id: 'number', name: 'Nummer'},
                                       {id: 'priority-choice', name: 'Priorität'},
                                   ]}/>}/>
                    <DataFormatter title={"Algorithmustyp"} Field={<TextField source="algorithmType"/>}/>
                    <DataFormatter title={"Matchingtyp"} Field={<TextField source="matchingType"/>}/>
                    <DataFormatter title={"Antwortmöglichkeiten"} Field={<ArrayField source="choices">
                        <Datagrid>
                            <LocalizedTextField source="text"/>
                            <TextField source="scoreValue"/>
                        </Datagrid>
                    </ArrayField>}/>
                </Grid>
            </Grid>
        </SimpleShowLayout>
    </Show>
);

export const CaregiverDeclarationQuestionCreate = props => (
    <Create title="Erstelle Deklarationsfrage" {...props} transform={transform}>
        <SimpleForm>
            <SelectInput label="Status" source="status" choices={[
                {id: 'disabled', name: "Deaktiviert"},
                {id: 'enabled', name: "Aktiviert"},
                {id: 'enabled-printable', name: "Aktiviert und druckbar"},
            ]}/>
            <ReferenceInput label="Punktekategorie" source="scoreCategory.id" reference="scoreCategory" validate={[required()]}>
                <SelectInput optionText="description[0].text" />
            </ReferenceInput>
            <LocalizedTextInput label="Frage" multiline fullWidth source="question" validate={[required()]}/>
            <SelectInput label="Fragentyp" source="answerType" validate={[required()]} choices={[
                { id: 'text', name: 'Text' },
                { id: 'single-choice', name: 'Single Choice' },
                { id: 'multiple-choice', name: 'Multiple Choice' },
                { id: 'checkbox', name: 'Checkbox' },
                { id: 'number', name: 'Nummer' },
                { id: 'priority-choice', name: 'Priorität' },
            ]} />
            <SelectInput label="Algorithmustyp" source="algorithmType" multiline choices={[
                { id: 'constraint', name: 'Constraint' },
                { id: 'matching', name: 'Matching' } ]}/>
            <SelectInput label="Matchingtyp" source="matchingType" multiline choices={[
                { id: 'language', name: 'Sprache' },
                { id: 'time-planning', name: 'Zeitplannung' },
                { id: 'location', name: 'Ortschaft' },
                { id: 'driving-license', name: 'Führerschein' },
                { id: 'employment-model', name: 'Anstellungsmodel' },
                { id: 'workload-planning', name: 'Arbeitsplanung' }]}/>
            <ArrayInput label="Antwortmöglichkeiten" source="choices">
                <SimpleFormIterator>
                    <LocalizedTextInput label="" multiline fullWidth source="text" validate={[required()]}/>
                    <TextInput label="" multiline fullWidth source="scoreValue"/>
                </SimpleFormIterator>
            </ArrayInput>
        </SimpleForm>
    </Create>
);

export const CaregiverDeclarationQuestionEdit = props => (
    <Edit title={<Title/>} {...props} transform={transform}>
        <SimpleForm>
            <TextInput disabled source="id" />
            <SelectInput label="Status" source="status" choices={[
                {id: 'disabled', name: "Deaktiviert"},
                {id: 'enabled', name: "Aktiviert"},
                {id: 'enabled-printable', name: "Aktiviert und druckbar"},
            ]}/>
            <ReferenceInput label="Punktekategorie" source="scoreCategory.id" reference="scoreCategory" validate={[required()]}>
                <SelectInput optionText="description[0].text" />
            </ReferenceInput>
            <LocalizedTextInput label="Frage" multiline fullWidth source="question" validate={[required()]}/>
            <SelectInput label="Fragentyp" source="answerType" validate={[required()]} choices={[
                { id: 'text', name: 'Text' },
                { id: 'single-choice', name: 'Single Choice' },
                { id: 'multiple-choice', name: 'Multiple Choice' },
                { id: 'checkbox', name: 'Checkbox' },
                { id: 'number', name: 'Nummer' },
                { id: 'priority-choice', name: 'Priorität' },
            ]} />
            <SelectInput label="Algorithmustyp" source="algorithmType" multiline choices={[
                { id: 'constraint', name: 'Constraint' },
                { id: 'matching', name: 'Matching' } ]}/>
            <SelectInput label="Matchingtyp" source="matchingType" multiline choices={[
                {id: 'language', name: 'Sprache'},
                {id: 'time-planning', name: 'Zeitplannung'},
                {id: 'location', name: 'Ortschaft'},
                {id: 'driving-license', name: 'Führerschein'},
                {id: 'employment-model', name: 'Anstellungsmodel'},
                {id: 'workload-planning', name: 'Arbeitsplanung'}]}/>
            <ArrayInput fieldKey="id" label="Antwortmöglichkeiten" source="choices">
                <SimpleFormIterator>
                    <LocalizedTextInput label="" multiline fullWidth source="text" validate={[required()]}/>
                    <TextInput label="" multiline fullWidth source="scoreValue"/>
                </SimpleFormIterator>
            </ArrayInput>
        </SimpleForm>
    </Edit>
);

const Title = ({ record }) => {
    return (
        <span>{record ? `Betreuungspersonen Deklarationsfragen - ${record.question[0].text}` : ''}</span>
    )
};

let transform = data => ({
    ...data,
    scoreCategory: data.scoreCategory.id,
    question: addLocalizationToQuestion(data.question),
    choices: addLocalizationToChoices(data.choices)
});
