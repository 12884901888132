/**
 * @param {string} birthDateStr
 * @returns {string}
 */
export function formatBirthDate(birthDateStr) {
    if (!birthDateStr) {
        return '';
    }

    const birthDate = new Date(birthDateStr);
    const day = ('' + birthDate.getDate()).padStart(2, '0');
    const month = ('' + (birthDate.getMonth() + 1)).padStart(2, '0');
    const year = '' + birthDate.getFullYear();
    const delimiter = '/';

    return day + delimiter + month + delimiter + year;
}

/**
 * @template T
 * @template R
 * @param {T} valueToCheck
 * @param {function(T):R}mapperFunc
 * @returns {R}
 */
export function mapWhenNotNullOrUndefined(valueToCheck, mapperFunc) {
    return valueToCheck === null || valueToCheck === undefined ? valueToCheck : mapperFunc(valueToCheck);
}

/**
 * @param {string} str
 * @returns {string}
 */
export function lcFirst(str) {
    return str.charAt(0).toLowerCase() + str.slice(1);
}