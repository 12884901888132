import React from "react";
import {Form} from "react-bootstrap";
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import ApiService from '../services/ApiService'
import {withKeycloak} from "@react-keycloak/web";

class MultiDocumentUpload extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            files: [null]
        };

        this.addFileCertificateUploader = this.addFileCertificateUploader.bind(this);
        this.removeFileCertificateUploader = this.removeFileCertificateUploader.bind(this);
        this.removeNullFromFiles = this.removeNullFromFiles.bind(this);
    }

    renderFileUploaderCertificate() {
        let renderedFileUploader = [];

        for(let index in this.state.files) {
            let file = this.state.files[index];

            if(file === null) {
                renderedFileUploader.push(
                    <div className="d-flex" style={{alignItems: "center"}}>
                        {this.renderRemoveFileCertificateButton(index)}
                        <input type={"file"} id={"fileCertificate" + index} accept={"application/pdf"}
                               onChange={(e) => this.addFileCertificate(e.target.files[0], index)}
                               style={{marginBottom: 5}}/>
                    </div>
                );
            } else {
                renderedFileUploader.push(
                    <div className="d-flex" style={{alignItems: "center", marginBottom: 5}}>
                        {this.renderRemoveFileCertificateButton(index)}
                        {this.state.files[index].name}
                    </div>
                )
            }
        }
        return renderedFileUploader;
    }

    renderRemoveFileCertificateButton(index) {
        if(this.state.files.length !== 1 || this.state.files[0] !== null) {
            return (
                <IconButton style={{marginRight: 10, padding: 2}}
                            onClick={() => this.removeFileCertificateUploader(index)}>
                    <RemoveCircleIcon/>
                </IconButton>
            );
        }
    }

    renderAddFileCertificateButton() {
        if(this.state.files.length < 20) {
            if(this.state.files[this.state.files.length - 1]) {
                return (
                    <IconButton style={{padding: 2}} edge="end" aria-label="delete" onClick={this.addFileCertificateUploader}>
                        <AddCircleIcon/>
                    </IconButton>
                )
            } else {
                return '';
            }
        } else {
            return (
                <p style={{fontSize: 16, marginBottom: 40}}>Sie haben die maximale Anzahl Dokumente erreicht.</p>
            )
        }
    }

    addFileCertificate(file, index) {
        let files = this.state.files;
        if(files.length <= 20) {
            files[index] = file;
            this.setState({files: files});
        }
    }

    removeFileCertificateUploader(index) {
        let files = this.state.files;
        if(files.length === 1){
            files[0] = null;
        } else {
            files.splice(index, 1);
        }
        this.setState({files: files});
    }

    addFileCertificateUploader() {
        let files = this.state.files;
        files.push(null);
        this.setState({files: files});
    }

    renderUploadButton() {
        if(this.state.files[0] !== null) {
            return (
                <Button style={{marginTop: 20, marginBottom: 20}} variant="contained"
                        onClick={() => this.props.uploadFunction(this.state.files).then(this.setState({files: [null]}))}>
                    Hochladen
                </Button>
            )
        } else {
            return '';
        }
    }

    render() {
        return (
            <div>
                <Form>
                    <Form.Group>
                        {this.renderFileUploaderCertificate()}
                    </Form.Group>
                </Form>
                {this.renderAddFileCertificateButton()}
                <div>
                    {this.renderUploadButton()}
                </div>
            </div>
        );
    }

    removeNullFromFiles() {
        if(this.state.files === 1 && this.state.files[0] === null)
            return null;

        let filteredList = [];
        for(let file of this.state.files) {
            if(file !== null)
                filteredList.push(file);
        }
        return filteredList;
    }
}
export default withKeycloak(MultiDocumentUpload);
