import MatchingParameters from "./MatchingParameters";
import AbstractModel from "./AbstractModel";

export default class Matching extends AbstractModel {
    /**
     * @type {string}
     * @private
     */
    _id = undefined;
    /**
     * @type {MatchingParameters}
     * @private
     */
    _matchingParameters = undefined;
    /**
     * @type {Date|undefined}
     * @private
     */
    _matchingCreated = undefined;
    /**
     * @type {Date|undefined}
     * @private
     */
    _matchingCompleted = undefined;
    /**
     * @type {CaregiverClientRelation[]}
     * @private
     */
    _caregiverClientRelations = undefined;

    /**
     * @param {string} [id]
     * @param {MatchingParameters} [matchingParameters]
     * @param {Date} [matchingCreated]
     * @param {Date} [matchingCompleted]
     * @param {CaregiverClientRelation[]} [caregiverClientRelations]
     */
    constructor(id, matchingParameters, matchingCreated, matchingCompleted, caregiverClientRelations) {
        super();

        this._id = id;
        this._matchingParameters = matchingParameters ?? new MatchingParameters();
        this._matchingCreated = matchingCreated;
        this._matchingCompleted = matchingCompleted;
        this._caregiverClientRelations = caregiverClientRelations;
    }

    /**
     * @returns {string}
     */
    get id() {
        return this._id;
    }

    /**
     * @param {string} id
     */
    set id(id) {
        this._id = id;
    }

    /**
     * @returns {MatchingParameters}
     */
    get matchingParameters() {
        return this._matchingParameters;
    }

    /**
     * @param {MatchingParameters} matchingParameters
     */
    set matchingParameters(matchingParameters) {
        this._matchingParameters = matchingParameters;
    }

    /**
     * @returns {Date|undefined}
     */
    get matchingCreated() {
        return this._matchingCreated;
    }

    /**
     * @param {Date|undefined} matchingCreated
     */
    set matchingCreated(matchingCreated) {
        this._matchingCreated = matchingCreated;
    }

    /**
     * @returns {Date|undefined}
     */
    get matchingCompleted() {
        return this._matchingCompleted;
    }

    /**
     * @param {Date|undefined} matchingCompleted
     */
    set matchingCompleted(matchingCompleted) {
        this._matchingCompleted = matchingCompleted;
    }

    /**
     * @returns {CaregiverClientRelation[]}
     */
    get caregiverClientRelations() {
        return this._caregiverClientRelations;
    }

    /**
     * @param {CaregiverClientRelation[]} caregiverClientRelations
     */
    set caregiverClientRelations(caregiverClientRelations) {
        this._caregiverClientRelations = caregiverClientRelations;
    }

    /**
     * @returns {{}}
     */
    toJSON() {
        return {
            id: this._id,
            matchingParameters: this._matchingParameters.toJSON(),
            matchingCreated: this._matchingCreated,
            matchingCompleted: this._matchingCompleted,
            caregiverClientRelations: this._caregiverClientRelations.map(caregiverRelation => caregiverRelation.toJSON())
        };
    }

    /**
     * @returns {Matching}
     */
    clone() {
        return this._clone(new Matching());
    }
}