import React from "react";
import './LocalizedTextInput.css';
import {Tabs, Tab, Nav} from 'react-bootstrap';
import {TextInput} from 'react-admin';
import get from "lodash/get";
import set from "lodash/set";


class LocalizedTextInput extends React.Component {

    constructor(props) {
        super(props);

        this.determineLocalization = this.determineLocalization.bind(this);

        /*let sourcePath = this.props.source.split(".");
        let locals = get(props.record, sourcePath[sourcePath.length - 1]);
        let newLocals = [];
        if(locals !== undefined && locals !== null) {
            for (let LOCAL of LOCALS) {
                for (let localIndex in locals) {
                    let local = locals[localIndex];
                    if (local.localization === LOCAL.key) {
                        newLocals.push(local);
                        break;
                    } else if (localIndex === (locals.length - 1).toString()) {
                        newLocals.push({text: "", localization: LOCAL.key});
                    }
                }
            }
        } else {
            for (let LOCAL of LOCALS) {
                newLocals.push({text: "", localization: LOCAL.key});
            }
        }
        set(props.record, sourcePath[sourcePath.length - 1], newLocals);
        console.log(this.props.record);*/
    }

    textInputStyle = {
        marginTop: 0
    };

    determineLocalization(targetLocalization) {
        /*let sourcePath = this.props.source.split(".");
        for(let localIndex in LOCALS) {
            let local = LOCALS[localIndex];
            if(local.key === targetLocalization)
                return localIndex;
        }*/
        return 0;
    }

    renderNavItems () {
        let renderedNavItems = [];
        for(let localIndex in LOCALS) {
            let local = LOCALS[localIndex];
            let className = "";
            if(localIndex === "0") className = 'nav-link-left';
            else if (localIndex === (LOCALS.length -1).toString()) className = 'nav-link-right';
            renderedNavItems.push(
                <Nav.Item>
                    <Nav.Link className={className}
                              eventKey={local.key}>{local.name}</Nav.Link>
                </Nav.Item>
            );
        }
        return renderedNavItems;
    }

    renderTabs () {
        let renderedTabs = [];
        for(let local of LOCALS) {
            renderedTabs.push(
                <Tab.Pane eventKey={local.key}>
                    <TextInput style={this.textInputStyle} label={this.props.label ? this.props.label : ''}
                               multiline={this.props.multiline} fullWidth={this.props.fullWidth}
                               record={this.props.record}
                               source={this.props.source + "[0].text"}
                               validate={this.props.validate}
                    />
                </Tab.Pane>
            )
        }
        return renderedTabs;
    }

    checkRecord() {
        let sourcePath = this.props.source.split(".");
        let locals = get(this.props.record, sourcePath[sourcePath.length - 1]);
        //console.log(locals);
    }

    render () {
        if(this.props.record) {
            return (
                <div>
                    <Tab.Container id="localizationTab" defaultActiveKey={LOCALS[0].key}>
                        <Nav variant="pills" style={{fontSize: 10}}>
                            {this.renderNavItems()}
                        </Nav>
                        <Tab.Content>
                            {this.renderTabs()}
                            {this.checkRecord()}
                        </Tab.Content>
                    </Tab.Container>
                </div>
            )
        } else {
            return ("")
        }
    }
}
export default LocalizedTextInput;

const LOCALS = [
    {name: "DE", key: "local-de"},
    //{name: "EN", key: "local-en"},
    //{name: "FR", key: "local-fr"},
    //{name: "IT", key: "local-it"},
];
