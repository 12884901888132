import React from "react";
import {Form} from "react-bootstrap";
import AnswerChoice from "../../model/AnswerChoice";



class MultipleCheckboxField extends React.Component {

    renderOptions() {
        let renderedOptions = [];
        let choices = this.props.question.choices;
        for(let choice of choices) {
            renderedOptions.push(
                <Form.Check
                    id={choice.id}
                    name={this.props.question.id}
                    label={choice.text[0].text}
                    onClick={() => this.setAnswer(choice)}
                    checked={this.containsChoice(choice.id)}
                    isInvalid={this.props.invalid}
                    required={this.props.required}
                />
            )
        }
        return renderedOptions;
    }

    setAnswer(choice) {
        let answers = this.props.value;
        let index = -1;
        for(let answerId in answers) {
            let answer = answers[answerId];
                    if(answer.choice.id === choice.id) {
                        index = answerId;
            }
        }
        if(index === -1) {
            answers.push(new AnswerChoice(null, choice))
        } else {
            answers.splice(index, 1)
        }
        console.log(answers);
        this.props.setAnswer(this.props.question.id, answers, "multiple-choice")
    }

    containsChoice(id) {
        for(let answer of this.props.value) {
            if(id === answer.choice.id) {
                return true;
            }
        }
        return false;
    }

    render() {
        return (
            <Form.Group controlId={this.props.question.id} ref={this.props.innerRef}>
                <Form.Label style={{fontWeight: 'bold'}}>{this.props.question.question[0].text   + " (Mehrere Antwortmöglichkeiten)"}{this.renderRequired()}</Form.Label>
                {this.renderOptions()}
            </Form.Group>
        )
    }

    renderRequired() {
        if(this.props.required) return '*';
        return '';
    }
}

export default React.forwardRef((props, ref) => <MultipleCheckboxField
    innerRef={ref} {...props}
/>);
