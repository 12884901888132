import AbstractModel from "./AbstractModel";

export default class UserInfo extends AbstractModel {
    constructor(id, userId, lastName, firstName, email, streetAndNr, zipcode, city, country, gender, birthDate,
                phoneNumber, mobileNumber, nationality, verified, role, relatedEntityId, details, revision) {
        super();

        this._id = id;
        this._userId = userId;
        this._lastName = lastName;
        this._firstName = firstName;
        this._email = email;
        this._streetAndNr = streetAndNr;
        this._zipcode = zipcode;
        this._city = city;
        this._country = country;
        this._gender = gender;
        this._birthDate = birthDate;
        this._phoneNumber = phoneNumber;
        this._mobileNumber = mobileNumber;
        this._nationality = nationality;
        this._verified = verified;
        this._role = role;
        this._relatedEntityId = relatedEntityId;
        this._details = details;
        this._revision = revision;
    }

    get id() {
        return this._id;
    }

    get userId() {
        return this._userId;
    }

    set userId(value) {
        this._userId = value;
    }

    get firstName() {
        return this._firstName;
    }

    set firstName(value) {
        this._firstName = value;
    }

    get lastName() {
        return this._lastName;
    }

    set lastName(value) {
        this._lastName = value;
    }

    get email() {
        return this._email;
    }

    set email(value) {
        this._email = value;
    }

    set id(value) {
        this._id = value;
    }

    get streetAndNr() {
        return this._streetAndNr;
    }

    set streetAndNr(value) {
        this._streetAndNr = value;
    }

    get zipcode() {
        return this._zipcode;
    }

    set zipcode(value) {
        this._zipcode = value;
    }

    get city() {
        return this._city;
    }

    set city(value) {
        this._city = value;
    }

    get country() {
        return this._country;
    }

    set country(value) {
        this._country = value;
    }

    get gender() {
        return this._gender;
    }

    set gender(value) {
        this._gender = value;
    }

    get birthDate() {
        return this._birthDate;
    }

    set birthDate(value) {
        this._birthDate = value;
    }

    get phoneNumber() {
        return this._phoneNumber;
    }

    set phoneNumber(value) {
        this._phoneNumber = value;
    }

    get mobileNumber() {
        return this._mobileNumber;
    }

    set mobileNumber(value) {
        this._mobileNumber = value;
    }

    get nationality() {
        return this._nationality;
    }

    set nationality(value) {
        this._nationality = value;
    }

    get verified() {
        return this._verified;
    }

    set verified(value) {
        this._verified = value;
    }

    get role() {
        return this._role;
    }

    set role(value) {
        this._role = value;
    }

    get relatedEntityId() {
        return this._relatedEntityId;
    }

    set relatedEntityId(relatedEntityId) {
        this._relatedEntityId = relatedEntityId;
    }

    get details() {
        return this._details;
    }

    set details(details) {
        this._details = details;
    }

    /**
     * @returns {Revision}
     */
    get revision() {
        return this._revision;
    }

    set revision(revision) {
        this._revision = revision;
    }

    toJSON() {
        return {
            id: this._id,
            userId: this._userId,
            email: this._email,
            lastName: this._lastName,
            firstName: this._firstName,
            streetAndNr: this._streetAndNr,
            zipcode: this._zipcode,
            city: this._city,
            country: this._country,
            gender: this._gender,
            birthDate: this._birthDate,
            phoneNumber: this._phoneNumber,
            mobileNumber: this._mobileNumber,
            nationality: this._nationality,
            verified: this._verified,
            role: this._role,
            relatedEntityId: this._relatedEntityId,
            details: this._details?.toJSON()
        }
    }

    /**
     * @returns {UserInfo}
     */
    clone() {
        return this._clone(new UserInfo());
    }
}
