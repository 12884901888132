import Availability from "./Availability";
import AbstractModel from "./AbstractModel";

export default class MatchingParameters extends AbstractModel {
    /**
     * @type {string}
     * @private
     */
    _id = undefined;
    /**
     * @type {Availability}
     * @private
     */
    _availability = undefined;
    /**
     * @type {number}
     * @private
     */
    _maxDistance = undefined;

    /**
     * @param {string} [id]
     * @param {Availability} [availability]
     * @param {number} [maxDistance]
     */
    constructor(id, availability, maxDistance) {
        super();

        this._id = id;
        this._availability = availability ?? new Availability();
        this._maxDistance = maxDistance ?? 0;
    }

    /**
     * @returns {string}
     */
    get id() {
        return this._id;
    }

    /**
     * @param {string} id
     */
    set id(id) {
        this._id = id;
    }

    /**
     * @returns {Availability}
     */
    get availability() {
        return this._availability;
    }

    /**
     * @param {Availability} availability
     */
    set availability(availability) {
        this._availability = availability;
    }

    /**
     * @returns {number}
     */
    get maxDistance() {
        return this._maxDistance;
    }

    /**
     * @param {number} maxDistance
     */
    set maxDistance(maxDistance) {
        this._maxDistance = maxDistance;
    }

    /**
     * @returns {{}}
     */
    toJSON() {
        return {
            id: this._id,
            availability: this._availability.toJSON(),
            maxDistance: this._maxDistance,
        };
    }

    /**
     * @returns {MatchingParameters}
     */
    clone() {
        return this._clone(new MatchingParameters());
    }
}