import React from "react";
import {Form} from "react-bootstrap";

class QuestionTextField extends React.Component {

    render() {
        return (
            <div ref={this.props.innerRef}>
            <Form.Group controlId={this.props.question.id}>
                <Form.Label style={{fontWeight: 'bold'}}>{this.props.question.question[0].text}{this.renderRequired()}</Form.Label>
                <Form.Control name={this.props.name}

                              required={this.props.required} type="text"
                              value={this.props.value}
                              isInvalid={this.props.invalid}
                              onChange={(e) => this.props.setAnswer(this.props.question.id, e.target.value, "text")}
                />
            </Form.Group>
            </div>
        );
    }

    renderRequired() {
        if(this.props.required) return '*';
        return '';
    }
}

export default React.forwardRef((props, ref) => <QuestionTextField
    innerRef={ref} {...props}
/>);
