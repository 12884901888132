import * as React from "react";
import {useCallback, useState} from "react";
import {
    ArrayField,
    ArrayInput,
    BooleanField,
    BooleanInput,
    Datagrid,
    DateField,
    DateInput,
    Edit,
    EmailField,
    Filter,
    FormTab,
    FunctionField,
    List,
    RecordContext,
    ReferenceField,
    ReferenceInput,
    required,
    SelectField,
    SelectInput,
    Show,
    SimpleFormIterator,
    Tab,
    TabbedForm,
    TabbedShowLayout,
    TextField,
    TextInput,
    useRecordContext,
} from 'react-admin';
import {useForm} from 'react-final-form';
import {countries} from "../util/countries";
import {DocumentsField} from "./fields/DocumentsField/DocumentsField";
import AnswerDataGrid from "./fields/AnswerDataGrid/AnswerDataGrid";
import AssessmentQuestionList from "./fields/AssessmentQuestionsList/AssessmentQuestionList";
import {languages} from "../util/languages";
import {languageLevels} from "../util/languageLevels";
import ImageEditorInput from "./ImageEditorInput/ImageEditorInput";
import PhotoField from "./fields/PhotoField/PhotoField";
import {NoteList} from "./NoteList/NoteList";
import DataFormatter from "./DataFormatter";
import OptionsButton from "./OptionsButton";
import {BulkDeleteButtonWithConfirmation} from "./DeleteButtonWithConfirmation";
import Grid from "@material-ui/core/Grid";
import {Checkbox, FormControlLabel, RadioGroup,} from "@material-ui/core";
import CaregiverHistory from './CaregiverHistory';
import {AvailabilityResource} from "./Availability";

const CaregiverFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn/>
    </Filter>
);

export const CaregiverList = (props) => (
    <List {...props} title={"Betreuungspersonen"} bulkActionButtons={<BulkDeleteButtonWithConfirmation {...props}/>}>
        <Datagrid>
            <TextField label="Nachname" source="userInfo.lastName"/>
            <TextField label="Vorname" source="userInfo.firstName"/>
            <EmailField label="Email" source="userInfo.email"/>
            <ReferenceField label="Qualifikation" source="qualification.id" reference="qualification">
                <TextField source="rank"/>
            </ReferenceField>
            <TextField label="Strasse" source="userInfo.streetAndNr"/>
            <TextField label="Ort" source="userInfo.city"/>
            <TextField label="Land" source="userInfo.country"/>
            <BooleanField label="Verifiziert" source="verified"/>
            <OptionsButton basePath="/caregiver" label=" " record={props} Printable={true}/>
        </Datagrid>
    </List>
);

export const CaregiverShow = props => (
    <Show title={<Title/>}{...props}>
        <TabbedShowLayout>
            <Tab label="Allgemein">
                <PhotoField/>
                <DataFormatter title="id" Field={<TextField source="id"/>}/>
                <DataFormatter title="Anmeldung abgeschlossen" source={<BooleanField source="registrationCompleted"/>}/>
                <DataFormatter title="Verifiziert" Field={<BooleanField source="verified"/>}/>
                <DataFormatter title="Partnerfirma"
                               Field={<ReferenceField source="partnerCompany.id" reference="partnerCompany">
                                   <TextField source="name"/>
                               </ReferenceField>}/>
                <DataFormatter title="Anrede" Field={<SelectField label="Anrede" source="userInfo.gender" choices={[
                    {id: 'male', name: 'Herr'},
                    {id: 'female', name: 'Frau'},
                    {id: 'other', name: 'Andere'},
                ]}/>}/>
                <DataFormatter title="Nachname" Field={<TextField source="userInfo.lastName"/>}/>
                <DataFormatter title="Vorname" Field={<TextField source="userInfo.firstName"/>}/>
                <DataFormatter title="Email" Field={<EmailField source="userInfo.email"/>}/>
                <DataFormatter title="Strasse" Field={<TextField source="userInfo.streetAndNr"/>}/>
                <DataFormatter title="PLZ" Field={<TextField source="userInfo.zipcode"/>}/>
                <DataFormatter title="Ort" Field={<TextField source="userInfo.city"/>}/>
                <DataFormatter title="Land" Field={<SelectField source="userInfo.country" choices={countries()}/>}/>
                <DataFormatter title="Nationalität"
                               Field={<SelectField source="userInfo.nationality" choices={countries()}/>}/>
                <DataFormatter title="Geburtsdatum" Field={<DateField source="userInfo.birthDate"/>}/>
                <DataFormatter title="Telefon" Field={<TextField type="number" source="userInfo.phoneNumber"/>}/>
                <DataFormatter title="Telefon Mobil" Field={<TextField type="number" source="userInfo.mobileNumber"/>}/>
                <DataFormatter title="Sprachkenntnisse" Field={<ArrayField source="languageSkills">
                    <Datagrid>
                        <SelectField label="Sprache" source="language" choices={languages}/>
                        <SelectField label="Level" source="level" choices={languageLevels}/>
                        <BooleanField label="Zertifiziert" source="certified"/>
                    </Datagrid>
                </ArrayField>}/>
                <DataFormatter title="Qualifikation"
                               Field={<ReferenceField source="qualification.id" reference="qualification">
                                   <FunctionField render={record => record.rank + ' - ' +
                                       record.description[0].text}/>
                               </ReferenceField>}/>
            </Tab>
            <Tab label="Deklarationsfragen">
                <AnswerDataGrid source="caregiverProfiles"/>
            </Tab>
            <Tab label="Assessmentfragen">
                <AssessmentQuestionList/>
            </Tab>
            <Tab label="Dokumente">
                <DocumentsField/>
            </Tab>
            <Tab label="Notizen">
                <NoteList caregiverId={props.id}/>
            </Tab>
            <Tab label="Historie">
                <RecordContext.Consumer>
                    {ctx => <CaregiverHistory userInfoId={ctx.userInfo?.id}
                                              userInfoDetailId={ctx.userInfo?.details?.id}/>}
                </RecordContext.Consumer>
            </Tab>
        </TabbedShowLayout>
    </Show>
);

export const CaregiverEdit = props => (
    <Edit title={<Title/>} transform={transform} {...props}>
        <TabbedForm>
            <FormTab label="Allgemein">
                <TextField source="id"/>
                <BooleanInput source="registrationCompleted"/>
                <BooleanInput source="verified"/>
                <ReferenceInput label="Partner Company" source="partnerCompany.id" reference="partnerCompany">
                    <SelectInput optionText="name" resettable/>
                </ReferenceInput>
                <SelectInput label="Gender" source="userInfo.gender" validate={[required()]} choices={[
                    {id: 'male', name: 'Herr'},
                    {id: 'female', name: 'Frau'},
                    {id: 'other', name: 'Andere'},
                ]}/>
                <TextInput label="Nachname" source="userInfo.lastName" validate={[required()]}/>
                <TextInput label="Vorname" source="userInfo.firstName" validate={[required()]}/>
                <TextInput label="Strasse" source="userInfo.streetAndNr" validate={[required()]}/>
                <TextInput label="PLZ" source="userInfo.zipcode" validate={[required()]}/>
                <TextInput label="Ort" source="userInfo.city" validate={[required()]}/>
                <SelectInput label="Land" source="userInfo.country" choices={countries()} validate={[required()]}/>
                <SelectInput label="Nationalität" source="userInfo.nationality" choices={countries()}
                             validate={[required()]}/>
                <DateInput label="Geburtsdatum" source="userInfo.birthDate"/>
                <TextInput type="phoneNumber" label="Telefon" source="userInfo.phoneNumber" validate={[required()]}/>
                <TextInput type="phoneNumber" label="Telefon Mobil" source="userInfo.mobileNumber"
                           validate={[required()]}/>
                <ArrayInput label="Sprachkenntnisse" source="languageSkills">
                    <SimpleFormIterator>
                        <SelectInput label="Sprache" source="language" choices={languages} validate={[required()]}/>
                        <SelectInput label="Level" source="level" choices={languageLevels} validate={[required()]}/>
                        <BooleanInput label="Zertifiziert" source="certified"/>
                    </SimpleFormIterator>
                </ArrayInput>
                <ReferenceInput allowEmpty label="Qualifikation" source="qualification.id" reference="qualification">
                    <SelectInput optionText={record => record.rank + ' - ' +
                        record.description[0].text}/>
                </ReferenceInput>
            </FormTab>
            <FormTab label={"Verfügbarkeiten"}>
                <AvailabilityResource {...props}/>
            </FormTab>
            <FormTab label="Profilbild">
                <ImageEditorInput/>
            </FormTab>
        </TabbedForm>
    </Edit>
);

const Title = ({record}) => {
    return (
        <span>{record ? `Betreuungsperson - ${record.userInfo.firstName + " " + record.userInfo.lastName}` : ''}</span>
    )
};

const AvailabilityEditTab = (props) => {
    const {source} = props;
    const record = useRecordContext(props);
    const form = useForm();

    // state is used to trigger a state change, so the record gets updated in "toggle"-functions
    const [version, setVersion] = useState(0);
    const handleChange = useCallback(() => setVersion(version + 1), [version]);

    if (!record) {
        return null;
    }

    /**
     * @param {string} day
     * @param {string} slot
     * @returns {boolean}
     */
    const _toggleWorkSlot = (day, slot) => {
        const values = record[source]?.workslots?.values();
        const workSlots = values ? new Set([...values]) : new Set();
        const workSlot = day + '-' + slot;

        if (workSlots.has(workSlot)) {
            workSlots.delete(workSlot);
        } else {
            workSlots.add(workSlot);
        }

        // initialize availability on record if not exist
        if (!record[source]) {
            record[source] = {workslots: undefined, workloads: undefined};
        }
        // update record value
        record[source].workslots = Array.from(workSlots);
        form.change(source, record[source]);
        handleChange();

        return workSlots.has(workSlot);
    };

    /**
     * @param {string} selectedWorkload
     */
    const _updateWorkLoads = (selectedWorkload) => {
        const [workLoadType, numberOfDays] = selectedWorkload.split(',');
        const values = record[source]?.workloads?.values();
        const workLoads = values ? new Set([...values]) : new Set();

        let workLoadFullDay = Array.from(workLoads).find(workLoad => workLoad.startsWith('fullDay'));
        let workLoadHalfDay = Array.from(workLoads).find(workLoad => workLoad.startsWith('halfDay'));
        let workLoadNights = Array.from(workLoads).find(workLoad => workLoad.startsWith('nights'));
        const newWorkLoad = numberOfDays === '0' ? workLoadType : workLoadType + '-' + numberOfDays;

        switch (workLoadType) {
            case 'halfDay':
                workLoads.delete(workLoadHalfDay);
                break;
            case 'fullDay':
                workLoads.delete(workLoadFullDay);
                break;
            case 'nights':
                workLoads.delete(workLoadNights);
                break;
        }

        workLoads.add(newWorkLoad);

        // initialize availability on record if not exist
        if (!record[source]) {
            record[source] = {workslots: undefined, workloads: undefined};
        }
        // update record value
        record[source].workloads = Array.from(workLoads);
        form.change(source, record[source]);
        handleChange();
    };

    const availableWorkSlots = [
        ['monday', 'Montag'],
        ['tuesday', 'Dienstag'],
        ['wednesday', 'Mittwoch'],
        ['thursday', 'Donnerstag'],
        ['friday', 'Freitag'],
        ['saturday', 'Samstag'],
        ['sunday', 'Sonntag'],
    ];

    /**
     * @returns {{}}
     * @private
     */
    const _initialValues = () => {
        const workSlots = [...(record[source]?.workslots ?? new Set()).values()];
        const workLoads = [...(record[source]?.workloads ?? new Set()).values()];

        const workLoadHalfDay = workLoads.find(workLoad => workLoad.startsWith('halfDay'));
        const workLoadFullDay = workLoads.find(workLoad => workLoad.startsWith('fullDay'));
        const workLoadNights = workLoads.find(workLoad => workLoad.startsWith('nights'));

        return {
            workSlots: workSlots,
            workLoadHalfDay: workLoadHalfDay,
            workLoadFullDay: workLoadFullDay,
            workLoadNights: workLoadNights,
        };
    };

    const _renderAvailableWorkSlot = (workSlot, index) => {
        const [id, label] = workSlot;

        return (<DayRow key={index}
                        toggle={_toggleWorkSlot}
                        label={label}
                        id={id}
                        initialWorkSlots={_initialValues().workSlots}/>);
    };

    return (
        <div>
            <h1>Verfügbarkeiten</h1>
            <br/>
            <RadioGroup>
                {availableWorkSlots.map(_renderAvailableWorkSlot)}
            </RadioGroup>
            <br/>
            <h1>Pensum</h1>
            <WorkLoadSelect source={`${source}.workloads`}
                            id="halfDay"
                            label="Halbtagesweise"
                            onWorkLoadSelected={_updateWorkLoads}
                            initialWorkLoad={_initialValues().workLoadHalfDay}>
            </WorkLoadSelect>
            <WorkLoadSelect source={`${source}.workloads`}
                            id="fullDay"
                            label="Tagesweise"
                            onWorkLoadSelected={_updateWorkLoads}
                            initialWorkLoad={_initialValues().workLoadFullDay}>
            </WorkLoadSelect>
            <WorkLoadSelect source={`${source}.workloads`}
                            id="nights"
                            label="Nachts"
                            onWorkLoadSelected={_updateWorkLoads}
                            initialWorkLoad={_initialValues().workLoadNights}>
            </WorkLoadSelect>
        </div>
    );
};

/**
 * @param {string} id
 * @param {string} label
 * @param {function(string,string):boolean} toggle
 * @param {string[]} initialWorkSlots
 * @returns {JSX.Element}
 * @constructor
 */
const DayRow = ({id, label, toggle, initialWorkSlots}) => {
    /**
     * @type {string[][]}
     */
    const availableWorkSlots = [
        ['morning', 'Vormittag'],
        ['afternoon', 'Nachmittag'],
        ['night', 'Nacht'],
    ];

    const _isSelected = (workSlotsSet, dayId, slotId) => {
        return workSlotsSet.includes(dayId + '-' + slotId);
    };

    return (
        <Grid container>
            <Grid xs={2}>
                <div className="grayBoxStyle">{label}</div>
            </Grid>
            <Grid className="whiteBoxStyle" style={{height: "34px"}} xs={6}>
                {availableWorkSlots.map(slot => {
                    const [slotId, name] = slot;
                    const checkbox = (<Checkbox name={name}
                                                onChange={() => toggle(id, slotId)}
                                                checked={_isSelected(initialWorkSlots, id, slotId) || false}
                                                defaultChecked={_isSelected(initialWorkSlots, id, slotId) || false}/>);
                    return (
                        <FormControlLabel key={slotId} style={{marginTop: "-10px"}} control={checkbox} label={name}/>
                    );
                })}
            </Grid>
        </Grid>
    )
};

/**
 * @param {string} id
 * @param {string} label
 * @param {function(string)} onWorkLoadSelected
 * @param {string} initialWorkLoad
 * @returns {JSX.Element}
 * @constructor
 */
const WorkLoadSelect = ({id, label, onWorkLoadSelected, initialWorkLoad}) => {
    const workLoad = initialWorkLoad?.replace('-', ',');

    const availableWorkLoads = [
        [id, "0"],
        [id, "1"],
        [id, "2"],
        [id, "3"],
        [id, "4"],
        [id, "5"],
        [id, "6"],
        [id, "7"]
    ];

    return (
        <div>
            <div className="grayBoxStyle">{label}</div>
            <select name={id}
                    id={id}
                    value={workLoad ?? -1}
                    onChange={(event) => onWorkLoadSelected(event.target.value)}>
                <option disabled={true} hidden={true} value={-1}>
                    {"Bitte auswählen"} ...
                </option>
                {availableWorkLoads.map((option, index) => <option key={index}
                                                                   value={option}>{option[1]} Tage</option>)}
            </select>
        </div>
    )
};

let transform = data => ({
    ...data,
    caregiverProfiles: transformCaregiverProfiles(data.caregiverProfiles),
    partnerCompany: data.partnerCompany ? data.partnerCompany.id : null,
    qualification: data.qualification ? data.qualification.id : null,
});

let transformCaregiverProfiles = (answers) => {
    for (let answer of answers) {
        answer.questionId = answer.question.id;
    }
    return answers
};
