import {createMuiTheme} from "@material-ui/core/styles";

const custom_theme = createMuiTheme({
    spacing: 8,
    sidebar: {
        width: '365px'
    },
    overrides: {
        MuiListItem: {
            root: {
                border: "1px solid rgb(0, 0, 0, 0.33)",
                paddingBottom: "10px",
                marginBottom: "5px",
                marginTop: "5px",
                marginLeft: "25px",
                borderRadius: "4px",
                color: "#000000"
            },
        },
        MuiMenuItem: {
            root:{
                backgroundColor:'#d6d6d6',
                width: "335px",
                '&:hover':{
                    color: 'black'
                }
            },
        },
        MuiListItemIcon: {
            root:{
                color: "rgb(0, 0, 0, 0.70)"
            },
        },
        RaMenuItemLink: {
            root: {
                color: "rgb(0, 0, 0, 0.70)"
            },
            active: {
                color: "black"
            },
        },
        MuiPaper: {
            root: {
                backgroundColor : '#f7edd4'
            }
        },
        MuiTableCell:{
            head: {
                backgroundColor: '#03438E',
                color: 'white',
            },
        },
        RaDatagrid: {
            headerCell: {
                backgroundColor: '#045fc8'
            }
        },
        MuiTab:{
            root: {
                backgroundColor : "#03438E",
            },
            textColorInherit: {
                color: "white"
            }
        },
        MuiLink:{
            root:{
                '&:hover':{
                    color: 'black'
                }
            }
        },
        MuiToolbar: {
            root: {
                paddingLeft: "24px",
            }
        }
    },
    palette: {
        primary: {
            main: '#000000'
        },
        secondary: {
            main: '#045fc8'
        },
        background: {
            default: '#faf3e3'
        }
    },
    typography: {
        fontFamily : ['Poppins', "sans-serif"]
    }
});

export default custom_theme;