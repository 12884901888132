export default class QuestionChoice {

    constructor(id, text, viewOrder) {
        this._id = id;
        this._text = text;
        this._viewOrder = viewOrder;
    }

    get id() {
        return this._id;
    }

    set id(value) {
        this._id = value;
    }

    get text() {
        return this._text;
    }

    set text(value) {
        this._text = value;
    }

    get viewOrder() {
        return this._viewOrder;
    }

    set viewOrder(value) {
        this._viewOrder = value;
    }

    toJSON() {
        let texts = [];
        for (let text of this._text) {
            texts.push(text.toJSON())
        }

        return ({
            id: this._id,
            text: texts,
            viewOrder: this._viewOrder
        })
    }
}
