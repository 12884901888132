import React, {Component} from 'react';
import {Datagrid, EditButton, ShowButton } from "react-admin";
import DeleteButtonWithConfirmation from "./DeleteButtonWithConfirmation";
import classnames from "classnames";
import IconButton from "@material-ui/core/IconButton";
import DownloadIcon from '@mui/icons-material/Download';
import ApiService from "../services/ApiService";
import keycloak from "../keycloak";

export default class OptionsButton extends Component {
    constructor() {
        super(null);
        this.basePath = "";
        this.record= null;
        this.Printable = false;
    }

    async export(caregiver) {
        ApiService.getClientPersonalSheet(caregiver.id, keycloak).then((response) => {
            response.blob().then((blob) => {
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    "personalSheet_" + caregiver.userInfo.lastName + '.pdf',
                );
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            })
        })
    }
    render() {
        return <div>
            <ShowButton basePath={this.props.basePath} record={this.props.record} label=" " />
            <EditButton basePath={this.props.basePath} label=" " record={this.props.record} />
            <DeleteButtonWithConfirmation basePath={this.props.basePath} label=" " record={this.props.record}/>
            {this.props.Printable ?
                <IconButton color={"primary"} onClick={() => this.export(this.props.record)}><DownloadIcon/></IconButton> : null
            }
        </div>
    }
}