import React from "react";
import './Note.css';
import { Grid, Paper, IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from '@material-ui/icons/Edit';
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField/TextField";
import MUIRichTextEditor from "mui-rte";
import Button from "@material-ui/core/Button";
import CircularProgress from '@material-ui/core/CircularProgress';
import { convertFromHTML, EditorState, ContentState, convertToRaw } from "draft-js";
import ApiService from "../../services/ApiService";
import {convertToHTML} from "draft-convert";
import keycloak from "../../keycloak";
import {formatDateDDMMYYYY, formatDateTimeHHMM} from "../../util/formatDateTime";


export class Note extends React.Component {

    constructor(props) {
        super(props);

        const contentHTML = convertFromHTML(props.note.text);
        const state = ContentState.createFromBlockArray(
            contentHTML.contentBlocks,
            contentHTML.entityMap
        );
        const content = JSON.stringify(convertToRaw(state));

        this.state = {
            isEditing: false,
            isLoading: false,
            defaultValue: content,
            editNoteEditorState: EditorState.createEmpty(),
            editNoteTitle: props.note.title,
        };

        this.updateNote = this.updateNote.bind(this);
        this.deleteNote = this.deleteNote.bind(this);
    }

    showRender() {
        return (
            <div>
                <h4 style={{ textAlign: "left" }}>{this.props.note.title}</h4>
                <div style={{marginBottom: 0}} dangerouslySetInnerHTML={{__html: this.props.note.text}}/>
                <Grid container wrap="nowrap" spacing={2}>
                    <Grid item xs zeroMinWidth>
                        <p style={{ fontSize: '0.8rem', margin: 0, textAlign: "left" }}>
                            {this.props.note.createdBy.firstName} {this.props.note.createdBy.lastName} am {formatDateDDMMYYYY(new Date(this.props.note.createdAt)) + ", " + formatDateTimeHHMM(new Date(this.props.note.createdAt))}
                        </p>
                        <p style={{ fontSize: '0.7rem', margin: 0, textAlign: "left", color: "gray" }}>
                            Zuletzt Editiert von {this.props.note.lastUpdatedBy.firstName} {this.props.note.lastUpdatedBy.lastName} am {formatDateDDMMYYYY(this.props.note.lastUpdatedAt) + ", " + formatDateTimeHHMM(new Date(this.props.note.lastUpdatedAt))}
                        </p>
                    </Grid>
                    <Grid item xs zeroMinWidth>
                        <p style={{ margin: 0, textAlign: "right" }}>
                            <IconButton onClick={() => this.setState({isEditing: true})} aria-label="edit">
                                <EditIcon />
                            </IconButton>
                            <IconButton aria-label="delete" onClick={this.deleteNote}>
                                <DeleteIcon />
                            </IconButton>
                        </p>
                    </Grid>
                </Grid>
            </div>
        )
    }

    updateNote() {
        let note = this.props.note;
        note["title"] = this.state.editNoteTitle;
        let htmlText = convertToHTML(this.state.editNoteEditorState.getCurrentContent());
        note["text"] = htmlText;
        ApiService.updateCaregiverNote(this.props.caregiverId, note.id, note, keycloak).then(() => {
            this.setState({isEditing: false});
            this.props.loadNotes();
        });
    }

    deleteNote() {
        ApiService.deleteCaregiverNote(this.props.caregiverId, this.props.note.id, keycloak).then(() => {
            this.props.loadNotes();
        });
    }

    editRender() {
        return (
            <div style={{margin: "20px 10px"}}>
                <TextField style={{marginBottom: 20}} fullWidth id="outlined-basic" label="Notiz Titel"
                           variant="outlined"
                           onChange={(e) => this.setState({editNoteTitle: e.target.value})}
                           value={this.state.editNoteTitle}
                />
                <Paper variant="outlined" style={{minHeight: 120, padding: "0px 20px 20px 20px"}}>
                    <MUIRichTextEditor
                        controls={["italic", "underline", "strikethrough", "highlight", "undo", "redo", "link",
                            "numberList", "bulletList", "quote"]}
                        label="Notiz Beschreibung"
                        maxLength={2047}
                        onChange={editorState => this.setState({editNoteEditorState: editorState})}
                        value={this.state.defaultValue}
                    />
                </Paper>
                <Button style={{marginTop: 20, marginRight: 20}}
                        disabled={this.state.editNoteEditorState === null ||
                        this.state.editNoteEditorState.getCurrentContent().getPlainText() === "" ||
                        this.state.editNoteTitle === "" ||
                        this.state.isSaving}
                        variant="contained" color="default" component="span"
                        onClick={this.updateNote}
                >
                    {this.state.isSaving ? <CircularProgress/> : "Senden"}
                </Button>
                <Button style={{marginTop: 20}}
                        disabled={this.state.isSaving}
                        variant="contained" color="default" component="span"
                        onClick={() => this.setState({isEditing: false})}
                >
                    {this.state.isSaving ? <CircularProgress/> : "Abbrechen"}
                </Button>
            </div>
        )
    }

    render() {
        return (
            <Paper key={this.props.note.id} style={{ margin: "20px 0px", padding: "10px 10px" }}>
                {this.state.isEditing ? this.editRender() : this.showRender()}
            </Paper>
        );
    }
}

/*

*/
