import * as React from "react";
import {
    BooleanField,
    BooleanInput,
    Button,
    Create,
    Datagrid,
    DateField,
    DateInput,
    Edit,
    EmailField,
    Filter,
    FormTab,
    Link,
    List,
    NumberField,
    required,
    SelectField,
    SelectInput,
    Show,
    SimpleForm,
    SimpleShowLayout,
    Tab,
    TabbedForm,
    TabbedShowLayout,
    TextField,
    TextInput
} from 'react-admin';
import {countries} from "../util/countries";
import DocumentsField from "./fields/DocumentsField/DocumentsField";
import AnswerDataGrid from "./fields/AnswerDataGrid/AnswerDataGrid";
import Grid from "@material-ui/core/Grid";
import DataFormatter from "./DataFormatter";
import OptionsButton from "./OptionsButton";
import {BulkDeleteButtonWithConfirmation} from "./DeleteButtonWithConfirmation";
import {parse} from "query-string";
import AddIcon from '@material-ui/icons/Add';
import {
    Button as ButtonMUI,
    Checkbox,
    FormControlLabel,
    List as ListMUI,
    ListItem,
    RadioGroup,
    Slider as SliderMUI
} from '@material-ui/core';
import keycloak from "../keycloak";
import ApiService from "../services/ApiService";

const ClientFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn/>
    </Filter>
);

export const ClientList = props => (
    <List {...props} filters={<ClientFilter/>} title={"Kunden"}
          bulkActionButtons={<BulkDeleteButtonWithConfirmation {...props}/>}>
        <Datagrid>
            <TextField label="Nachname" source="userInfo.lastName"/>
            <TextField label="Vorname" source="userInfo.firstName"/>
            <EmailField label="Email" source="userInfo.email"/>
            <TextField label="Strasse" source="userInfo.streetAndNr"/>
            <TextField label="Ort" source="userInfo.city"/>
            <TextField label="Land" source="userInfo.country"/>
            <BooleanField label="Verifiziert" source="verified"/>
            <OptionsButton basePath="/client" label=" " record={props.userInfo}/>
        </Datagrid>
    </List>
);

export const ClientShow = props => (
    <Show title={<Title/>} {...props}>
        <TabbedShowLayout>
            <Tab label="Allgemein">
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <DataFormatter title={"Verifiziert"} Field={<BooleanField source="verified"/>}/>
                        <DataFormatter title={"Anrede"} Field={<SelectField source="userInfo.gender" choices={[
                            {id: 'male', name: 'Herr'},
                            {id: 'female', name: 'Frau'},
                            {id: 'other', name: 'Andere'},
                        ]}/>}/>
                        <DataFormatter title={"Nachname"} Field={<TextField source="userInfo.lastName"/>}/>
                        <DataFormatter title={"Vorname"} Field={<TextField source="userInfo.firstName"/>}/>
                        <DataFormatter title={"Email"} Field={<EmailField source="userInfo.email"/>}/>
                        <DataFormatter title={"Strasse"} Field={<TextField source="userInfo.streetAndNr"/>}/>
                        <DataFormatter title={"PLZ"} Field={<TextField label="PLZ" source="userInfo.zipcode"/>}/>
                        <DataFormatter title={"Ort"} Field={<TextField label="Ort" source="userInfo.city"/>}/>
                        <DataFormatter title={"Land"}
                                       Field={<SelectField source="userInfo.country" choices={countries()}/>}/>
                        <DataFormatter title={"Nationalität"}
                                       Field={<SelectField source="userInfo.nationality" choices={countries()}/>}/>
                        <DataFormatter title={"Geburtsdatum"}
                                       Field={<DateField source="userInfo.birthDate"/>}/>
                        <DataFormatter title={"Telefon"} Field={<NumberField source="userInfo.phoneNumber"/>}/>
                        <DataFormatter title={"Telefon Mobil"} Field={<NumberField source="userInfo.mobileNumber"/>}/>
                    </Grid>
                </Grid>
            </Tab>
            <Tab label="Deklarationsfragen">
                <AnswerDataGrid source="clientProfiles"/>
            </Tab>
            <Tab label="Dokumente">
                <DocumentsField/>
            </Tab>
            <Tab label="Angehörige Personen">
                <AddNewObserverButton record={props}/>
                <br/>
                <ClientObserverList/>
                <br/>
            </Tab>
            <Tab label={"Matching"}>
                <MatchingTab {...props}/>
            </Tab>
        </TabbedShowLayout>
    </Show>
);

const AddNewObserverButton = ({record}) => (
    <div>
        <AddIcon/>
        <Button
            component={Link}
            to={{
                pathname: "/clientObserver/create",
                search: `?id=${record.id}`,
            }}
            label="Angehörige Person hinzufügen"
        >
        </Button>
    </div>
);


export const ClientEdit = props => (
    <Edit title={<Title/>} transform={transform} {...props}>
        <TabbedForm onSubmit={() => void (0)}>
            <FormTab label="Allgemein">
                <TextField source="id"/>
                <BooleanInput label="Verifiziert" source="verified"/>
                <SelectInput label="Anrede" source="userInfo.gender" validate={[required()]} choices={[
                    {id: 'male', name: 'Herr'},
                    {id: 'female', name: 'Frau'},
                    {id: 'other', name: 'Andere'},
                ]}/>
                <TextInput label="Nachname" source="userInfo.lastName" validate={[required()]}/>
                <TextInput label="Vorname" source="userInfo.firstName" validate={[required()]}/>
                <TextInput label="Strasse" source="userInfo.streetAndNr" validate={[required()]}/>
                <TextInput label="PLZ" source="userInfo.zipcode" validate={[required()]}/>
                <TextInput label="Ort" source="userInfo.city" validate={[required()]}/>
                <SelectInput label="Land" source="userInfo.country" choices={countries()} validate={[required()]}/>
                <SelectInput label="Nationalität" source="userInfo.nationality" choices={countries()}
                             validate={[required()]}/>
                <DateInput label="Geburtsdatum" source="userInfo.birthDate" validate={[required()]}/>
                <TextInput type="phoneNumber" label="Telefon" source="userInfo.phoneNumber" validate={[required()]}/>
                <TextInput type="phoneNumber" label="Telefon Mobil" source="userInfo.mobileNumber"
                           validate={[required()]}/>
            </FormTab>
        </TabbedForm>
    </Edit>
);

const ContactPersonPanel = ({id, record, resource}) => (
    <div>
        <SimpleShowLayout record={record}>
            <SelectField label="Anrede" source="userInfo.gender" choices={[
                {id: 'male', name: 'Herr'},
                {id: 'female', name: 'Frau'},
                {id: 'other', name: 'Andere'},
            ]}/>
            <TextField label="Nachname" source="userInfo.lastName"/>
            <TextField label="Vorname" source="userInfo.firstName"/>
            <EmailField label="Email" source="userInfo.email"/>
            <TextField label="Strasse" source="userInfo.streetAndNr"/>
            <TextField label="PLZ" source="userInfo.zipcode"/>
            <TextField label="Ort" source="userInfo.city"/>
            <SelectField label="Land" source="userInfo.country" choices={countries()}/>
            <SelectField label="Nationalität" source="userInfo.nationality" choices={countries()}/>
            <DateField label="Geburtsdatum" source="userInfo.birthDate"/>
            <TextField label="Telefon" source="userInfo.phoneNumber"/>
            <TextField label="Telefon Mobil" source="userInfo.mobileNumber"/>
        </SimpleShowLayout>
    </div>
);

const Title = ({record}) => {
    return (
        <span>{record ? `Kunde - ${record.userInfo.firstName + " " + record.userInfo.lastName}` : ''}</span>
    )
};

let transform = data => ({
    ...data,
    clientProfiles: transformClientProfiles(data.clientProfiles),
    clientObservers: data.clientObservers
});

const ClientObserverList = () => (<div/>
    /*<ReferenceManyToManyField
        reference="clientObserver"
        through="client_observer_clients"
        using="client_observer_id,client_id"
    >
        <Datagrid>
            <SelectField label="Anrede" source="userInfo.gender" choices={[
                { id: 'male', name: 'Herr' },
                { id: 'female', name: 'Frau' },
                { id: 'other', name: 'Andere' },
            ]} />
            <TextField label="Nachname" source="userInfo.lastName"/>
            <TextField label="Vorname" source="userInfo.firstName"/>
            <EmailField label="Email" source="userInfo.email"/>
            <EditButton/>
        </Datagrid>
    </ReferenceManyToManyField>*/
)

export const ClientObserverCreate = props => {
    const {id: client_id_string} = parse(props.location.search);
    const id = client_id_string ? parseInt(client_id_string, 10) : "";
    return (
        <div>
            <Create {...props}>
                <SimpleForm defaultValue={{"client": {id}}}>
                    <SelectInput label="Gender" source="userInfo.gender" validate={[required()]} choices={[
                        {id: 'male', name: 'Herr'},
                        {id: 'female', name: 'Frau'},
                        {id: 'other', name: 'Andere'},
                    ]}/>
                    <TextInput label="Email" source="userInfo.email" validate={[required()]}/>
                    <TextInput label="Vorname" source="userInfo.firstName" validate={[required()]}/>
                    <TextInput label="Nachname" source="userInfo.lastName" validate={[required()]}/>
                    <TextInput label="Strasse" source="userInfo.streetAndNr" validate={[required()]}/>
                    <TextInput label="PLZ" source="userInfo.zipcode" validate={[required()]}/>
                    <TextInput label="Ort" source="userInfo.city" validate={[required()]}/>
                    <SelectInput label="Land" source="userInfo.country" choices={countries()} validate={[required()]}/>
                    <SelectInput label="Nationalität" source="userInfo.nationality" choices={countries()}
                                 validate={[required()]}/>
                    <DateInput label="Geburtsdatum" source="userInfo.birthDate"/>
                    <TextInput type="number" label="Telefon" source="userInfo.phoneNumber" validate={[required()]}/>
                    <TextInput type="number" label="Telefon Mobil" source="userInfo.mobileNumber"
                               validate={[required()]}/>
                </SimpleForm>
            </Create>
        </div>
    )
}

export const ClientObserverEdit = props => {
    return (
        <div>
            <Edit {...props}>
                <SimpleForm redirect={"/client"}>
                    <SelectInput label="Gender" source="userInfo.gender" validate={[required()]} choices={[
                        {id: 'male', name: 'Herr'},
                        {id: 'female', name: 'Frau'},
                        {id: 'other', name: 'Andere'},
                    ]}/>
                    <TextInput label="Email" source="userInfo.email" validate={[required()]}/>
                    <TextInput label="Vorname" source="userInfo.firstName" validate={[required()]}/>
                    <TextInput label="Nachname" source="userInfo.lastName" validate={[required()]}/>
                    <TextInput label="Strasse" source="userInfo.streetAndNr" validate={[required()]}/>
                    <TextInput label="PLZ" source="userInfo.zipcode" validate={[required()]}/>
                    <TextInput label="Ort" source="userInfo.city" validate={[required()]}/>
                    <SelectInput label="Land" source="userInfo.country" choices={countries()} validate={[required()]}/>
                    <SelectInput label="Nationalität" source="userInfo.nationality" choices={countries()}
                                 validate={[required()]}/>
                    <DateInput label="Geburtsdatum" source="userInfo.birthDate"/>
                    <TextInput type="number" label="Telefon" source="userInfo.phoneNumber" validate={[required()]}/>
                    <TextInput type="number" label="Telefon Mobil" source="userInfo.mobileNumber"
                               validate={[required()]}/>
                </SimpleForm>
            </Edit>
        </div>
    )
}

class MatchingTab extends React.Component {
    state = {
        matchingResult: undefined,
        matchingParameters: undefined,
        workSlots: undefined,
        maxDistance: undefined
    };

    /**
     * @returns {number}
     */
    get id() {
        return this.props.id;
    }

    /**
     * @returns {Matching}
     */
    get matchingResult() {
        return this.state.matchingResult;
    }

    /**
     * @returns {MatchingParameters}
     */
    get matchingParameters() {
        return this.state.matchingParameters;
    }

    /**
     * @returns {Set<string>}
     */
    get workSlots() {
        return this.state.workSlots;
    }

    /**
     * @returns {number}
     */
    get maxDistance() {
        return this.state.maxDistance;
    }

    /**
     * @returns {boolean}
     */
    get isMatchingExecuted() {
        return !!this.matchingResult.matchingCreated;
    }

    /**
     * @returns {string[][]}
     */
    get availableWorkSlots() {
        return [
            ['monday', 'Montag'],
            ['tuesday', 'Dienstag'],
            ['wednesday', 'Mittwoch'],
            ['thursday', 'Donnerstag'],
            ['friday', 'Freitag'],
            ['saturday', 'Samstag'],
            ['sunday', 'Sonntag'],
        ];
    }

    /**
     * @param {string} day
     * @param {string} slot
     * @returns {boolean}
     */
    _toggleWorkSlot = (day, slot) => {
        const workSlots = new Set([...this.workSlots.values()]);
        const workSlot = day + '-' + slot;

        if (workSlots.has(workSlot)) {
            workSlots.delete(workSlot);
        } else {
            workSlots.add(workSlot);
        }

        this.setState({
            workSlots: workSlots
        });

        return workSlots.has(workSlot);
    }

    _setMaxDistance = (event, maxDistance) => {
        this.setState({
            maxDistance: maxDistance
        });
    };

    /**
     * @param {Matching} matching
     * @private
     */
    _importMatching = (matching) => {
        this.setState({
            matchingResult: matching,
            matchingParameters: matching.matchingParameters,
            workSlots: matching.matchingParameters.availability.workSlots,
            maxDistance: matching.matchingParameters.maxDistance
        });
    }

    componentDidMount = () => {
        ApiService.getActiveMatching(this.id, keycloak).then(this._importMatching);
    }

    _onSubmit = () => {
        const parameters = this.matchingParameters.clone();

        parameters.availability.workSlots = this.workSlots;
        parameters.maxDistance = this.maxDistance;

        ApiService.triggerMatching(this.id, parameters, keycloak).then(this._importMatching);
    };

    _renderAvailableWorkSlot = (workSlot, index) => {
        const [id, label] = workSlot;

        return (<DayRow key={index}
                        toggle={this._toggleWorkSlot}
                        label={label}
                        id={id}/>);
    };

    _renderDistanceSlider = () => (
        <Grid container>
            <Grid xs={2}>
                <label>Distanz</label>
            </Grid>
            <Grid style={{height: "34px"}} xs={4}>
                <SliderMUI min={0} max={500} onChange={this._setMaxDistance}/>
            </Grid>
            <Grid style={{height: "34px"}} xs={1}>
                <div className="ml-2" style={{lineHeight: '30px'}}>{this.maxDistance} km</div>
            </Grid>
        </Grid>
    );

    /**
     * @param {CaregiverClientRelation} caregiverClientRelation
     * @param {number} index
     * @returns {JSX.Element}
     * @private
     */
    _renderMatchingResultRow = (caregiverClientRelation, index) => {
        const firstName = caregiverClientRelation.caregiver.userInfo.firstName;
        const lastName = caregiverClientRelation.caregiver.userInfo.lastName;
        const streetAndNr = caregiverClientRelation.caregiver.userInfo.streetAndNr;
        const PLZ = caregiverClientRelation.caregiver.userInfo.zipcode;
        const Id = caregiverClientRelation.caregiver.id;
        return (
            <ListItem key={index}>
                <Grid container>
                    <Grid xs={3}>
                        {firstName}
                    </Grid>
                    <Grid xs={3}>
                        {lastName}
                    </Grid>
                    <Grid xs={2}>
                        {streetAndNr}
                    </Grid>
                    <Grid xs={2}>
                        {PLZ}
                    </Grid>
                    <Grid xs={2}>
                        <a href={`/caregiver/${Id}/show`}>Zum Profil</a>
                    </Grid>
                </Grid>
            </ListItem>
        );
    }

    render() {
        if (!this.matchingResult) {
            return null;
        }

        return (<div>
                <h1>Matching</h1>
                <br/>
                <div>
                    <RadioGroup>
                        {this.availableWorkSlots.map(this._renderAvailableWorkSlot)}
                    </RadioGroup>
                    {this._renderDistanceSlider()}
                    <br/>
                    <ButtonMUI variant="contained" color="secondary" onClick={this._onSubmit}>
                        Matching ausführen
                    </ButtonMUI>
                    <br/>
                    <br/>
                    {this.matchingResult.caregiverClientRelations.length === 0 && this.isMatchingExecuted && (
                        <p>Keine Resultate vorhanden.</p>
                    )}
                    {this.isMatchingExecuted && this.matchingResult.caregiverClientRelations.length > 0 && (
                        <ListMUI component="ul" style={{width: "650px", marginLeft: "-20px"}}>
                            {this.matchingResult.caregiverClientRelations.map(this._renderMatchingResultRow)}
                        </ListMUI>
                    )}
                </div>
                <br/>
                <p>Matching zuletzt ausgeführt am {this.matchingResult.matchingCreated?.toLocaleString() ?? '-'}</p>
            </div>
        )
    }
}

let transformClientProfiles = (answers) => {
    for (let answer of answers) {
        answer.questionId = answer.question.id;
    }
    return answers
};

/**
 * @param {string} id
 * @param {string} label
 * @param {function(string,string):boolean} toggle
 * @returns {JSX.Element}
 * @constructor
 */
const DayRow = ({id, label, toggle}) => {
    /**
     * @type {string[][]}
     */
    const availableWorkSlots = [
        ['morning', 'Vormittag'],
        ['afternoon', 'Nachmittag'],
        ['night', 'Nacht'],
    ];

    return (
        <Grid container>
            <Grid xs={2}>
                <div className="grayBoxStyle">{label}</div>
            </Grid>
            <Grid className="whiteBoxStyle" style={{height: "34px"}} xs={6}>
                {availableWorkSlots.map(slot => {
                    const [slotId, name] = slot;
                    const checkbox = (<Checkbox name={name} onChange={() => toggle(id, slotId)}/>);

                    return (
                        <FormControlLabel key={slotId} style={{marginTop: "-10px"}} control={checkbox} label={name}/>
                    );
                })}
            </Grid>
        </Grid>
    )
};

