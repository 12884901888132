import * as React from "react";
import {
    Toolbar, SaveButton, Show, SimpleShowLayout, List, Edit, SimpleForm,
    Datagrid, TextField, TextInput, required, EditButton, DeleteButton
} from 'react-admin';
import Grid from "@material-ui/core/Grid";
import DataFormatter from "./DataFormatter";
import OptionsButton from "./OptionsButton";
import {BulkDeleteButtonWithConfirmation} from "./DeleteButtonWithConfirmation";

export const QualificationList = props => (
    <List {...props} title={"Meetings"} sort={{ field: 'rank', order: 'ASC' }} bulkActionButtons={<BulkDeleteButtonWithConfirmation {...props}/>}>
        <Datagrid>
            <TextField label="Stufe" source="rank"/>
            <TextField label="Beschreibung" source="description[0].text"/>
            <OptionsButton basePath="/qualification" label=" " record={props}/>
        </Datagrid>
    </List>
);

export const QualificationShow = props => (
    <Show {...props} title={<Title/>}>
        <SimpleShowLayout>
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <DataFormatter title={"Stufe"} Field={<TextField source="rank"/>}/>
                    <DataFormatter title={"Beschreibung"} Field={<TextField source="description[0].text"/>}/>
                </Grid>
            </Grid>
        </SimpleShowLayout>
    </Show>
);

const EditToolbar = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);

export const QualificationEdit = props => (
    <Edit title={<Title/>} {...props}>
        <SimpleForm toolbar={<EditToolbar/>}>
            <TextField label="Stufe" source="rank"/>
            <TextInput label="Beschreibung" source="description[0].text"/>
        </SimpleForm>
    </Edit>
);

const Title = ({ record }) => {
    return (
        <span>{record ? `Qualifikation -  ${record.rank + " - " + record.description[0].text}` : ''}</span>
    )
};
