import * as React from "react";
import {ReactKeycloakProvider} from '@react-keycloak/web';
import keycloak from './keycloak';
import 'bootstrap/dist/css/bootstrap.min.css';
import MainView from "./components/MainView";
import {BrowserRouter} from 'react-router-dom'

function App() {
    return (
        <BrowserRouter>
            <ReactKeycloakProvider authClient={keycloak}>
                <MainView/>
            </ReactKeycloakProvider>
        </BrowserRouter>
    );
}

export default App;
