import React from 'react';
import BackupIcon from '@material-ui/icons/Backup';
import Button from '@material-ui/core/Button';

const FileUploader = props => {
    const hiddenFileInput = React.useRef(null);

    const handleClick = event => {
        hiddenFileInput.current.click();
    };
    const handleChange = event => {
        const fileUploaded = event.target.files[0];
        props.handleFile(fileUploaded);
    };
    return (
        <div style={{display: "flex"}}>
            <label htmlFor="icon-button-file" style={{margin: 0, marginRight: 5}}>
                <Button variant="contained" color="primary" aria-label="upload picture" component="span">
                    <BackupIcon />
                </Button>
            </label>
            <p style={{margin: 0, alignSelf: 'center', wordBreak: "break-all"}}>{props.file ? props.file.name : "Kein Bild ausgewählt"}</p>
            <input type="file" id="icon-button-file" accept={"image/*"}
                   ref={hiddenFileInput}
                   onChange={handleChange}
                   style={{display:'none'}}
            />
        </div>
    );
};
export default FileUploader;
