export const formatDateDDMMYYYY = (date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [day, month, year].join('.');
};

export const formatDateTimeHHMM = (date) => {
    var d = new Date(date),
        hour = '' + (d.getHours()),
        min = '' + (d.getMinutes());

    if (hour.length < 2)
        hour = '0' + hour;
    if (min.length < 2)
        min = '0' + min;

    return [hour, min].join(':');
};

export const dateOptions = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
};

export const dateTimeOptions = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
};
