import AbstractModel from "./AbstractModel";

export default class CaregiverClientRelation extends AbstractModel {
    /**
     * @type {number}
     * @private
     */
    _id = undefined;
    /**
     * @type {Caregiver}
     * @private
     */
    _caregiver = undefined;
    /**
     * @type {Client}
     * @private
     */
    _client = undefined;
    /**
     * @type {Date}
     * @private
     */
    _requestDate = undefined;
    /**
     * @type {Date}
     * @private
     */
    _clientAcceptedDate = undefined;
    /**
     * @type {Date}
     * @private
     */
    _caregiverAcceptedDate = undefined;
    /**
     * @type {Date}
     * @private
     */
    _closedDate = undefined;
    /**
     * @type {string}
     * @private
     */
    _relationState = undefined;

    /**
     * @param {number} [id]
     * @param {Caregiver} [caregiver]
     * @param {Client} [client]
     * @param {Date} [requestDate]
     * @param {Date} [clientAcceptedDate]
     * @param {Date} [caregiverAcceptedDate]
     * @param {Date} [closedDate]
     * @param {string} [relationState]
     */
    constructor(
        id,
        caregiver,
        client,
        requestDate,
        clientAcceptedDate,
        caregiverAcceptedDate,
        closedDate,
        relationState
    ) {
        super();

        this._id = id;
        this._caregiver = caregiver;
        this._client = client;
        this._requestDate = requestDate;
        this._clientAcceptedDate = clientAcceptedDate;
        this._caregiverAcceptedDate = caregiverAcceptedDate;
        this._closedDate = closedDate;
        this._relationState = relationState;
    }

    /**
     * @returns {number}
     */
    get id() {
        return this._id;
    }

    /**
     * @param {number} id
     */
    set id(id) {
        this._id = id;
    }

    /**
     * @returns {Caregiver}
     */
    get caregiver() {
        return this._caregiver;
    }

    /**
     * @param {Caregiver} caregiver
     */
    set caregiver(caregiver) {
        this._caregiver = caregiver;
    }

    /**
     * @returns {Client}
     */
    get client() {
        return this._client;
    }

    /**
     * @param {Client} client
     */
    set client(client) {
        this._client = client;
    }

    /**
     * @returns {Date}
     */
    get requestDate() {
        return this._requestDate;
    }

    /**
     * @param {Date} requestDate
     */
    set requestDate(requestDate) {
        this._requestDate = requestDate;
    }

    /**
     * @returns {Date}
     */
    get clientAcceptedDate() {
        return this._clientAcceptedDate;
    }

    /**
     * @param {Date} clientAcceptedDate
     */
    set clientAcceptedDate(clientAcceptedDate) {
        this._clientAcceptedDate = clientAcceptedDate;
    }

    /**
     * @returns {Date}
     */
    get caregiverAcceptedDate() {
        return this._caregiverAcceptedDate;
    }

    /**
     * @param {Date} caregiverAcceptedDate
     */
    set caregiverAcceptedDate(caregiverAcceptedDate) {
        this._caregiverAcceptedDate = caregiverAcceptedDate;
    }

    /**
     * @returns {Date}
     */
    get closedDate() {
        return this._closedDate;
    }

    /**
     * @param {Date} closedDate
     */
    set closedDate(closedDate) {
        this._closedDate = closedDate;
    }

    /**
     * @returns {string}
     */
    get relationState() {
        return this._relationState;
    }

    /**
     * @param {string} relationState
     */
    set relationState(relationState) {
        this._relationState = relationState;
    }

    toJSON() {
        return {
            id: this._id,
            caregiver: this._caregiver.toJSON(),
            client: this._client.toJSON(),
            requestDate: this._requestDate,
            clientAcceptedDate: this._clientAcceptedDate,
            caregiverAcceptedDate: this._caregiverAcceptedDate,
            closedDate: this._closedDate,
            relationState: this._relationState
        };
    }

    /**
     * @returns {CaregiverClientRelation}
     */
    clone() {
        return this._clone(new CaregiverClientRelation());
    }
}