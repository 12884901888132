import React from "react";
import keycloak from "../../keycloak";
import ApiService from "../../services/ApiService";
import CircularProgress from '@material-ui/core/CircularProgress';
import {Note} from "../Note/Note";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import MUIRichTextEditor from 'mui-rte';
import { Paper } from "@material-ui/core";
import { convertToRaw, convertFromRaw, EditorState, ContentState, Editor } from 'draft-js';
import { convertToHTML, convertFromHTML } from 'draft-convert';

export class NoteList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            isSaving: false,
            notes: [],
            newNoteTitle: "",
            newNoteEditorState: EditorState.createEmpty()
        };

        this.loadNotes = this.loadNotes.bind(this);
    }

    componentDidMount() {
        this.loadNotes();
    }

    loadNotes() {
        ApiService.getCaregiverNotes(this.props.record.id, keycloak).then (notes => {
            this.setState({isLoading: false, notes: notes});
        });
    }

    saveNote() {
        let htmlText = convertToHTML(this.state.newNoteEditorState.getCurrentContent());

        let note = {
            title: this.state.newNoteTitle,
            text: htmlText
        };

        this.setState({isSaving: true});
        ApiService.addCaregiverNote(this.props.record.id, note, keycloak).then(() => {
            const editorState = EditorState.push(this.state.newNoteEditorState, ContentState.createFromText(''));
            this.setState({
                newNoteEditorState: editorState,
                isSaving: false,
                newNoteTitle: ""
            });
            this.loadNotes();
        });
    }

    renderNotes() {
        if(this.state.notes.length > 0) {
            let renderedNotes = [];
            for (let i = this.state.notes.length - 1; i >= 0; i--) {
                let note = this.state.notes[i];
                renderedNotes.push(
                    <Note note={note}
                          caregiverId={this.props.caregiverId}
                          loadNotes={this.loadNotes}
                    />
                )
            }
            return renderedNotes;
        }
        else return <p style={{margin: "40px 0px", textAlign: "center"}}>Keine Notizen vorhanden</p>
    }

    renderInputField() {
        return (
            <div style={{margin: "20px 10px"}}>
                <Divider style={{margin: "40px 0px"}}/>
                <TextField style={{marginBottom: 20}} fullWidth id="outlined-basic" label="Notiz Titel"
                           variant="outlined"
                           onChange={(e) => this.setState({newNoteTitle: e.target.value})}
                />
                <Paper variant="outlined" style={{minHeight: 120, padding: "0px 20px 20px 20px"}}>
                    <MUIRichTextEditor
                        controls={["italic", "underline", "strikethrough", "highlight", "undo", "redo", "link",
                            "numberList", "bulletList", "quote"]}
                        label="Notiz Beschreibung"
                        maxLength={2047}
                        onChange={editorState => this.setState({newNoteEditorState: editorState})}
                    />
                </Paper>
                <Button style={{marginTop: 20}}
                        disabled={this.state.newNoteEditorState === null ||
                            this.state.newNoteEditorState.getCurrentContent().getPlainText() === "" ||
                            this.state.newNoteTitle === "" ||
                            this.state.isSaving}
                        variant="contained" color="default" component="span"
                        onClick={() => this.saveNote()}
                >
                    {this.state.isSaving ? <CircularProgress/> : "Senden"}
                </Button>
            </div>
        )
    }

    render() {
        if(this.state.isLoading) return <CircularProgress/>;
        else {
            return (
                <div>
                    {this.renderNotes()}
                    {this.renderInputField()}
                </div>
            )
        }
    }
}
