import React, { Component } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {Form} from "react-bootstrap";
import AnswerChoice from "../../model/AnswerChoice";


// convert data
const getItems = (data) =>
    Array.from(data, item => ({
        choice: item,
        id: "" + item.id,
        content: item.text[0].text,
        prio: null
    }));

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const repriorize = (list) => {
    for(let index in list) {
        let item = list[index];
        item.prio = ++index;
    }
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: grid * 1.5,
    margin: `0 0 ${grid}px 0`,
    borderRadius: 5,
    border: isDragging ? "1px solid #9EAF5B" : "1px solid #C5B492",

    // change background colour if dragging
    background: isDragging ? '#E6EEC5' : '#FAE9C5',

    // styles we need to apply on draggables
    ...draggableStyle
});

const getListHeaderStyle = {
    margin: -9,
    borderRadius:"5px 5px 0px 0px",
    marginBottom: 10,
    border: "1px solid #E2DBCB",
    background: "#EEE1C9",
    textAlign: "center",
    padding: 5
};

const getListStyle = isDraggingOver => ({
    borderRadius: 5,
    border: "1px solid #E2DBCB",
    background: isDraggingOver ? '#CEDBEE' : '#FFFBF4',
    padding: grid,
    width: 250,
});

const getArrowStyle = {
    marginLeft: 30,
    marginRight: 30,
    fontSize: "4rem",
    paddingTop: 50,
    color: "#bbbbbb"
};

class PriorityField extends Component {

    constructor(props) {
        super(props);
        this.state = {
            items: getItems(props.question.choices),
            selected: []
        };
    }

    /**
     * A semi-generic way to handle multiple lists. Matches
     * the IDs of the droppable container to the names of the
     * source arrays stored in the state.
     */
    id2List = {
        droppable: 'items',
        droppable2: 'selected'
    };

    getList = id => this.state[this.id2List[id]];

    onDragEnd = result => {
        const { source, destination } = result;

        // dropped outside the list
        if (!destination) {
            return;
        }

        if (source.droppableId === destination.droppableId) {
            const items = reorder(
                this.getList(source.droppableId),
                source.index,
                destination.index
            );

            let state = { items };

            if (source.droppableId === 'droppable2')
            {
                console.log(items);
                repriorize(items);
                state = { selected: items };
                this.setAnswer(items);
            }

            this.setState(state);
        } else {
            const result = move(
                this.getList(source.droppableId),
                this.getList(destination.droppableId),
                source,
                destination
            );

            repriorize(result.droppable2);

            this.setState({
                items: result.droppable,
                selected: result.droppable2
            });
            console.log(result.droppable2);
            this.setAnswer(result.droppable2);
        }
    };

    setAnswer(items) {
        let answers = [];
        for(let answer of items) {
            console.log(answer);
            answers.push(new AnswerChoice(null, answer.choice, answer.prio));
        }

        this.props.setAnswer(this.props.question.id, answers, "multiple-choice")
    }

    render() {
        return (
            <div ref={this.props.innerRef}>
                <Form.Group controlId={this.props.question.id}>
                    <Form.Label style={{fontWeight: 'bold'}}>{this.props.question.question[0].text}{this.renderRequired()}</Form.Label>
                    <p>Ziehen Sie die Optionen aus der linken Liste in die rechte Liste und ordnen sie die Optionen
                        nach Ihrer persönlicher Priorität.
                    </p>
                    {this.renderFields()}
                </Form.Group>
            </div>
        );
    }

    renderFields() {
        return (
            <div style={{display: "flex"}}>
                <DragDropContext onDragEnd={this.onDragEnd}>
                    <Droppable droppableId="droppable" >
                        {(provided, snapshot) => (
                            <div
                                ref={provided.innerRef}
                                style={getListStyle(snapshot.isDraggingOver)}>
                                <div style={getListHeaderStyle}>Optionen</div>
                                {this.state.items.map((item, index) => (
                                    <Draggable
                                        key={item.id}
                                        draggableId={item.id}
                                        index={index}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={getItemStyle(
                                                    snapshot.isDragging,
                                                    provided.draggableProps.style
                                                )}>
                                                {item.content}
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                    <div style={getArrowStyle}>

                    </div>
                    <Droppable droppableId="droppable2">
                        {(provided, snapshot) => (
                            <div
                                ref={provided.innerRef}
                                style={getListStyle(snapshot.isDraggingOver)}>
                                <div style={getListHeaderStyle}>Priorisierung</div>
                                {this.state.selected.map((item, index) => (
                                    <Draggable
                                        key={item.id}
                                        draggableId={item.id}
                                        index={index}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={getItemStyle(
                                                    snapshot.isDragging,
                                                    provided.draggableProps.style
                                                )}>
                                                {item.prio}. {item.content}
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
        )
    }

    renderRequired() {
        if(this.props.required) return '*';
        return '';
    }
}

export default React.forwardRef((props, ref) => <PriorityField
    innerRef={ref} {...props}
/>);
