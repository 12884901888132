import simpleRestProvider from "ra-data-simple-rest";
import {fetchUtils} from 'react-admin';
import keycloak from "../keycloak";
import ApiService from "../services/ApiService";
import Email from "../model/Email";

const updateEmailAddress = params => {
    const verifyUrl = new URL(window.location);

    verifyUrl.search = 'token={token}';

    const email = new Email(params.data.email, verifyUrl.toString());

    return ApiService.initEmailChange(email, keycloak).then(() => params);
};

const withUserProfileHandler = dataProvider => ({
    ...dataProvider,
    getOne: (resource, params) => {
        return resource === 'profile' ? Promise.resolve({
            data: {
                id: '68cfd4dd-3520-44fc-af11-c84b49c1760e',
                email: ''
            }
        }) : dataProvider.getOne(resource, params);
    },
    update: (resource, params) => {
        return resource === 'profile' ? updateEmailAddress(params) : dataProvider.update(resource, params);
    },
});

const fetchJson = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({Accept: 'application/json'});
    }
    // add your own headers here
    options.headers.set('Authorization', 'Bearer ' + keycloak.token);
    return fetchUtils.fetchJson(url, options);
};

const dataProvider = simpleRestProvider(window._env_.API_URL, fetchJson);

export default withUserProfileHandler(dataProvider);
