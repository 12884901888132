import React from "react";
import {withKeycloak} from "@react-keycloak/web";
import keycloak from "../../../keycloak";
import AssessmentAnswerDataGrid from "../AssessmentAnswerDataGrid/AssessmentAnswerDataGrid";
import AssessmentPoints from "../AssessmentPoints/AssessmentPoints";

class AssessmentQuestionList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            answers: [],
            loading: true
        }
    }

    componentDidMount() {
        fetch(window._env_.API_URL + "/" + this.props.resource + "/" + this.props.record.id + "/assessmentAnswer",
            {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + keycloak.token
                }
            })
            .then(response => response.json())
            .then(answers => this.setState({answers: answers, loading: false}));
    }

    render() {
        return (
            <div>
                <h2>Punktzahl</h2>
                <AssessmentPoints answers={this.state.answers} style={{marginBottom: 20}}/>
                <div style={{marginBottom: 30}}/>
                <h2>Antworten</h2>
                <AssessmentAnswerDataGrid data={this.state.answers} loading={this.state.loading}/>
            </div>
        );
    }


}
export default withKeycloak(AssessmentQuestionList);
