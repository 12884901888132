import React from "react";

export default function DataFormatter({ title, Field }) {
    return(
        <div>
            <div className={"row"}>
                <div className={"col-4"}>
                    <p>
                        {title}
                    </p>
                </div>
                <div className={"col-6"}>
                    {Field}
                </div>
            </div>
        </div>
    )
}
