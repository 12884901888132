import React, { Component, Fragment } from 'react';
import { fade } from '@material-ui/core/styles/colorManipulator';
import ActionDelete from '@material-ui/icons/Delete';
import classnames from 'classnames';
import IconCancel from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import {BulkDeleteButton, Button, DeleteButton} from 'react-admin';

export default class DeleteButtonWithConfirmation extends Component {
    constructor() {
        super();
        this.basePath = "";
        this.record= null;
    }
    state = {
        showDialog: false
    }

    handleClick = () => {
        this.setState({ showDialog: true });
    };

    handleCloseClick = () => {
        this.setState({ showDialog: false });
    };

    render() {
        const { showDialog } = this.state;
        const { label = 'ra.action.delete', classes = {}, className } = this.props;
        return (
            <Fragment>
                <Button onClick={this.handleClick} label={label} className={classnames('ra-delete-button', classes.deleteButton, className)} key="button">
                    <ActionDelete />
                </Button>
                <Dialog fullWidth open={showDialog} onClose={this.handleCloseClick} aria-label="Sind sie sich sicher??">
                    <DialogTitle>Sind Sie sich sicher das Sie dieses Element löschen wollen?</DialogTitle>
                    <DialogContent>
                        <div>
                            Diese Veränderung wird unwiderrufbar sein.
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <DeleteButton basePath={this.props.basePath} label={label} record={this.props.record} />
                        <Button label="Abbrechen" onClick={this.handleCloseClick}>
                            <IconCancel />
                        </Button>
                    </DialogActions>
                </Dialog>
            </Fragment>
        );
    }
}

export class BulkDeleteButtonWithConfirmation extends Component {
    constructor() {
        super();
    }
    state = {
        showDialog: false
    }

    handleClick = () => {
        this.setState({ showDialog: true });
    };

    handleCloseClick = () => {
        this.setState({ showDialog: false });
    };

    render() {
        const { showDialog } = this.state;
        const { label = 'ra.action.delete', classes = {}, className } = this.props;
        return (
            <Fragment>
                <Button onClick={this.handleClick} label={label} className={classnames('ra-delete-button', classes.deleteButton, className)} key="button">
                    <ActionDelete />
                </Button>
                <Dialog fullWidth open={showDialog} onClose={this.handleCloseClick} aria-label="Sind sie sich sicher??">
                    <DialogTitle>Sind Sie sich sicher das Sie dieses Element löschen wollen?</DialogTitle>
                    <DialogContent>
                        <div>
                            Diese Veränderung wird unwiderrufbar sein.
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <BulkDeleteButton label={label} {...this.props}/>
                        <Button label="Abbrechen" onClick={this.handleCloseClick}>
                            <IconCancel />
                        </Button>
                    </DialogActions>
                </Dialog>
            </Fragment>
        );
    }
}