import * as React from "react";
import {
    List,
    Filter,
    Show,
    Create,
    Edit,
    SimpleForm,
    Datagrid,
    TextField,
    BooleanField,
    ReferenceField,
    SelectField,
    TextInput,
    ReferenceInput,
    SelectInput,
    BooleanInput,
    ArrayInput,
    SimpleFormIterator,
    SelectArrayInput,
    ArrayField,
    SimpleShowLayout,
    required,
    EditButton, DeleteButton
}
    from 'react-admin';
import LocalizedTextInput from "./LocalizedTextInput/LocalizedTextInput";
import LocalizedTextField from "./LocalizedTextField/LocalizedTextField";
import {addLocalizationToChoices, addLocalizationToQuestion} from "../util/localizationTransformer";
import DataFormatter from "./DataFormatter";
import Grid from "@material-ui/core/Grid";
import OptionsButton from "./OptionsButton";
import {BulkDeleteButtonWithConfirmation} from "./DeleteButtonWithConfirmation";

const ClientDeclarationQuestionFilter = (props) => (
    <Filter {...props}>
        <SelectInput label="Status" source="status" choices={[
            {id: 'disabled', name: "Deaktiviert"},
            {id: 'enabled', name: "Aktiviert"},
            {id: 'enabled-printable', name: "Aktiviert und druckbar"},
        ]}/>
    </Filter>
);

export const ClientDeclarationQuestionList = props => (
    <List {...props} title="Kunde Deklarationsfragen" filters={<ClientDeclarationQuestionFilter/>}
          filterDefaultValues={{enabled: true}} bulkActionButtons={<BulkDeleteButtonWithConfirmation {...props}/>}>
        <Datagrid>
            <ReferenceField label="Punktekategorie" source="scoreCategory.id" reference="scoreCategory" sortable={false}>
                <TextField source="description[0].text" />
            </ReferenceField>
            <SelectField label="Fragentyp" source="answerType" sortable={false} choices={[
                { id: 'text', name: 'Text' },
                { id: 'single-choice', name: 'Single Choice' },
                { id: 'multiple-choice', name: 'Multiple Choice' },
                { id: 'checkbox', name: 'Checkbox' },
                { id: 'number', name: 'Nummer' },
                { id: 'priority-choice', name: 'Priorität' },
            ]}/>
            <TextField label="Frage" source="question[0].text" sortable={false}/>
            <SelectField label="Status" source="status" choices={[
                {id: 'disabled', name: "Deaktiviert"},
                {id: 'enabled', name: "Aktiviert"},
                {id: 'enabled-printable', name: "Aktiviert und druckbar"},
            ]}/>
            <OptionsButton basePath="/clientDeclarationQuestion" label=" " record={props.userInfo}/>
        </Datagrid>
    </List>
);

export const ClientDeclarationQuestionShow = props => (
    <Show title={<Title/>} {...props}>
        <SimpleShowLayout>
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <DataFormatter title={"Id"} Field={<TextField disabled source="id" />}/>
                    <DataFormatter title={"Status"} Field={<SelectField label="Status" source="status" choices={[
                        {id: 'disabled', name: "Deaktiviert"},
                        {id: 'enabled', name: "Aktiviert"},
                        {id: 'enabled-printable', name: "Aktiviert und druckbar"},
                    ]}/>}/>
                    <DataFormatter title={"Punktekategorie"} Field={<ReferenceField source="scoreCategory.id" reference="scoreCategory">
                        <TextField source="description[0].text" />
                    </ReferenceField>}/>
                </Grid>
                </Grid>
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <DataFormatter title={"Frage"} Field={<TextField source="question[0].text" />}/>
                    <DataFormatter title={"Fragentyp"} Field={<SelectField source="answerType" choices={[
                        { id: 'text', name: 'Text' },
                        { id: 'single-choice', name: 'Single Choice' },
                        { id: 'multiple-choice', name: 'Multiple Choice' },
                        { id: 'checkbox', name: 'Checkbox' },
                        { id: 'number', name: 'Nummer' },
                        { id: 'priority-choice', name: 'Priorität' },
                    ]} />}/>
                    <DataFormatter title={"Algorithmustyp"}Field={<TextField source="algorithmType" />}/>
                    <DataFormatter title={"Matchingtyp"}Field={<TextField source="matchingType" />}/>
                    <DataFormatter title={"Antwortmöglichkeiten"} Field={<ArrayField label="Antwortmöglichkeiten" source="choices">
                        <Datagrid>
                            <LocalizedTextField label="" source="text" />
                            <TextField source="scoreValue"/>
                        </Datagrid>
                    </ArrayField>}/>
                </Grid>
            </Grid>
        </SimpleShowLayout>
    </Show>
);

export const ClientDeclarationQuestionCreate = props => (
    <Create title="Erstelle Deklarationsfrage" {...props} transform={transform}>
        <SimpleForm>
            <SelectInput label="Status" source="status" choices={[
                {id: 'disabled', name: "Deaktiviert"},
                {id: 'enabled', name: "Aktiviert"},
                {id: 'enabled-printable', name: "Aktiviert und druckbar"},
            ]}/>
            <ReferenceInput label="Punktekategorie" source="scoreCategory.id" reference="scoreCategory" validate={[required()]}>
                <SelectInput optionText="description[0].text" />
            </ReferenceInput>
            <LocalizedTextInput label="Frage" multiline fullWidth source="question" validate={[required()]}/>
            <SelectInput label="Fragentyp" source="answerType" validate={[required()]} choices={[
                { id: 'text', name: 'Text' },
                { id: 'single-choice', name: 'Single Choice' },
                { id: 'multiple-choice', name: 'Multiple Choice' },
                { id: 'checkbox', name: 'Checkbox' },
                { id: 'number', name: 'Nummer' },
                { id: 'priority-choice', name: 'Priorität' },
            ]} />
            <SelectInput label="Algorithmustyp" source="algorithmType" multiline choices={[
                { id: 'constraint', name: 'Constraint' },
                { id: 'matching', name: 'Matching' } ]}/>
            <SelectInput label="Matchingtyp" source="matchingType" multiline choices={[
                { id: 'language', name: 'Sprache' },
                { id: 'time-planning', name: 'Zeitplannung' },
                { id: 'location', name: 'Ortschaft' },
                { id: 'driving-license', name: 'Führerschein' },
                { id: 'employment-model', name: 'Anstellungsmodel' },
                { id: 'workload-planning', name: 'Arbeitsplanung' }]}/>
            <ArrayInput label="Antwortmöglichkeiten" source="choices">
                <SimpleFormIterator>
                    <LocalizedTextInput label="" multiline fullWidth source="text" validate={[required()]}/>
                    <TextInput label="" multiline fullWidth source="scoreValue"/>
                </SimpleFormIterator>
            </ArrayInput>
        </SimpleForm>
    </Create>
);

export const ClientDeclarationQuestionEdit = props => (
    <Edit title={<Title/>} {...props} transform={transform}>
        <SimpleForm>
            <TextInput disabled source="id" />
            <SelectInput label="Status" source="status" choices={[
                {id: 'disabled', name: "Deaktiviert"},
                {id: 'enabled', name: "Aktiviert"},
                {id: 'enabled-printable', name: "Aktiviert und druckbar"},
            ]}/>
            <ReferenceInput label="Punktekategorie" source="scoreCategory.id" reference="scoreCategory" validate={[required()]}>
                <SelectInput optionText="description[0].text" />
            </ReferenceInput>
            <LocalizedTextInput label="Frage" multiline fullWidth source="question" validate={[required()]}/>
            <SelectInput label="Fragentyp" source="answerType" validate={[required()]} choices={[
                { id: 'text', name: 'Text' },
                { id: 'single-choice', name: 'Single Choice' },
                { id: 'multiple-choice', name: 'Multiple Choice' },
                { id: 'checkbox', name: 'Checkbox' },
                { id: 'number', name: 'Nummer' },
                { id: 'priority-choice', name: 'Priorität' },
            ]} />
            <SelectInput label="Algorithmustyp" source="algorithmType" multiline choices={[
                { id: 'constraint', name: 'Constraint' },
                { id: 'matching', name: 'Matching' } ]}/>
            <SelectInput label="Matchingtyp" source="matchingType" multiline choices={[
                { id: 'language', name: 'Sprache' },
                { id: 'time-planning', name: 'Zeitplannung' },
                { id: 'location', name: 'Ortschaft' },
                { id: 'driving-license', name: 'Führerschein' },
                { id: 'employment-model', name: 'Anstellungsmodel' },
                { id: 'workload-planning', name: 'Arbeitsplanung' }]}/>
            <ArrayInput fieldKey="id" label="Antwortmöglichkeiten" source="choices">
                <SimpleFormIterator>
                    <LocalizedTextInput label="" multiline fullWidth source="text" validate={[required()]}/>
                    <TextInput label="" multiline fullWidth source="scoreValue"/>
                </SimpleFormIterator>
            </ArrayInput>
        </SimpleForm>
    </Edit>
);

const Title = ({ record }) => {
    return (
        <span>{record ? `Kunde Deklarationsfragen - ${record.question[0].text}` : ''}</span>
    )
};

let transform = data => ({
    ...data,
    scoreCategory: data.scoreCategory.id,
    question: addLocalizationToQuestion(data.question),
    choices: addLocalizationToChoices(data.choices)
});
