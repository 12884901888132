//TODO: Fix workaround when multiple languages are added
export const addLocalizationToChoices = (choices) => {
    let newChoices = [];
    for(let choice of choices) {
        choice.text[0].localization = "local-de";
        newChoices.push(choice);
    }
    return newChoices;
};

export const addLocalizationToQuestion = (question) => {
    question[0].localization = "local-de";
    return question;
};
