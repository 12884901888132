import React from "react";
import {Form} from "react-bootstrap";

class RadioButtonField extends React.Component {

    renderOptions() {
        let renderedOptions = [];
        let choiceId = this.matchStringToId(this.props.value);
        for(let choice of this.props.question.choices) {
            renderedOptions.push(
                <div onClick={() => this.props.setAnswer(this.props.question.id, choice.text[0].text, "single-choice")}
                     style={{marginBottom: 5, padding: '0px 5px', background: '#fafafa'}}>
                    <Form.Check id={choice.id}
                                name={this.props.question.id}
                                type="radio"
                                checked={choice.id === choiceId}
                                label={choice.text[0].text}
                                isInvalid={this.props.invalid}
                                required={this.props.required}
                    />
                </div>
            )
        }
        return renderedOptions;
    }

    matchStringToId(string) {
        for(let choice of this.props.question.choices) {
            if(choice.text[0].text === this.props.value) {
                return choice.id;
            }
        }
    }

    render() {
        return (
            <Form.Group controlId={this.props.question.id} ref={this.props.innerRef}>
                <Form.Label style={{fontWeight: 'bold'}}>{this.props.question.question[0].text}{this.renderRequired()}</Form.Label>
                {this.renderOptions()}
            </Form.Group>
        )
    }

    renderRequired() {
        if(this.props.required) return '*';
        return '';
    }
}

export default React.forwardRef((props, ref) => <RadioButtonField
    innerRef={ref} {...props}
/>);
