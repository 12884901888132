import * as React from "react";
import {
    ShowController, ShowView, Show, TabbedShowLayout, Tab, SimpleShowLayout, List, Edit, SimpleForm,
    Datagrid, TextField, EmailField, FunctionField, DateField, SelectField, ReferenceField,
    TextInput, DateTimeInput, SelectInput, ReferenceInput, Filter, required, BooleanField, EditButton, DeleteButton
}
    from 'react-admin';
import {formatDateDDMMYYYY, formatDateTimeHHMM, dateTimeOptions, dateOptions} from "../util/formatDateTime";
import Grid from "@material-ui/core/Grid";
import DataFormatter from "./DataFormatter";
import OptionsButton from "./OptionsButton";
import {BulkDeleteButtonWithConfirmation} from "./DeleteButtonWithConfirmation";

const MeetingFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
    </Filter>
);

export const MeetingList = props => (
    <List {...props} filters={<MeetingFilter/>} title={"Meetings"} sort={{ field: 'date', order: 'ASC' }} bulkActionButtons={<BulkDeleteButtonWithConfirmation {...props}/>}>
        <Datagrid>
            <DateField showTime label="Datum" source="date" options={dateTimeOptions}/>
            <SelectField label="Status" source="status" choices={[
                { id: 'open', name: 'Offen' },
                { id: 'accepted', name: 'Angenommen' },
                { id: 'done', name: 'Abgeschlossen' },
                { id: 'declined', name: 'Abgelehnt' },
            ]} />
            <ReferenceField label="Zugewiesen" source="assignedAdmin.id" reference="admin" link="show">
                <FunctionField render={record => record.userInfo.lastName + ' ' +
                    record.userInfo.firstName + ' (' + record.userInfo.email + ')'} />
            </ReferenceField>
            <TextField label="Vorname" source="userInfo.firstName"/>
            <TextField label="Nachname" source="userInfo.lastName"/>
            <SelectField label="Rolle" source="userInfo.userType" choices={[
                {id: 'caregiver', name: 'Betreuungsperson'},
                {id: 'client', name: 'Kunde'},
                {id: 'client_observer', name: 'Kontaktperson'},
            ]}/>
            <TextField label="Stadt" source="userInfo.city"/>
            <TextField label="Land" source="userInfo.country"/>
            <DateField label="Erstellungsdatum" source="creationDate" options={dateOptions}/>
            <OptionsButton basePath="/meeting" label=" " record={props.userInfo}/>
        </Datagrid>
    </List>
);


export const MeetingShow = (props) => (
            <Show {...props}>
                <TabbedShowLayout>
                    <Tab label="Allgemein">
						<DataFormatter title={"Id"} Field={<TextField source="id"/>}/>
	                    <DataFormatter title={"Datum"} Field={<DateField showTime source="date" options={dateTimeOptions}/>}/>
	                    <DataFormatter title={"Status"} Field={<SelectField source="status" choices={[
	                        { id: 'open', name: 'Offen' },
	                        { id: 'accepted', name: 'Angenommen' },
	                        { id: 'done', name: 'Abgeschlossen' },
	                        { id: 'declined', name: 'Abgelehnt' },
	                    ]} />}/>
	                    <DataFormatter title={"Zugewiesen"} Field={<ReferenceField source="assignedAdmin.id" reference="admin" link="show">
	                        <FunctionField render={record => record.userInfo.lastName + ' ' +
	                            record.userInfo.firstName + ' (' + record.userInfo.email + ')'} />
	                    </ReferenceField>
	                    }/>
	                    <DataFormatter title={"Erstellungsdatum"} Field={<DateField showTime source="creationDate" options={dateTimeOptions}/>}/>
                    </Tab>
                    <Tab label="Kontaktperson">
                        <DataFormatter title={"Anrede"} Field={<SelectField source="userInfo.gender" choices={[
                            {id: 'male', name: 'Herr'},
                            {id: 'female', name: 'Frau'},
                            {id: 'other', name: 'Andere'},
                        ]}/>}/>
                        <DataFormatter title={"Vorname"} Field={<TextField source="userInfo.firstName"/>}/>
                    	<DataFormatter title={"Nachname"} Field={<TextField source="userInfo.lastName"/>}/>
                    	<DataFormatter title={"Email"} Field={<EmailField source="userInfo.email"/>}/>
                    	<DataFormatter title={"Adresse"} Field={<TextField source="userInfo.streetAndNr"/>}/>
                    	<DataFormatter title={"Stadt"} Field={<TextField source="userInfo.city"/>}/>
                    	<DataFormatter title={"Land"} Field={<TextField source="userInfo.country"/>}/>
                    	<DataFormatter title={"Telefon"} Field={<TextField source="userInfo.phoneNumber"/>}/>
                    	<DataFormatter title={"Telefon Mobil"} Field={<TextField source="userInfo.mobileNumber"/>}/>
                    </Tab>
                    <Tab label="Betroffene Person">
                        <DataFormatter title={"Anrede"} Field={<SelectField source="userInfoFor.gender" choices={[
                            {id: 'male', name: 'Herr'},
                            {id: 'female', name: 'Frau'},
                            {id: 'other', name: 'Andere'},
                        ]}/>}/>
                        <DataFormatter title={"Vorname"} Field={<TextField source="userInfoFor.firstName"/>}/>
                    	<DataFormatter title={"Nachname"} Field={<TextField source="userInfoFor.lastName"/>}/>
                    	<DataFormatter title={"Email"} Field={<EmailField source="userInfoFor.email"/>}/>
                    	<DataFormatter title={"Adresse"} Field={<TextField source="userInfoFor.streetAndNr"/>}/>
                    	<DataFormatter title={"Stadt"} Field={<TextField source="userInfoFor.city"/>}/>
                    	<DataFormatter title={"Land"} Field={<TextField source="userInfoFor.country"/>}/>
                    	<DataFormatter title={"Telefon"} Field={<TextField source="userInfoFor.phoneNumber"/>}/>
                    	<DataFormatter title={"Telefon Mobil"} Field={<TextField source="userInfoFor.mobileNumber"/>}/>
                    </Tab>
                </TabbedShowLayout>
            </Show>
);

export const MeetingEdit = props => (
    <Edit title={<Title/>} {...props} transform={transform}>
        <SimpleForm>
            <TextField source="id" />
            <DateTimeInput label="Datum" source="date" validate={[required()]}/>
            <SelectInput label="Status" source="status" validate={[required()]} choices={[
                { id: 'open', name: 'Offen' },
                { id: 'accepted', name: 'Angenommen' },
                { id: 'done', name: 'Abgeschlossen'},
                { id: 'declined', name: 'Abgelehnt' },
            ]} />
            <ReferenceInput label="Zugewiesen" source="assignedAdmin.id" reference="admin" resettable>
                <SelectInput optionText={record => record.userInfo.lastName + ' ' +
                    record.userInfo.firstName + ' (' + record.userInfo.email + ')'} />
            </ReferenceInput>
            <TextField label="Vorname" source="userInfo.firstName"/>
            <TextField label="Nachname" source="userInfo.lastName"/>
            <TextField label="Adresse" source="userInfo.streetAndNr"/>
            <TextField label="Stadt" source="userInfo.city"/>
            <TextField label="Land" source="userInfo.country"/>
            <TextField label="Telefon" source="userInfo.phoneNumber"/>
            <TextField label="Telefon Mobil" source="userInfo.mobileNumber"/>
        </SimpleForm>
    </Edit>
);

const Title = ({ record }) => {
    return (
        <span>{record ? `Meetings - ${formatDateDDMMYYYY(record.date) + ", " + formatDateTimeHHMM(record.date) + ", " + 
        record.userInfo.firstName + " " + record.userInfo.lastName}` : ''}</span>
    )
};

let transform = data => ({
    ...data,
    userInfo: data.userInfo.id,
    userInfoFor: data.userInfoFor ? data.userInfoFor.id : null,
    assignedAdmin: data.assignedAdmin ? data.assignedAdmin.id : null
});
