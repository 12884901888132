import * as React from "react";
import {
    Show,
    SimpleShowLayout,
    List,
    Create,
    Edit,
    SimpleForm,
    Datagrid,
    TextField,
    TextInput,
    required,
    EditButton, DeleteButton
} from 'react-admin';
import LocalizedTextInput from "./LocalizedTextInput/LocalizedTextInput";
import LocalizedTextField from "./LocalizedTextField/LocalizedTextField";
import Grid from "@material-ui/core/Grid";
import DataFormatter from "./DataFormatter";
import OptionsButton from "./OptionsButton";
import {BulkDeleteButtonWithConfirmation} from "./DeleteButtonWithConfirmation";

export const ScoreSuperCategoryList = props => (
    <List {...props} title={"Oberpunktekategorien"} bulkActionButtons={<BulkDeleteButtonWithConfirmation {...props}/>}>
        <Datagrid>
            <TextField label="Beschreibung" source="description[0].text" />
            <OptionsButton basePath="/scoreSuperCategory" label=" " record={props}/>
        </Datagrid>
    </List>
);

export const ScoreSuperCategoryShow = props => (
    <Show title={<Title/>} {...props}>
        <SimpleShowLayout>
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <DataFormatter title={"Id"} Field={<TextField source="id" />} />
                    <DataFormatter title={"Beschreibung"} Field={<TextField  source="description[0].text" />} />
                </Grid>
            </Grid>
        </SimpleShowLayout>
    </Show>
);

export const ScoreSuperCategoryCreate = props => (
    <Create title="Erstelle Oberpunktekategorie" {...props}>
        <SimpleForm>
            <LocalizedTextInput label="Beschreibung" source="description" validate={[required()]}/>
        </SimpleForm>
    </Create>
);

export const ScoreSuperCategoryEdit = props => (
    <Edit title={<Title/>} {...props}>
        <SimpleForm>
            <TextInput disabled source="id" />
            <LocalizedTextInput label="Beschreibung" source="description" validate={[required()]}/>
        </SimpleForm>
    </Edit>
);

const Title = ({ record }) => {
    return (
        <span>{record ? `Oberpunktekategorien - ${record.description[0].text}` : ''}</span>
    )
};
