import React, {forwardRef} from 'react';
import {AppBar, Layout, MenuItemLink, UserMenu} from 'react-admin';
import Icon from '@material-ui/icons/Person';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import InboxIcon from '@material-ui/icons/Inbox';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import GroupIcon from '@material-ui/icons/Group';
import StarIcon from '@material-ui/icons/Star';
import EditIcon from '@material-ui/icons/Edit';
import {Accordion} from "react-bootstrap";
import MUITheme from "./MUITheme";
import authProvider from "../providers/AuthProvider";

const AdminLayout = props => <Layout {...props} sidebar={SideBar} appBar={C2YAppBar}/>;

const CategoryHeader = {
    background: "#ffbf00",
    border: "rgb(0,0,0,0)",
    marginLeft: "-10px",
    width: "370px"
};

class SideBar extends React.Component {
    state = {
        permissions: [],
    };
    _asyncRequest;
    async componentDidMount() {
        this._asyncRequest = authProvider.getPermissions().then(
            permissions => {
                this._asyncRequest = null;
                this.setState({permissions})
            }
        );
    }
    componentWillUnmount() {
        if (this._asyncRequest) {
            this._asyncRequest.cancel();
        }
    }
    render() {
        console.log(this.state.permissions)
        return (
            <div theme={MUITheme}>
                <br/>
                <Accordion>
                    <div eventKey="1">
                        <Accordion.Toggle as={"div"} variant="link" eventKey="0">
                            <MenuItemLink style={CategoryHeader}
                                          to="#"
                                          primaryText="Nutzer"
                                          leftIcon={<Icon/>}
                            />
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0" as={"Card.Body"}>
                            <div>
                                <MenuItemLink
                                    to="/caregiver"
                                    primaryText="Betreuungspersonen"
                                    leftIcon={<Icon/>}
                                />
                                {
                                    this.state.permissions.includes("admin") ?
                                        <MenuItemLink
                                            to="/client"
                                            primaryText="Kunde"
                                            leftIcon={<Icon/>}
                                        /> : null
                                }
                                {
                                    this.state.permissions.includes("admin") ?
                                        <MenuItemLink
                                            to="/Meeting"
                                            primaryText="Meetings"
                                            leftIcon={<Icon/>}
                                        /> : null
                                }
                                {
                                    this.state.permissions.includes("admin") ?
                                        <MenuItemLink
                                            to="/partnerCompany"
                                            primaryText="Partnerfirmen"
                                            leftIcon={<Icon/>}
                                        /> : null
                                }
                            </div>
                        </Accordion.Collapse>
                    </div>
                </Accordion>
                {
                    this.state.permissions.includes("admin") ?
                        <Accordion>
                            <div eventKey="1">
                                <Accordion.Toggle as={"div"} variant="link" eventKey="0"
                                                  class="${classes.MenuHeader}">
                                    <MenuItemLink style={CategoryHeader}
                                                  to="#"
                                                  primaryText="Fragen"
                                                  leftIcon={<LiveHelpIcon/>}
                                    />
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="0" as={"Card.Body"}>
                                    <div>
                                        <MenuItemLink
                                            to="/clientDeclarationQuestion"
                                            primaryText="Kunde Deklarationsfragen"
                                            leftIcon={<LiveHelpIcon/>}
                                        />
                                        <MenuItemLink
                                            to="/caregiverDeclarationQuestion"
                                            primaryText="Betreuungsperson Deklarationsfragen"
                                            leftIcon={<LiveHelpIcon/>}
                                        />
                                        <MenuItemLink
                                            to="/caregiverAssessmentQuestion"
                                            primaryText="Betreuungsperson Prüfungsfragen"
                                            leftIcon={<LiveHelpIcon/>}
                                        />
                                    </div>
                                </Accordion.Collapse>
                            </div>
                        </Accordion> : null
                }
                {
                    this.state.permissions.includes("admin") ?
                        <Accordion>
                            <div eventKey="1">
                                <Accordion.Toggle as={"div"} variant="link" eventKey="0" class="${classes.MenuHeader}">
                                    <MenuItemLink style={CategoryHeader}
                                                  to="#"
                                                  primaryText="Kategorien"
                                                  leftIcon={<AllInboxIcon/>}
                                    />
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="0" as={"Card.Body"}>
                                    <div>
                                        <MenuItemLink
                                            to="/scoreCategory"
                                            primaryText="Punktekategorien"
                                            leftIcon={<InboxIcon/>}
                                        />
                                        <MenuItemLink
                                            to="/scoreSuperCategory"
                                            primaryText="Oberpunktekategorien"
                                            leftIcon={<AllInboxIcon/>}
                                        />
                                    </div>
                                </Accordion.Collapse>
                            </div>
                        </Accordion> : null }
                {
                    this.state.permissions.includes("admin") ?
                        <div>
                        <Accordion>
                            <div eventKey="1">
                                <Accordion.Toggle as={"div"} variant="link" eventKey="0" class="${classes.MenuHeader}">
                                    <MenuItemLink style={CategoryHeader}
                                                  to="#"
                                                  primaryText="Sonstiges"
                                                  leftIcon={<StarIcon/>}
                                    />
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="0" as={"Card.Body"}>
                                    <div>
                                        <MenuItemLink
                                            to="/qualification"
                                            primaryText="Qualifikationen"
                                            leftIcon={<StarIcon/>}
                                        />
                                        <MenuItemLink
                                            to="/admin"
                                            primaryText="IAHA Mitarbeiter"
                                            leftIcon={<GroupIcon/>}
                                        />
                                    </div>
                                </Accordion.Collapse>
                            </div>
                        </Accordion>
                        </div> : null
                }
            </div>
        )
    }
}

const C2YAppBar = props => <AppBar {...props} userMenu={<C2YUserMenu/>}/>;

const C2YUserMenu = props => (
    <UserMenu {...props}>
        <EditProfileMenu/>
    </UserMenu>
);

const EditProfileMenu = forwardRef(({onClick}, ref) => (
    <MenuItemLink ref={ref}
                  to="/profile/68cfd4dd-3520-44fc-af11-c84b49c1760e"
                  primaryText="E-Mail Adresse ändern"
                  onClick={onClick}
                  leftIcon={<EditIcon/>}/>
));

export default AdminLayout;