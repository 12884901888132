/* This API Service class is necessary for nested resources in react admin. In the future, those are optimally
 * implemented in a custom data provider.
*/
import QuestionChoice from "../model/QuestionChoice";
import Local from "../model/Local";
import ScoreSuperCategory from "../model/ScoreSuperCategory";
import ScoreCategory from "../model/ScoreCategory";
import ClientDeclarationQuestion from "../model/ClientDeclarationQuestion";
import Matching from "../model/Matching";
import MatchingParameters from "../model/MatchingParameters";
import Availability from "../model/Availability";
import CaregiverClientRelation from "../model/CaregiverClientRelation";
import Caregiver from "../model/Caregiver";
import Client from "../model/Client";
import UserInfo from "../model/UserInfo";
import Revision from "../model/Revision";
import UserInfoChild from "../model/UserInfoChild";
import UserInfoDetail from "../model/UserInfoDetail";
import CaregiverDeclarationQuestion from "../model/CaregiverDeclarationQuestion";

class ApiService {

    api = window._env_.API_URL;

    addClientFiles = (id, files, keycloak) => {
        return this.postRequestFormDataArray(this.api + "/client/" + id + "/document", files, keycloak);
    };

    addCaregiverFiles = (id, files, keycloak) => {
        return this.postRequestFormDataArray(this.api + "/caregiver/" + id + "/document", files, keycloak);
    };

    addCaregiverPhoto = (id, photo, keycloak) => {
        return this.postRequestFormData(this.api + "/caregiver/" + id + "/photo", photo, keycloak)
    };

    updateCaregiverPhoto = (id, photoId, photo, keycloak) => {
        return this.putRequestFormData(`${this.api}/caregiver/${id}/photo/${photoId}`, photo, keycloak)
    };

    getCaregiverPhotoInfo = (id, keycloak) => {
        return this.getRequest(this.api + "/caregiver/" + id + "/photo", keycloak)
    };

    getCaregiverPhoto = (caregiverId, id, keycloak) => {
        return this.getRequestBlob(this.api + "/caregiver/" + caregiverId + "/photo/" + id, keycloak)
    };

    getCaregiverNotes = (caregiverId, keycloak) => {
        return this.getRequest(this.api + "/caregiver/" + caregiverId + "/note", keycloak)
    };

    addCaregiverNote = (caregiverId, note, keycloak) => {
        return this.postRequest(this.api + "/caregiver/" + caregiverId + "/note", note, keycloak)
    };

    updateCaregiverNote = (caregiverId, noteId, note, keycloak) => {
        return this.putRequest(this.api + "/caregiver/" + caregiverId + "/note/" + noteId, note, keycloak)
    };

    deleteCaregiverNote = (caregiverId, noteId, keycloak) => {
        return this.deleteRequest(this.api + "/caregiver/" + caregiverId + "/note/" + noteId, keycloak)
    };

    getClientObserversForClient = (id, keycloak) => {
        return this.getRequest(this.api + '/client/' + id + '/clientObserver', keycloak);
    };

    getClientPersonalSheet = (id, keycloak) => {
        return fetch(this.api + "/caregiver/" + id + "/personalsheet", {
            method: 'GET',
            headers: this.defaultHeaders(keycloak)
        })
    }

    getClientDeclarationQuestions = (keycloak) => {
        return this.getRequest(this.api + '/clientDeclarationQuestion', keycloak).then(clientDeclarationQuestionsJson => {
            console.log(clientDeclarationQuestionsJson)
            let caregiverDeclarationQuestions = [];
            for (let clientDeclarationQuestionJson of clientDeclarationQuestionsJson) {
                caregiverDeclarationQuestions.push(this.jsonToClientDeclarationQuestion(clientDeclarationQuestionJson))
            }
            return caregiverDeclarationQuestions;
        });
    };

    getCaregiverDeclarationQuestions = (keycloak) => {
        return this.getRequest(this.api + '/caregiverDeclarationQuestion', keycloak).then(caregiverDeclarationQuestionsJson => {
            let caregiverDeclarationQuestions = [];
            console.log(caregiverDeclarationQuestionsJson)
            for (let caregiverDeclarationQuestionJson of caregiverDeclarationQuestionsJson) {
                caregiverDeclarationQuestions.push(this.jsonToCaregiverDeclarationQuestion(caregiverDeclarationQuestionJson))
            }
            return caregiverDeclarationQuestions;
        });
    };

    getUserEmailByKeycloakId = (keycloakId, keycloak) => {
        const url = `${this.api}/user/${keycloakId}/email`;

        return this.getRequestRaw(url, keycloak).then(response => response.text());
    };

    /**
     * @param {number} userInfoId
     * @param {Keycloak.KeycloakInstance} keycloak
     * @returns {Promise<UserInfo[]>}
     */
    getUserInfoRevisions = (userInfoId, keycloak) => {
        const url = `${this.api}/user/info/${userInfoId}/revisions`;

        return this.getRequest(url, keycloak).then(revisions => revisions.map(this.jsonToUserInfo));
    };

    /**
     * @param {string} userInfoDetailId
     * @param {Keycloak.KeycloakInstance} keycloak
     * @returns {Promise<UserInfoDetail[]>}
     */
    getUserInfoDetailRevisions = (userInfoDetailId, keycloak) => {
        const url = `${this.api}/user/detail/${userInfoDetailId}/revisions`;

        return this.getRequest(url, keycloak).then(revisions => revisions.map(this.jsonToUserInfoDetail));
    };

    /**
     * @param {string} userInfoDetailId
     * @param {Keycloak.KeycloakInstance} keycloak
     * @returns {Promise<UserInfoChild[]>}
     */
    getUserInfoChildRevisions = (userInfoDetailId, keycloak) => {
        const url = `${this.api}/user/child/${userInfoDetailId}/revisions`;

        return this.getRequest(url, keycloak).then(revisions => revisions.map(this.jsonToUserInfoChild));
    };

    /**
     * @param {number} id
     * @param {Keycloak.KeycloakInstance} keycloak
     * @returns {Promise<Matching>}
     */
    getActiveMatching = (id, keycloak) => {
        return this.getRequest(`${this.api}/client/${id}/matching`, keycloak).then(this.jsonToMatching);
    };

    /**
     * @param {Email} email
     * @param {Keycloak.KeycloakInstance} keycloak
     * @returns {Promise<Response>}
     */
    initEmailChange = (email, keycloak) => {
        return this.putRequest(`${this.api}/user/email`, email, keycloak, false);
    }

    /**
     * @param {string} token
     * @param {Keycloak.KeycloakInstance} keycloak
     * @returns {Promise<Response>}
     */
    sendEmailChangeToken = (token, keycloak) => {
        return this.getRequestRaw(`${this.api}/user/email/${token}`, keycloak);
    }

    /**
     * @param {number} id
     * @param {MatchingParameters} matchingParameters
     * @param {Keycloak.KeycloakInstance} keycloak
     * @returns {Promise<Matching>}
     */
    triggerMatching = (id, matchingParameters, keycloak) => {
        return this.postRequest(`${this.api}/client/${id}/matching`, matchingParameters, keycloak).then(this.jsonToMatching);
    };

    /**
     * @param {{}} matchingJson
     * @returns {Matching}
     */
    jsonToMatching = (matchingJson) => {
        const matchingParameters = matchingJson.matchingParameters ? this.jsonToMatchingParameters(matchingJson.matchingParameters) : new MatchingParameters();
        const matchingCreated = matchingJson.matchingCreated ? new Date(matchingJson.matchingCreated) : undefined;
        const matchingCompleted = matchingJson.matchingCompleted ? new Date(matchingJson.matchingCompleted) : undefined;
        const caregiverClientRelations = (matchingJson.caregiverClientRelations ?? []).map(this.jsonToCaregiverClientRelation);

        return new Matching(matchingJson.id, matchingParameters, matchingCreated, matchingCompleted, caregiverClientRelations);
    };

    /**
     * @param {{}} relationJson
     * @returns {CaregiverClientRelation}
     */
    jsonToCaregiverClientRelation = (relationJson) => {
        const caregiver = this.jsonToCaregiver(relationJson.caregiver);
        const client = this.jsonToClient(relationJson.client);
        const requestDate = relationJson.requestDate ? new Date(relationJson.requestDate) : null;
        const clientAcceptedDate = relationJson.clientAcceptedDate ? new Date(relationJson.clientAcceptedDate) : null;
        const caregiverAcceptedDate = relationJson.caregiverAcceptedDate ? new Date(relationJson.caregiverAcceptedDate) : null;
        const closedDate = relationJson.closedDate ? new Date(relationJson.closedDate) : null;

        return new CaregiverClientRelation(relationJson.id, caregiver, client, requestDate, clientAcceptedDate, caregiverAcceptedDate, closedDate, relationJson.relationState);
    };

    jsonToCaregiver = (caregiverJson) => {
        const userInfo = this.jsonToUserInfo(caregiverJson.userInfo);

        return new Caregiver(caregiverJson.id, userInfo);
    };

    jsonToClient = (clientJson) => {
        const userInfo = this.jsonToUserInfo(clientJson.userInfo);

        return new Client(clientJson.id, userInfo);
    };

    jsonToUserInfo = (userInfoJson) => {
        return new UserInfo(
            userInfoJson.id,
            userInfoJson.userId,
            userInfoJson.lastName,
            userInfoJson.firstName,
            userInfoJson.email,
            userInfoJson.streetAndNr,
            userInfoJson.zipcode,
            userInfoJson.city,
            userInfoJson.country,
            userInfoJson.gender,
            userInfoJson.birthDate ? new Date(userInfoJson.birthDate) : null,
            userInfoJson.phoneNumber,
            userInfoJson.mobileNumber,
            userInfoJson.nationality,
            userInfoJson.verified,
            userInfoJson.role,
            userInfoJson.relatedEntityId,
            userInfoJson.details ? this.jsonToUserInfoDetail(userInfoJson.details) : null,
            userInfoJson.revision ? this.jsonToRevision(userInfoJson.revision) : null
        );
    };

    jsonToRevision = (revision) => {
        return new Revision(
            revision.id,
            revision.timestamp ? new Date(revision.timestamp) : null,
            revision.issuer,
            revision.operation
        );
    };


    jsonToUserInfoDetail = userInfoDetailJson => {
        const children = (userInfoDetailJson.children ?? []).map(this.jsonToUserInfoChild);
        return new UserInfoDetail(
            userInfoDetailJson.id,
            children,
            userInfoDetailJson.residencePermit,
            userInfoDetailJson.civilStatus,
            userInfoDetailJson.withholdingTax,
            userInfoDetailJson.criminalRecord,
            userInfoDetailJson.otherEmployers,
            userInfoDetailJson.additionalIncomeUnemploymentInsurance,
            userInfoDetailJson.disabilityAndSocialInsuranceDeductions,
            userInfoDetailJson.socialSecurityNumber,
            userInfoDetailJson.bankAccountNumber,
            userInfoDetailJson.employmentStartDate ? new Date(userInfoDetailJson.employmentStartDate) : null,
            userInfoDetailJson.employmentEndDate ? new Date(userInfoDetailJson.employmentEndDate) : null,
            userInfoDetailJson.notice,
            userInfoDetailJson.revision ? this.jsonToRevision(userInfoDetailJson.revision) : null
        );
    }

    jsonToUserInfoChild = userInfoChildJson => new UserInfoChild(
        userInfoChildJson.id,
        userInfoChildJson.firstName,
        userInfoChildJson.lastName,
        userInfoChildJson.birthDate ? new Date(userInfoChildJson.birthDate) : null,
        userInfoChildJson.revision ? this.jsonToRevision(userInfoChildJson.revision) : null
    );

    jsonToMatchingParameters = (matchingParametersJson) => {
        const availability = matchingParametersJson.availability ? this.jsonToAvailability(matchingParametersJson.availability) : new Availability();

        return new MatchingParameters(matchingParametersJson.id, availability, matchingParametersJson.maxDistance);
    };

    jsonToAvailability = (availabilityJson) => {
        const workloads = availabilityJson.workloads ? new Set(availabilityJson.workloads) : undefined;
        const workslots = availabilityJson.workslots ? new Set(availabilityJson.workslots) : undefined;

        return new Availability(availabilityJson.id, workloads, workslots);
    };

    jsonToClientDeclarationQuestion = (clientDeclarationQuestionJson) => {
        let questionChoices = [];
        for (let questionChoiceJson of clientDeclarationQuestionJson.choices) {
            questionChoices.push(this.jsonToQuestionChoice(questionChoiceJson));
        }

        let questionLocals = [];
        for (let questionLocal of clientDeclarationQuestionJson.question) {
            questionLocals.push(this.jsonToLocal(questionLocal))
        }

        return new ClientDeclarationQuestion(
            clientDeclarationQuestionJson.id,
            this.jsonToScoreCategory(clientDeclarationQuestionJson.scoreCategory),
            questionLocals,
            clientDeclarationQuestionJson.answerType,
            questionChoices
        )
    };

    jsonToCaregiverDeclarationQuestion = (caregiverDeclarationQuestionJson) => {
        let questionChoices = [];
        let questionLocals = [];
        console.log(caregiverDeclarationQuestionJson)
        if (caregiverDeclarationQuestionJson != null) {
            for (let questionChoiceJson of caregiverDeclarationQuestionJson.choices) {
                questionChoices.push(this.jsonToQuestionChoice(questionChoiceJson));
            }

            for (let questionLocal of caregiverDeclarationQuestionJson.question) {
                questionLocals.push(this.jsonToLocal(questionLocal))
            }
        }

        return new CaregiverDeclarationQuestion(
            caregiverDeclarationQuestionJson.id,
            this.jsonToScoreCategory(caregiverDeclarationQuestionJson.scoreCategory),
            questionLocals,
            caregiverDeclarationQuestionJson.answerType,
            questionChoices
        )
    };

    jsonToScoreCategory = (scoreCategoryJson) => {
        let descriptions = [];
        for (let description of scoreCategoryJson.description) {
            descriptions.push(this.jsonToLocal(description));
        }
        return new ScoreCategory(scoreCategoryJson.id, descriptions,
            this.jsonToScoreSuperCategory(scoreCategoryJson.superCategory));
    };

    jsonToScoreSuperCategory = (scoreSuperCategoryJson) => {
        let descriptions = [];
        for (let description of scoreSuperCategoryJson.description) {
            descriptions.push(this.jsonToLocal(description));
        }
        return new ScoreSuperCategory(scoreSuperCategoryJson.id, descriptions);
    };

    jsonToQuestionChoice = (questionChoiceJson) => {
        let texts = [];
        for (let text of questionChoiceJson.text) {
            texts.push(this.jsonToLocal(text));
        }
        return new QuestionChoice(questionChoiceJson.id, texts, questionChoiceJson.viewOrder);
    };

    jsonToLocal = (localJson) => {
        return new Local(localJson.id, localJson.text, localJson.localization);
    };

    jsonToScoreCategory = (scoreCategoryJson) => {
        let descriptions = [];
        for (let description of scoreCategoryJson.description) {
            descriptions.push(this.jsonToLocal(description));
        }
        return new ScoreCategory(scoreCategoryJson.id, descriptions,
            this.jsonToScoreSuperCategory(scoreCategoryJson.superCategory));
    };

    jsonToScoreSuperCategory = (scoreSuperCategoryJson) => {
        let descriptions = [];
        for (let description of scoreSuperCategoryJson.description) {
            descriptions.push(this.jsonToLocal(description));
        }
        return new ScoreSuperCategory(scoreSuperCategoryJson.id, descriptions);
    };


    defaultHeaders = (keycloak) => {
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (keycloak) {
            headers.append('Authorization', 'Bearer ' + keycloak.token);
        }

        return headers;
    };

    formDataHeader = (keycloak) => {
        const headers = new Headers();
        if (keycloak) {
            headers.append('Authorization', 'Bearer ' + keycloak.token);
        }
        return headers;
    };

    getRequest = (url, keycloak) => {
        return fetch(url, {
            method: 'GET',
            headers: this.defaultHeaders(keycloak)
        })
            .then(this.checkHttpStatus)
            .then(response => response.json())
            .catch(this.handleError);
    };

    /**
     * @param {string} url
     * @param {Keycloak.KeycloakInstance} keycloak
     * @returns {Promise<Response>}
     */
    getRequestRaw = (url, keycloak) => {
        return fetch(url, {
            method: 'GET',
            headers: this.defaultHeaders(keycloak)
        })
            .then(this.checkHttpStatus)
            .then(response => response)
            .catch(this.handleError);
    };

    getRequestBlob = (url, keycloak) => {
        return fetch(url, {
            method: 'GET',
            headers: this.defaultHeaders(keycloak)
        })
            .then(this.checkHttpStatus)
            .then(response => response.blob())
            .catch(this.handleError);
    };

    postRequest = (url, body, keycloak) => {
        let json = JSON.stringify({});
        if (body) json = JSON.stringify(body);
        return this.postFetch(url, json, keycloak);
    };

    postRequestArray = (url, bodies, keycloak) => {
        let json = JSON.stringify({});
        if (bodies) {
            let list = [];
            for (let body of bodies)
                list.push(body.toJSON());
            json = JSON.stringify(list);
        }
        return this.postFetch(url, json, keycloak);
    };

    postFetch = (url, json, keycloak) => {
        return fetch(url, {
            method: 'POST',
            headers: this.defaultHeaders(keycloak),
            body: json
        })
            .then(this.checkHttpStatus)
            .then(response => response.json())
            .catch(this.handleError);
    };

    /**
     * @param {string} url
     * @param {{}} body
     * @param {Keycloak.KeycloakInstance} keycloak
     * @param {boolean} [expectJson=true]
     * @returns {Promise<any>|Promise<Response>}
     */
    putRequest = (url, body, keycloak, expectJson) => {
        const isJsonExpected = expectJson === undefined ? true : !!expectJson;
        return fetch(url, {
            method: 'PUT',
            headers: this.defaultHeaders(keycloak),
            body: JSON.stringify(body)
        })
            .then(this.checkHttpStatus)
            .then(response => isJsonExpected ? response.json() : response)
            .catch(this.handleError);
    };

    deleteRequest = (url, keycloak) => {
        return fetch(url, {
            method: 'DELETE',
            headers: this.defaultHeaders(keycloak)
        })
            .then(this.checkHttpStatus)
            .then(response => response)
            .catch(this.handleError);
    };

    postRequestFormDataArray = (url, files, keycloak) => {
        let body = new FormData();
        for (let file of files) {
            body.append("files", file);
        }
        return fetch(url, {
            method: 'POST',
            headers: this.formDataHeader(keycloak),
            body: body
        })
            .then(this.checkHttpStatus)
            .then(response => response)
            .catch(this.handleError);
    };

    postRequestFormData = (url, file, keycloak) => {
        let body = new FormData();
        body.append("file", file);
        return fetch(url, {
            method: 'POST',
            headers: this.formDataHeader(keycloak),
            body: body
        })
            .then(this.checkHttpStatus)
            .then(response => response)
            .catch(this.handleError);
    };

    putRequestFormData = (url, file, keycloak) => {
        let body = new FormData();
        body.append("file", file);
        return fetch(url, {
            method: 'PUT',
            headers: this.formDataHeader(keycloak),
            body: body
        })
            .then(this.checkHttpStatus)
            .then(response => response)
            .catch(this.handleError);
    };

    updateCaregiver = (caregiver, keycloak) => {
        return this.putRequest(this.api + '/caregiver/' + caregiver.id, caregiver, keycloak)
            .then(() => this.getCaregiver(caregiver.id, keycloak));
    };

    getCaregiver = (id, keycloak) => {
        return this.getRequest(this.api + '/caregiver/' + id, keycloak).then(caregiverJson => {
            return (caregiverJson);
        });
    };

    checkHttpStatus = (response) => {
        if (!response.ok) {
            throw new Error(response.status)
        } else return response;
    };

    handleError = (error) => {
        if (error.message === '401') {
            //TODO propper error handling
        } else if (error.message === '403') {
            //TODO propper error handling
        } else {
            throw error;
        }
    }
}

export default new ApiService();
